import * as React from 'react';
import LayerSetter from './components/LayerSetter';
import Title from './components/Title';
import Content from './components/Content';
import Footer from './components/Footer';
import Themed from './components/Themed';
import {
  BasicModalProperties,
  ModalElementProperties,
  ThemedModalProperties,
  ModalThemeMapperType,
} from './types';
import { ReactComponent as Svg } from './modal.svg';

type ModalStatic = {
  Themed: React.FunctionComponent<ThemedModalProperties> & {
    mapper?: ModalThemeMapperType | undefined;
  };
  Title: {
    ({ className, children, icon, ...other }: ModalElementProperties): JSX.Element;
    displayName: string;
  };
  Content: {
    ({ className, children, ...other }: ModalElementProperties): JSX.Element;
    displayName: string;
  };
  Footer: {
    ({ className, children, ...other }: ModalElementProperties): JSX.Element;
    defaultProps: { alignment: string };
    displayName: string;
  };
  svg: React.FunctionComponent;
};

/** Basic component for displaying content above an interface.
 * They are intended for capturing user's attention (informing the user or shifting
 * focus to a task).
 * Users cannot interact with content outside an active modal window and inert content
 * outside is usually visually obscured/dimmed. This implementation uses portals.<br/><br/>
 * A special case of this component is an Alert which interupts the user's workflow
 * to communicte an important message and to acquire a response.
 * Assistive technologies will distinguish alert dialogs/modals from other dialogs/modals
 * and will probably give alert dialogs special treatment.
 * If you need an Alert, please wait for the implementation of that component.
 * <br/>
 * <br/>
 * **Important** This component can have only `Modal.Title`, `Modal.Content` and `Modal.Footer` as direct children.
 * @component
 * @visibleName Modal
 * @since 0.4.0
 * */
const Modal: React.FunctionComponent<BasicModalProperties> & ModalStatic = (props) => (
  <LayerSetter {...props} />
);

Modal.Themed = Themed;
Modal.displayName = 'Modal';

Modal.defaultProps = {
  removeCloseButton: false,
  xPosition: 'center',
  yPosition: 'center',
  modalRoot: 'modal-layer',
  size: 'medium',
};

Modal.Title = Title;
Modal.Content = Content;
Modal.Footer = Footer;
Modal.svg = Svg;

export default Modal;
