import * as React from 'react';
import classnames from 'classnames';
import Flex from '../../../Layout/Flex';
import { printCoreWarning } from '../../../shared';
import { BasicRadioProps, RadioGroupProps, ThemedRadioProps } from '../types';
import styles from '../index.module.scss';

/**
 * Helper component that makes sure the each radio button's function
 * is not created on each render cycle.
 * @param properties
 */
const SelectableRadioButton = ({
  id,
  value,
  onChange,
  child,
  ...props
}: Omit<BasicRadioProps | ThemedRadioProps, 'onChange'> & {
  child: React.ReactNode;
  onChange: (id: string) => void;
}) => {
  /**
   * Adding to avoid the need for the client to supply this
   * optimization himself.
   */
  const selectThisRadio = React.useCallback(() => {
    if (onChange) {
      onChange(id || value);
    }
  }, [onChange, id, value]);

  return React.cloneElement(child as React.ReactElement<any>, {
    ...props,
    id,
    value,
    onChange: selectThisRadio,
  });
};

/**
 * A group of radio buttons with the same name.
 * Each radio button passed as child must have either an unique id or
 * unique value so the parent can know if it's selected.
 * This is the recommended wrapper around the `RadioButton` component
 * because it provides some basic styling and makes sure that the onChange
 * function retrieves the radio button id (or value) on selection.
 *
 * @visibleName RadioButton.Group
 */
const Group = ({
  className,
  children,
  name,
  onChange,
  selected,
  vertical,
  style,
  flexBasis,
  wrap,
  justifyContent,
  alignItems = vertical ? 'flex-start' : 'center',
}: RadioGroupProps) => {
  if (React.Children.count(children) < 2) {
    printCoreWarning(`You passed one or none children to the RadioButton.Group component that expects
    to have at least two RadioButtons. Did you wish to use a single RadioButton component instead?`);
  }

  return (
    <Flex
      alignItems={alignItems}
      wrap={wrap}
      justifyContent={justifyContent}
      flexBasis={flexBasis}
      direction={vertical ? 'column' : 'row'}
      style={style}
      className={classnames(styles['radio-button__group'], {
        [className as string]: !!className,
        [styles['radio-button__group--horizontal']]: !vertical,
      })}
    >
      {React.Children.map(children, (child) => (
        <SelectableRadioButton
          {...child.props}
          checked={(child.props.id || child.props.value) === selected}
          name={name || child.props.name}
          onChange={onChange}
          child={child}
        />
      ))}
    </Flex>
  );
};

Group.displayName = 'RadioButton.Group';

export default Group;
