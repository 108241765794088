import * as React from 'react';
import { ArrowTagProps } from './types';
import { StyledTag, StyledTagBody, StyledTagBodyInner, StyledTagContent } from './Styled';
import styles from './index.module.scss';

const BasicArrowTag: React.FunctionComponent<ArrowTagProps> = (props) => {
  const {
    height,
    borderWidth,
    horizontalPadding,
    theme,
    color,
    backgroundColor,
    borderColor,
    testId,
    className,
    children,
    id,
    ...other
  } = props;

  const bwNumeric = +(borderWidth || '').replace(/[^\d.-]/g, '');
  const bw = bwNumeric !== 0 ? borderWidth : '0px'; // if borderWidth='0' we need unit for css calc to work

  return (
    <StyledTag
      className={`${styles.arrowtag} ${className}`}
      height={height}
      {...other}
      id={id}
      data-test-id={testId ? testId : undefined}
    >
      {bw !== '0px' && (
        <StyledTagBody
          theme={theme}
          className={`${styles['arrowtag--body']} ${className}`}
          height={height}
          borderWidth={bw}
          borderColor={borderColor}
        >
          <svg viewBox="0 0 50 100">
            <polygon points="0,50 50,0 50,100" />
          </svg>
        </StyledTagBody>
      )}

      <StyledTagBodyInner
        className={`${styles['arrowtag--body']} ${styles['arrowtag--body-inner']}`}
        height={height}
        borderWidth={bw}
        theme={theme}
        backgroundColor={backgroundColor}
      >
        <svg viewBox="0 0 50 100">
          <polygon points="0,50 50,0 50,100" />
        </svg>
      </StyledTagBodyInner>

      <StyledTagContent
        theme={theme}
        className={styles['arrowtag--content']}
        height={height}
        borderWidth={bw}
        horizontalPadding={horizontalPadding}
        color={color}
      >
        {children}
      </StyledTagContent>
    </StyledTag>
  );
};

BasicArrowTag.defaultProps = {
  height: '1.8em',
  borderWidth: '.1em',
  horizontalPadding: '.6em',
};

export default BasicArrowTag;
