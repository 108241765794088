import base from '../index.scss';

export type IconsType = {
  [key: string]: string;
};

const icons: IconsType = {
  'icon.accept': base.icon_accept,
  'icon.acrobat-pdf': base['icon_acrobat-pdf'],
  'icon.add': base.icon_add,
  'icon.add-outline': base['icon_add-outline'],
  'icon.add-user': base['icon_add-user'],
  'icon.alarm': base.icon_alarm,
  'icon.alert': base.icon_alert,
  'icon.alert-circle': base['icon_alert-circle'],
  'icon.alert-octagon': base['icon_alert-octagon'],
  'icon.alignLeft': base.icon_alignLeft,
  'icon.amp': base.icon_amp,
  'icon.analytics': base.icon_analytics,
  'icon.android': base.icon_android,
  'icon.answer-container': base['icon_answer-container'],
  'icon.areachart': base.icon_areachart,
  'icon.arrow-back': base['icon_arrow-back'],
  'icon.arrow-down': base['icon_arrow-down'],
  'icon.arrow-left': base['icon_arrow-left'],
  'icon.arrow-right': base['icon_arrow-right'],
  'icon.arrow-up': base['icon_arrow-up'],
  'icon.at-risk': base['icon_at-risk'],
  'icon.baidu': base.icon_baidu,
  'icon.bar-graph': base['icon_bar-graph'],
  'icon.bar-graph-full': base['icon_bar-graph-full'],
  'icon.benchmark': base.icon_benchmark,
  'icon.bing': base.icon_bing,
  'icon.blog': base.icon_blog,
  'icon.books': base.icon_books,
  'icon.brief-creator': base['icon_brief-creator'],
  'icon.briefing': base.icon_briefing,
  'icon.briefing-order': base['icon_briefing-order'],
  'icon.briefing-ordered': base['icon_briefing-ordered'],
  'icon.bug': base.icon_bug,
  'icon.bulb': base.icon_bulb,
  'icon.burger-menu': base['icon_burger-menu'],
  'icon.calendar-monthly': base['icon_calendar-monthly'],
  'icon.calendar-weekly': base['icon_calendar-weekly'],
  'icon.caret-down': base['icon_caret-down'],
  'icon.caret-up': base['icon_caret-up'],
  'icon.carousel': base.icon_carousel,
  'icon.cd-briefing-creator': base['icon_cd-briefing-creator'],
  'icon.cd-live-editor': base['icon_cd-live-editor'],
  'icon.cd-output': base['icon_cd-output'],
  'icon.cellphone-off': base['icon_cellphone-off'],
  'icon.center-topic-graph': base['icon_center-topic-graph'],
  'icon.changelog': base.icon_changelog,
  'icon.check-circle': base['icon_check-circle'],
  'icon.checkbox-checked': base['icon_checkbox-checked'],
  'icon.checkbox-empty': base['icon_checkbox-empty'],
  'icon.checkbox-indeterminate': base['icon_checkbox-indeterminate'],
  'icon.checkmark': base.icon_checkmark,
  'icon.chevron-down': base['icon_chevron-down'],
  'icon.chevron-left': base['icon_chevron-left'],
  'icon.chevron-right': base['icon_chevron-right'],
  'icon.chevron-up': base['icon_chevron-up'],
  'icon.circle': base.icon_circle,
  'icon.clipboard': base.icon_clipboard,
  'icon.close': base.icon_close,
  'icon.close-groups': base['icon_close-groups'],
  'icon.cloud': base.icon_cloud,
  'icon.comment': base.icon_comment,
  'icon.comparison': base.icon_comparison,
  'icon.competitors': base.icon_competitors,
  'icon.content': base.icon_content,
  'icon.content-suite': base['icon_content-suite'],
  'icon.context-menu': base['icon_context-menu'],
  'icon.corner-arrow-left': base['icon_corner-arrow-left'],
  'icon.corner-arrow-right': base['icon_corner-arrow-right'],
  'icon.cpc': base.icon_cpc,
  'icon.crawl-internal': base['icon_crawl-internal'],
  'icon.crawl-js': base['icon_crawl-js'],
  'icon.create': base.icon_create,
  'icon.csv': base.icon_csv,
  'icon.currency-EUR': base['icon_currency-EUR'],
  'icon.currency-GBP': base['icon_currency-GBP'],
  'icon.currency-USD': base['icon_currency-USD'],
  'icon.decline': base.icon_decline,
  'icon.deselect-circle-filled': base['icon_deselect-circle-filled'],
  'icon.desktop': base.icon_desktop,
  'icon.desktopLocal': base.icon_desktopLocal,
  'icon.destinations': base.icon_destinations,
  'icon.directory': base.icon_directory,
  'icon.dots-vertical': base['icon_dots-vertical'],
  'icon.double-chevron-left': base['icon_double-chevron-left'],
  'icon.double-chevron-right': base['icon_double-chevron-right'],
  'icon.download': base.icon_download,
  'icon.email': base.icon_email,
  'icon.emoticon-happy': base['icon_emoticon-happy'],
  'icon.emoticon-happy-outline': base['icon_emoticon-happy-outline'],
  'icon.emoticon-neutral': base['icon_emoticon-neutral'],
  'icon.emoticon-neutral-outline': base['icon_emoticon-neutral-outline'],
  'icon.emoticon-sad': base['icon_emoticon-sad'],
  'icon.emoticon-sad-outline': base['icon_emoticon-sad-outline'],
  'icon.events': base.icon_events,
  'icon.events-3pack': base['icon_events-3pack'],
  'icon.excel': base.icon_excel,
  'icon.expand': base.icon_expand,
  'icon.export': base.icon_export,
  'icon.eye': base.icon_eye,
  'icon.facebook': base.icon_facebook,
  'icon.feed-cloud-1': base['icon_feed-cloud-1'],
  'icon.feed-seo-1': base['icon_feed-seo-1'],
  'icon.file-export': base['icon_file-export'],
  'icon.filter': base.icon_filter,
  'icon.flag': base.icon_flag,
  'icon.flat-arrow-right': base['icon_flat-arrow-right'],
  'icon.flight-adwords': base['icon_flight-adwords'],
  'icon.flights': base.icon_flights,
  'icon.focus-keyword': base['icon_focus-keyword'],
  'icon.fullscreen': base.icon_fullscreen,
  'icon.globe': base.icon_globe,
  'icon.globe-link': base['icon_globe-link'],
  'icon.google-plus': base['icon_google-plus'],
  'icon.guard-error': base['icon_guard-error'],
  'icon.guard-off': base['icon_guard-off'],
  'icon.guard-ok': base['icon_guard-ok'],
  'icon.guard-pause': base['icon_guard-pause'],
  'icon.headphones': base.icon_headphones,
  'icon.help': base.icon_help,
  'icon.help-center': base['icon_help-center'],
  'icon.help-video': base['icon_help-video'],
  'icon.home': base.icon_home,
  'icon.hotel-knowledge': base['icon_hotel-knowledge'],
  'icon.hotel-3pack': base['icon_hotel-3pack'],
  'icon.hourglass': base.icon_hourglass,
  'icon.hp-paid': base['icon_hp-paid'],
  'icon.images': base.icon_images,
  'icon.import': base.icon_import,
  'icon.info': base.icon_info,
  'icon.info-outline': base['icon_info-outline'],
  'icon.information-container': base['icon_information-container'],
  'icon.invited-license': base['icon_invited-license'],
  'icon.ios': base.icon_ios,
  'icon.jobs': base.icon_jobs,
  'icon.keyword-count': base['icon_keyword-count'],
  'icon.knowledge-graph': base['icon_knowledge-graph'],
  'icon.kph': base.icon_kph,
  'icon.lightbulb': base.icon_lightbulb,
  'icon.line-chart': base['icon_line-chart'],
  'icon.line-graph': base['icon_line-graph'],
  'icon.link-variant': base['icon_link-variant'],
  'icon.list-collapse': base['icon_list-collapse'],
  'icon.list-drag': base['icon_list-drag'],
  'icon.list-expand': base['icon_list-expand'],
  'icon.live-brief': base['icon_live-brief'],
  'icon.live-editor': base['icon_live-editor'],
  'icon.live-search': base['icon_live-search'],
  'icon.lock': base.icon_lock,
  'icon.logout': base.icon_logout,
  'icon.low-paid': base['icon_low-paid'],
  'icon.maps': base.icon_maps,
  'icon.marker': base.icon_marker,
  'icon.martini': base.icon_martini,
  'icon.menu-down': base['icon_menu-down'],
  'icon.menu-hamburger': base['icon_menu-hamburger'],
  'icon.menu-left': base['icon_menu-left'],
  'icon.menu-right': base['icon_menu-right'],
  'icon.mobile': base.icon_mobile,
  'icon.mobile-apps': base['icon_mobile-apps'],
  'icon.music-container': base['icon_music-container'],
  'icon.nav-admin': base['icon_nav-admin'],
  'icon.nav-content-experience': base['icon_nav-content-experience'],
  'icon.nav-dashboards': base['icon_nav-dashboards'],
  'icon.nav-exports': base['icon_nav-exports'],
  'icon.nav-home': base['icon_nav-home'],
  'icon.nav-search-experience': base['icon_nav-search-experience'],
  'icon.nav-settings': base['icon_nav-settings'],
  'icon.nav-site-experience': base['icon_nav-site-experience'],
  'icon.naver': base.icon_naver,
  'icon.naversite': base.icon_naversite,
  'icon.navigation': base.icon_navigation,
  'icon.new-document': base['icon_new-document'],
  'icon.news': base.icon_news,
  'icon.next': base.icon_next,
  'icon.next-version': base['icon_next-version'],
  'icon.nf-archive': base['icon_nf-archive'],
  'icon.nf-cloud': base['icon_nf-cloud'],
  'icon.nf-pen': base['icon_nf-pen'],
  'icon.nf-search': base['icon_nf-search'],
  'icon.nf-tool': base['icon_nf-tool'],
  'icon.niche-keyword': base['icon_niche-keyword'],
  'icon.no-data': base['icon_no-data'],
  'icon.noneye': base.icon_noneye,
  'icon.nonurl': base.icon_nonurl,
  'icon.open-external': base['icon_open-external'],
  'icon.open-groups': base['icon_open-groups'],
  'icon.opportunity': base.icon_opportunity,
  'icon.opportunity-checked': base['icon_opportunity-checked'],
  'icon.opportunity-small': base['icon_opportunity-small'],
  'icon.own-license': base['icon_own-license'],
  'icon.paid-mobile-apps': base['icon_paid-mobile-apps'],
  'icon.pause': base.icon_pause,
  'icon.pen': base.icon_pen,
  'icon.pencil': base.icon_pencil,
  'icon.pl-paid': base['icon_pl-paid'],
  'icon.play': base.icon_play,
  'icon.plus': base.icon_plus,
  'icon.plus-circle': base['icon_plus-circle'],
  'icon.potential': base.icon_potential,
  'icon.prev': base.icon_prev,
  'icon.prev-version': base['icon_prev-version'],
  'icon.previous': base.icon_previous,
  'icon.print': base.icon_print,
  'icon.project': base.icon_project,
  'icon.prominent-keyword': base['icon_prominent-keyword'],
  'icon.puzzle': base.icon_puzzle,
  'icon.qihoo': base.icon_qihoo,
  'icon.questions': base.icon_questions,
  'icon.rankings': base.icon_rankings,
  'icon.referring-domains': base['icon_referring-domains'],
  'icon.refresh': base.icon_refresh,
  'icon.rejected': base.icon_rejected,
  'icon.related-questions': base['icon_related-questions'],
  'icon.related-topics': base['icon_related-topics'],
  'icon.reload': base.icon_reload,
  'icon.remove': base.icon_remove,
  'icon.remove-circle': base['icon_remove-circle'],
  'icon.reset': base.icon_reset,
  'icon.ring': base.icon_ring,
  'icon.ring-filled': base['icon_ring-filled'],
  'icon.save': base.icon_save,
  'icon.search': base.icon_search,
  'icon.seasonality': base.icon_seasonality,
  'icon.select-circle-filled': base['icon_select-circle-filled'],
  'icon.settings': base.icon_settings,
  'icon.seznam': base.icon_seznam,
  'icon.share': base.icon_share,
  'icon.shopping': base.icon_shopping,
  'icon.similar-keywords': base['icon_similar-keywords'],
  'icon.site-links': base['icon_site-links'],
  'icon.small-arrow-left': base['icon_small-arrow-left'],
  'icon.small-arrow-right': base['icon_small-arrow-right'],
  'icon.smartphone': base.icon_smartphone,
  'icon.sogou': base.icon_sogou,
  'icon.sort-asc': base['icon_sort-asc'],
  'icon.sort-desc': base['icon_sort-desc'],
  'icon.soso': base.icon_soso,
  'icon.spam-term': base['icon_spam-term'],
  'icon.speedometer': base.icon_speedometer,
  'icon.squared-close': base['icon_squared-close'],
  'icon.stacked-area-chart': base['icon_stacked-area-chart'],
  'icon.star-empty': base['icon_star-empty'],
  'icon.star-full': base['icon_star-full'],
  'icon.star-half': base['icon_star-half'],
  'icon.stop': base.icon_stop,
  'icon.subdomain': base.icon_subdomain,
  'icon.switch': base.icon_switch,
  'icon.table': base.icon_table,
  'icon.tablet': base.icon_tablet,
  'icon.tag-plus': base['icon_tag-plus'],
  'icon.tag-text-outline': base['icon_tag-text-outline'],
  'icon.three-dots': base['icon_three-dots'],
  'icon.threshold': base.icon_threshold,
  'icon.thumbs-down': base['icon_thumbs-down'],
  'icon.toggle-backgrounds': base['icon_toggle-backgrounds'],
  'icon.toggle-edges': base['icon_toggle-edges'],
  'icon.tool': base.icon_tool,
  'icon.top-stuff-container': base['icon_top-stuff-container'],
  'icon.topicgraph': base.icon_topicgraph,
  'icon.traffic-container': base['icon_traffic-container'],
  'icon.trash': base.icon_trash,
  'icon.triangle-bottom': base['icon_triangle-bottom'],
  'icon.triangle-left': base['icon_triangle-left'],
  'icon.triangle-right': base['icon_triangle-right'],
  'icon.triangle-top': base['icon_triangle-top'],
  'icon.trophy': base.icon_trophy,
  'icon.twitter': base.icon_twitter,
  'icon.twitter-card': base['icon_twitter-card'],
  'icon.unpin': base.icon_unpin,
  'icon.unplugged': base.icon_unplugged,
  'icon.upload': base.icon_upload,
  'icon.upload-cloud': base['icon_upload-cloud'],
  'icon.url-details': base['icon_url-details'],
  'icon.url-nonsecure': base['icon_url-nonsecure'],
  'icon.user': base.icon_user,
  'icon.vertical-dots': base['icon_vertical-dots'],
  'icon.video': base.icon_video,
  'icon.video-carousel': base['icon_video-carousel'],
  'icon.visibility': base.icon_visibility,
  'icon.warning': base.icon_warning,
  'icon.wordpress': base.icon_wordpress,
  'icon.yahoo': base.icon_yahoo,
  'icon.yandex-ru': base['icon_yandex-ru'],
  'icon.yandex-tr': base['icon_yandex-tr'],
  'icon.youtube-play': base['icon_youtube-play'],
  'icon.zoom-in': base['icon_zoom-in'],
  'icon.zoom-out': base['icon_zoom-out'],
  'icon.recipes': base.icon_recipes,
  'icon.email-resend': base['icon_email-resend'],
  'icon.keyword-suggest': base['icon_keyword-suggest'],
  'icon.autocorrection': base['icon_autocorrection'],
};

// Is not autogenerated, do not remove
const old = {
  'icon.accept.old': '',
  'icon.acrobat-pdf.old': '',
  'icon.add-outline.old': 'icon_add_outline plus add_outline',
  'icon.add-user.old': '',
  'icon.add.old': 'icon_add plus plus_background',
  'icon.alarm.old': 'icon_alarm alarm clock',
  'icon.alert-circle.old': '',
  'icon.alert-octagon.old': 'icon_alert_octagon alert alert_octagon',
  'icon.alert.old': '',
  'icon.alignLeft.old': '',
  'icon.amp.old': '',
  'icon.analytics.old': '',
  'icon.android.old': '',
  'icon.answer-container.old': '',
  'icon.areachart.old': '',
  'icon.arrow-back.old': '',
  'icon.arrow-down.old': '',
  'icon.arrow-left.old': '',
  'icon.arrow-right.old': '',
  'icon.arrow-up.old': '',
  'icon.at-risk.old': '',
  'icon.baidu.old': '',
  'icon.bar-graph-full.old': '',
  'icon.bar-graph.old': 'icon_bar_graph bar_graph',
  'icon.benchmark.old': '',
  'icon.bing.old': '',
  'icon.blog.old': '',
  'icon.books.old': '',
  'icon.boxed-dollar.old': '',
  'icon.brief-creator.old': '',
  'icon.briefing-order.old': '',
  'icon.briefing-ordered.old': '',
  'icon.briefing.old': '',
  'icon.bug.old': '',
  'icon.burger-menu.old': 'icon_burger_menu burger_menu nav menu',
  'icon.calendar-monthly.old': '',
  'icon.calendar-weekly.old': '',
  'icon.caret-down.old': '',
  'icon.caret-up.old': '',
  'icon.carousel.old': '',
  'icon.cd-briefing-creator.old': '',
  'icon.cd-live-editor.old': '',
  'icon.cd-output.old': '',
  'icon.cellphone-off.old': '',
  'icon.center-topic-graph.old': '',
  'icon.changelog.old': '',
  'icon.check-circle.old': 'icon_checkmark checkmark checkmark_background',
  'icon.checkbox-checked.old': 'icon_checkbox_checked checkbox_checked',
  'icon.checkbox-empty.old': 'icon_checkbox_empty checkbox_empty box',
  'icon.checkbox-indeterminate.old':
    'icon_checkbox_intermediate checkbox_intermediate checkbox_minus',
  'icon.checkmark.old': '',
  'icon.chevron-down.old': '',
  'icon.chevron-left.old': 'icon_chevron_left chevron_left previous',
  'icon.chevron-right.old': 'icon_chevron_right chevron_right next',
  'icon.chevron-up.old': '',
  'icon.circle.old': '',
  'icon.clipboard.old': '',
  'icon.close-groups.old': '',
  'icon.close.old': 'icon_close close',
  'icon.cloud.old': 'icon_cloud cloud',
  'icon.comment.old': '',
  'icon.comparison.old': '',
  'icon.competitors.old': '',
  'icon.content-suite.old': '',
  'icon.content.old': '',
  'icon.context-menu.old': '',
  'icon.corner-arrow-left.old': '',
  'icon.corner-arrow-right.old': '',
  'icon.cpc.old': '',
  'icon.crawl-internal.old': '',
  'icon.crawl-js.old': '',
  'icon.create.old': 'icon_create create box_arrow',
  'icon.csv.old': '',
  'icon.currency-EUR.old': '',
  'icon.currency-GBP.old': '',
  'icon.currency-USD.old': '',
  'icon.decline.old': '',
  'icon.deselect-circle-filled.old': '',
  'icon.desktop.old': '',
  'icon.desktopLocal.old': '',
  'icon.destinations.old': '',
  'icon.directory.old': '',
  'icon.dollar.old': '',
  'icon.dots-vertical.old': '',
  'icon.double-chevron-left.old': '',
  'icon.double-chevron-right.old': '',
  'icon.download.old': '',
  'icon.easy-to-improve.old': '',
  'icon.email.old': '',
  'icon.excel.old': '',
  'icon.expand.old': '',
  'icon.export.old': 'icon_export',
  'icon.eye.old': '',
  'icon.facebook.old': '',
  'icon.feed-cloud-1.old': '',
  'icon.feed-seo-1.old': '',
  'icon.file-export.old': '',
  'icon.filter.old': '',
  'icon.flag.old': 'icon_flag flag',
  'icon.flat-arrow-right.old': '',
  'icon.flight-adwords.old': '',
  'icon.flights.old': '',
  'icon.focus-keyword.old': '',
  'icon.fullscreen.old': '',
  'icon.gift.old': '',
  'icon.globe-link.old': '',
  'icon.globe.old': 'icon_globe world',
  'icon.google-plus.old': '',
  'icon.guard-error.old': '',
  'icon.guard-off.old': '',
  'icon.guard-ok.old': '',
  'icon.guard-pause.old': '',
  'icon.headphones.old': '',
  'icon.help-center.old': '',
  'icon.help-video.old': 'icon_help_video play video video_background',
  'icon.help.old': 'icon_help help help_outlined',
  'icon.home.old': '',
  'icon.hourglass.old': '',
  'icon.hp-paid.old': '',
  'icon.images.old': '',
  'icon.import.old': '',
  'icon.info-outline.old': 'icon_info_outline info_outline info',
  'icon.info.old': 'icon_info info info_background',
  'icon.information-container.old': '',
  'icon.invited-license.old': '',
  'icon.ios.old': '',
  'icon.jobs.old': '',
  'icon.keyword-count.old': '',
  'icon.knowledge-graph.old': '',
  'icon.kph.old': '',
  'icon.lightbulb.old': '',
  'icon.line-chart.old': '',
  'icon.line-graph.old': '',
  'icon.link-variant.old': '',
  'icon.list-collapse.old': '',
  'icon.list-drag.old': '',
  'icon.list-expand.old': '',
  'icon.live-brief.old': '',
  'icon.live-editor.old': '',
  'icon.live-search.old': '',
  'icon.lock.old': '',
  'icon.logout.old': '',
  'icon.low-paid.old': '',
  'icon.maps.old': '',
  'icon.marker.old': '',
  'icon.martini.old': '',
  'icon.menu-down.old': 'icon_menu_down menu_down',
  'icon.menu-hamburger.old': '',
  'icon.menu-left.old': 'icon_menu_left menu_left',
  'icon.menu-right.old': 'icon_menu_right menu_right',
  'icon.mobile-apps.old': '',
  'icon.mobile.old': '',
  'icon.music-container.old': '',
  'icon.nav-admin.old': '',
  'icon.nav-content-experience.old': '',
  'icon.nav-dashboards.old': '',
  'icon.nav-exports.old': '',
  'icon.nav-home.old': '',
  'icon.nav-search-experience.old': '',
  'icon.nav-settings.old': '',
  'icon.nav-site-experience.old': '',
  'icon.naver.old': '',
  'icon.naversite.old': '',
  'icon.navigation.old': '',
  'icon.new-document.old': '',
  'icon.news.old': '',
  'icon.next-version.old': '',
  'icon.next.old': '',
  'icon.nf-archive.old': '',
  'icon.nf-cloud.old': '',
  'icon.nf-pen.old': '',
  'icon.nf-search.old': '',
  'icon.nf-tool.old': '',
  'icon.niche-keyword.old': '',
  'icon.no-data.old': '',
  'icon.noneye.old': '',
  'icon.nonurl.old': '',
  'icon.ok.old': '',
  'icon.open-external.old': '',
  'icon.open-groups.old': '',
  'icon.opportunity-checked.old': '',
  'icon.opportunity-small.old': '',
  'icon.opportunity.old': '',
  'icon.own-license.old': '',
  'icon.paid-mobile-apps.old': '',
  'icon.pause.old': '',
  'icon.pen.old': '',
  'icon.pencil.old': '',
  'icon.pl-paid.old': '',
  'icon.play.old': '',
  'icon.plus-circle.old': '',
  'icon.plus.old': '',
  'icon.potential.old': '',
  'icon.prev-version.old': '',
  'icon.prev.old': '',
  'icon.previous.old': '',
  'icon.print.old': '',
  'icon.project.old': '',
  'icon.prominent-keyword.old': '',
  'icon.puzzle.old': 'icon_puzzle puzzle',
  'icon.qihoo.old': '',
  'icon.questions.old': '',
  'icon.rankings.old': '',
  'icon.referring-domains.old': '',
  'icon.refresh.old': '',
  'icon.rejected.old': '',
  'icon.related-questions.old': '',
  'icon.related-topics.old': '',
  'icon.reload.old': '',
  'icon.remove-circle.old': '',
  'icon.remove.old': '',
  'icon.reset.old': '',
  'icon.ring-filled.old': '',
  'icon.ring.old': '',
  'icon.save.old': '',
  'icon.search.old': 'icon_search search magnifier',
  'icon.searchmetrics-circle.old': '',
  'icon.searchmetrics.old': '',
  'icon.seasonality.old': 'icon_seasonality seasonality leaf',
  'icon.select-circle-filled.old': '',
  'icon.settings.old': '',
  'icon.seznam.old': '',
  'icon.share.old': '',
  'icon.shopping.old': '',
  'icon.similar-keywords.old': '',
  'icon.site-links.old': '',
  'icon.small-arrow-left.old': '',
  'icon.small-arrow-right.old': '',
  'icon.smartphone.old': '',
  'icon.sogou.old': '',
  'icon.sort-asc.old': '',
  'icon.sort-desc.old': '',
  'icon.soso.old': '',
  'icon.spam-term.old': '',
  'icon.speedometer.old': '',
  'icon.squared-close.old': '',
  'icon.stacked-area-chart.old': '',
  'icon.star-empty.old': 'icon_star_empty star_empty star_no_fill',
  'icon.star-full.old': 'icon_star_full star_full star_filled',
  'icon.star-half.old': 'icon_star_half star_half star_half_full',
  'icon.stop.old': '',
  'icon.subdomain.old': '',
  'icon.switch.old': '',
  'icon.table.old': '',
  'icon.tablet.old': '',
  'icon.tag-plus.old': '',
  'icon.tag-text-outline.old': '',
  'icon.tg-fullscreen-graph.old': '',
  'icon.tg-fullscreen-table.old': '',
  'icon.tg-splitscreen-1-2.old': '',
  'icon.three-dots.old': '',
  'icon.threshold.old': 'icon_threshold threshold bar_chart_arrow',
  'icon.thumbs-down.old': '',
  'icon.toggle-backgrounds.old': '',
  'icon.toggle-edges.old': '',
  'icon.tool.old': '',
  'icon.top-stuff-container.old': '',
  'icon.topicgraph.old': '',
  'icon.traffic-container.old': '',
  'icon.trash.old': '',
  'icon.triangle-bottom.old': '',
  'icon.triangle-left.old': '',
  'icon.triangle-right.old': '',
  'icon.triangle-top.old': '',
  'icon.trophy.old': 'icon_trophy trophy',
  'icon.twitter-card.old': '',
  'icon.twitter.old': '',
  'icon.unpin.old': '',
  'icon.unplugged.old': '',
  'icon.upload-cloud.old': '',
  'icon.upload.old': '',
  'icon.url-details.old': '',
  'icon.url-nonsecure.old': '',
  'icon.user.old': '',
  'icon.vertical-dots.old': '',
  'icon.video-carousel.old': '',
  'icon.video.old': '',
  'icon.visibility.old': '',
  'icon.warning.old': '',
  'icon.wiggly-line.old': '',
  'icon.wordpress.old': '',
  'icon.yahoo.old': '',
  'icon.yandex-ru.old': '',
  'icon.yandex-tr.old': '',
  'icon.youtube-play.old': 'icon_youtube_play youtube_play youtube video_play',
  'icon.zoom-in.old': '',
  'icon.zoom-out.old': '',
};

export default { ...icons, ...old };
