import base from '../index.scss';

export type zIndexType = {
  [key: string]: string;
};

const zIndex: zIndexType = {
  'zIndex.level.01': base.zIndex_level_01,
  'zIndex.level.02': base.zIndex_level_02,
  'zIndex.level.03': base.zIndex_level_03,
  'zIndex.level.04': base.zIndex_level_04,
  'zIndex.level.05': base.zIndex_level_05,
  'zIndex.level.06': base.zIndex_level_06,
  'zIndex.level.07': base.zIndex_level_07,
  'zIndex.level.08': base.zIndex_level_08,
  'zIndex.level.09': base.zIndex_level_09,
  'zIndex.level.10': base.zIndex_level_10,
};

// Is not autogenerated, do not remove
const old = {
  'zIndex.level.01.old':
    'zIndex1ActionBar zIndex1LegendItem zIndex1NavbarBrand zIndex1Omnibox zIndex1ScrollbarLayoutFace zIndex1ProjectInvitedHandler zIndex1Mask zIndex1ChartBarBg',
  'zIndex.level.02.old': 'zIndex2TableConfigurator zIndex2ChartBarInner',
  'zIndex.level.03.old': 'zIndex3TableFooter zIndexNavigation zIndex3Spinner',
  'zIndex.level.04.old': '',
  'zIndex.level.05.old': 'zIndex5PositionOnTopOfTableFooter',
  'zIndex.level.06.old':
    'zIndex6TableFilterOverlay zIndex6TableConfiguratorOverlay zIndex6TableConfiguratorOpen zIndex6BriefingCreatorActionBar zIndex6Reloading',
  'zIndex.level.07.old':
    'zIndex7ContextMenu zIndex7DatePickerWithTrend zIndex7DropdownMenu zIndex7OverlaySpinner zIndex7Tooltip',
  'zIndex.level.08.old':
    'zIndex8Navigation zIndex8PagePanel zIndex8DateRangePickerPopover zIndex8ProjectDetailsFooter zIndex8ScrollNav zIndex8Tooltip zIndex8ContextMenuWithModalOpen',
  'zIndex.level.09.old': 'zIndex9Tooltip zIndex9TinyMce zIndex9ZoomOverlayTarget',
  'zIndex.level.10.old': ' zIndex10Dev zIndex10DebugWidget',
};

export default { ...zIndex, ...old };
