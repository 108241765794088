import * as React from 'react';
import styles from '../index.module.scss';

const LinkContent = ({ showIcon, iconAlignment, Icon, showHref, children, href }) => (
  <span className={styles.link__content__wrapper}>
    {showIcon && iconAlignment === 'left' && Icon}
    <span className={styles.link__content}>
      {!showHref && children}
      {showHref && href}
    </span>
    {showIcon && iconAlignment === 'right' && Icon}
  </span>
);

export default LinkContent;
