import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { CardChildType } from '../types';

/**
 * Container for the whole content of a card
 * @component
 * @visibleName Card.Content
 */
const CardContent: React.FunctionComponent<CardChildType> = ({
  alignment,
  className,
  style,
  border,
  ...props
}) => {
  return (
    <div
      style={style}
      className={classnames(
        styles.card__content,
        styles[alignment || 'left'],
        { [styles.border]: !!border },
        className
      )}
    >
      {props.children}
    </div>
  );
};

CardContent.displayName = 'Card.Content';
export default CardContent;
