import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import Card from '../../../DataDisplay/Card';
import Icon from '../../../General/Icon';
import Content from './Content';
import styles from '../index.module.scss';
import { BasicModalProperties } from '../types';
import { printCoreWarning } from '../../../shared';
import checkValue from '../../../../styles/checkValue';

type ChildElementType = React.ReactElement & {
  type: {
    displayName: string;
  };
};

const StyledCard = styled(Card)`
  ${({
    top,
    left,
    width,
  }: {
    top?: number;
    left?: number;
    elevation?: string | number;
    width?: string;
  }) => `
    ${top ? `top: ${top}px;` : ''}
    ${left ? `left: ${left}px;` : ''}
    ${width ? `width: ${width}px` : ''}
  `}
  ${({ elevation }: { elevation?: number | string }) =>
    elevation
      ? `
  z-index: ${elevation};
`
      : ''}
`;

/**
 * Basic implementation of the Modal component.
 *
 * @since 0.4.0
 */

const BasicModal: React.FunctionComponent<BasicModalProperties> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  children,
  className,
  elevation,
  focusId,
  id,
  onHide,
  xPosition,
  yPosition,
  removeCloseButton,
  scrollContent,
  scrollOverlay,
  show,
  size,
  testId,
  theme,
  triggerId,
  style,
  width,
}: BasicModalProperties) => {
  const modal = React.useRef(null);
  const [firstFocusableEl, setFirstDecentant] = React.useState<HTMLElement | null>(null);
  const [lastFocusableEl, setLastDecentant] = React.useState<HTMLElement | null>(null);

  /** If we have the triggerId we need to calculate it's position
   * and where we should be placed based on that.
   * Screen size and element size currently not taken into account.
   */
  const [top, left] = React.useMemo(() => {
    const { top, left, width, height } = document
      .getElementById(triggerId || '')
      ?.getBoundingClientRect() || { top: undefined, left: undefined };

    if (show && top && left && height && width) {
      let calculatedTop = top;
      let calculatedLeft = left;

      if (yPosition === 'center') {
        calculatedTop = calculatedTop + height / 2;
      }
      if (yPosition === 'bottom') {
        calculatedTop = calculatedTop + height;
      }

      if (xPosition === 'center') {
        calculatedLeft = calculatedLeft + width / 2;
      }
      if (xPosition === 'right') {
        calculatedLeft = calculatedLeft + width;
      }

      return [calculatedTop, calculatedLeft];
    }
    return [undefined, undefined];
  }, [triggerId, show, yPosition, xPosition]);

  /**
   * As children are passed before first render (when closed),
   * we'll calculate this before we need to render them.
   */
  const sections = React.useMemo(() => {
    const componentChildren = React.Children.toArray(children);
    let title = componentChildren.find(
      (child) => (child as ChildElementType).type.displayName === 'Modal.Title'
    );
    let content = componentChildren.find(
      (child) => (child as ChildElementType).type.displayName === 'Modal.Content'
    );
    const footer = componentChildren.find(
      (child) => (child as ChildElementType).type.displayName === 'Modal.Footer'
    );

    // The user did not use any of the static exports
    // Add content by default
    if (!content && !footer && !title) {
      content = <Content>{componentChildren}</Content>;
    }

    if (title) {
      title = React.cloneElement(title as React.ReactElement, {
        ...(theme && {
          style: {
            backgroundColor: checkValue(theme.backgroundColor, 'colors'),
            color: checkValue(theme.color, 'colors'),
          },
        }),
        ...(!removeCloseButton && {
          icon: <Icon className={styles.modal__title__icon} icon="$icon--close" onClick={onHide} />,
        }),
        // Add id to title
        ...(id && {
          id: `${id}__title`,
        }),
      });
    }

    return {
      title,
      content,
      footer,
    };
  }, [children, theme, id, removeCloseButton, onHide]);

  /**
   * When a modal (dialog) opens, the focus needs to move to an element inside the dialog.
   * Ideally, first focusable element
   */
  React.useEffect(() => {
    let calculatedFirst, calculatedLast;
    if (modal && modal.current && show) {
      const allFocusableElements = ((modal.current as unknown) as Element)?.querySelectorAll(query);
      calculatedFirst = allFocusableElements.item(0);
      calculatedLast = allFocusableElements.item(allFocusableElements.length - 1);
      setFirstDecentant(calculatedFirst);
      setLastDecentant(calculatedLast);
      if (calculatedFirst && !focusId) {
        calculatedFirst.focus();
      } else if (focusId) {
        document.getElementById(focusId)?.focus();
      }
    }
  }, [show, focusId]);

  /**
   * In case of modal (dialog), tab needs to be "trapped" inside of the modal.
   * The user is not supposed to have any interaction with the content outside the modal
   * so we need to block keyboard interaction (mouse interaction is handled using a layer on top).
   *
   */
  const handleSpecialKeys = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key, shiftKey } = event;

      if (key !== 'Tab' && key !== 'Escape') return;
      if (key === 'Escape' && onHide) {
        onHide(event);
      }
      /* shift + tab */
      if (shiftKey && lastFocusableEl && document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        event.preventDefault();
      } else if (!shiftKey && firstFocusableEl && document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        event.preventDefault();
      }
    },
    [firstFocusableEl, lastFocusableEl, onHide]
  );

  const handleOverlayInteraction = React.useCallback(
    (e) => {
      if (onHide) {
        onHide(e);
      }
    },
    [onHide]
  );

  React.useEffect(() => {
    if (!ariaLabelledBy && !id) {
      printCoreWarning(`The Modal component needs to have a label describing the content for users using screen readers. 
      If you pass an id to the Modal component, we will make sure to give this value as the aria-labelledby attribute.
      You can also pass the ariaLabelledBy with the ID of a child inside of Modal.Header.
      Please make sure you do one of the two to make sure our components are accessible to everyone.
      `);
    }
  }, [id, ariaLabelledBy]);

  if (!show) return null;

  return (
    <div
      ref={modal}
      id={id}
      data-test-id={testId ? testId : undefined}
      aria-labelledby={ariaLabelledBy || `${id}__title`}
      aria-describedby={ariaDescribedBy}
      className={classnames(styles.modal, className, styles.fixed, {
        [styles.scroll__content]: scrollContent,
        [styles.scroll__overlay]: scrollOverlay,
      })}
      onKeyDown={handleSpecialKeys}
      role="dialog"
      aria-modal={true}
    >
      <div
        style={elevation ? { zIndex: Number(elevation) } : undefined}
        className={styles.modal__overlay}
        onClick={handleOverlayInteraction}
      />
      <StyledCard
        className={classnames(
          styles.modal__card,
          styles.fixed,
          styles[`horizontal--${xPosition ? xPosition : 'left'}`],
          styles[`vertical--${yPosition ? yPosition : 'bottom'}`],
          styles[size || 'medium'],
          {
            [styles.withTrigger]: !!triggerId,
          }
        )}
        style={style}
        elevation={elevation}
        top={top}
        left={left}
        width={width}
      >
        {sections.title}
        {sections.content}
        {sections.footer}
      </StyledCard>
    </div>
  );
};

/**
 * Query for selection of all focusable elements that can
 * be inside the modal.
 */
const query =
  'a[href]:not([disabled]), div[tabindex], button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

BasicModal.displayName = 'BasicModal';
export default BasicModal;
