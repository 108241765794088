import * as React from 'react';
import { printCoreWarning } from '../../../shared';
import { ClientThemeType, ThemeClient, Palette, withTheme } from '../../../../theme/index';
import { LoaderThemeMapperType, ThemedLoaderProperties, LoaderThemeType } from '../types';

/**
 * Function that mappes client passed theme to our component's theme.
 * Used to limit impacts of future changes to client theme to our Loader component.
 */
const themeMapper: LoaderThemeMapperType = (
  theme: ThemeClient | undefined,
  { palette = Palette.brand, themeOverwrite }: { palette?: Palette; themeOverwrite?: LoaderThemeType }
): LoaderThemeType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette && palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Loader.Themed component must have a palette. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    return {
      color: themeOverwrite?.color || selectedPalette.main,
    };
  }
  return undefined;
};

const ThemedLoader: React.FunctionComponent<ThemedLoaderProperties & { Component: any }> = ({
  theme,
  palette,
  themeOverwrite,
  Component,
  ...otherProps
}) => {
  const mappedTheme = themeMapper(theme, { palette, themeOverwrite });
  return <Component theme={mappedTheme} {...otherProps} />;
};

/**
 * Default themed export (Themed component and the mapper function)
 *
 * @component
 * @visibleName Loader.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedLoaderProperties & { Component: any }> & {
  mapper?: LoaderThemeMapperType;
} = withTheme(ThemedLoader);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'Loader.Themed';
ThemedExport.defaultProps = {
  palette: Palette.brand,
};

export default ThemedExport;
