import * as React from 'react';
import styled from 'styled-components';
import Basic from './Basic';
import checkValue from '../../../../styles/checkValue';
import {
  ClientThemeType,
  ThemeClient,
  Palette,
  ColorDefinition,
  withTheme,
} from '../../../../theme/index';
import {
  ThemedLinkProperties,
  LinkThemeMapperType,
  LinkThemeType,
  BasicLinkProperties,
} from '../types';
import { printCoreWarning } from '../../../shared';

const StyledLink = styled(Basic)`
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  display: block;
  height: 30px;
  padding: 5px 10px 6px 10px;
  font-size: 13px;
  box-sizing: border-box;

  ${({ theme }: BasicLinkProperties) => {
    let styles = '';
    if (theme?.default) {
      styles += `
        ${
          theme?.default.backgroundColor
            ? `background-color: ${checkValue(theme?.default.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.default.borderColor
            ? `border-color: ${checkValue(theme?.default.borderColor, 'colors')};`
            : ''
        }
        ${theme?.default.color ? `color: ${checkValue(theme?.default.color, 'colors')};` : ''}
      `;
    }
    if (theme?.hover) {
      styles += `
      &:hover {
        ${
          theme?.hover.backgroundColor
            ? `background-color: ${checkValue(theme?.hover.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.hover.borderColor
            ? `border-color: ${checkValue(theme?.hover.borderColor, 'colors')};`
            : ''
        }
        ${theme?.hover.color ? `color: ${checkValue(theme?.hover.color, 'colors')};` : ''}
      }`;
    }
    if (theme?.disabled) {
      styles += `
      &:disabled {
        ${
          theme?.disabled.backgroundColor
            ? `background-color: ${checkValue(theme?.disabled.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.disabled.borderColor
            ? `border-color: ${checkValue(theme?.disabled.borderColor, 'colors')};`
            : ''
        }
        ${theme?.disabled.color ? `color: ${checkValue(theme?.disabled.color, 'colors')};` : ''}
      }`;
    }
    if (theme?.focus) {
      styles += `
      &:focus{
        ${
          theme?.focus.backgroundColor
            ? `background-color: ${checkValue(theme?.focus.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.focus.borderColor
            ? `border-color: ${checkValue(theme?.focus.borderColor, 'colors')};`
            : ''
        }
        ${theme?.focus.color ? `color: ${checkValue(theme?.focus.color, 'colors')};` : ''}
        box-shadow: 0 0 5px 0 rgba(62, 62, 64, 0.5);
        outline: none;
      }`;
    }
    // must be after focus so it's not overwritten
    if (theme?.active) {
      styles += `
      &:active {
        ${
          theme?.active.backgroundColor
            ? `background-color: ${checkValue(theme?.active.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.active.borderColor
            ? `border-color: ${checkValue(theme?.active.borderColor, 'colors')};`
            : ''
        }
        ${theme?.active.color ? `color: ${checkValue(theme?.active.color, 'colors')};` : ''}
      }`;
    }
    return styles;
  }}
`;

const themeMapper = (
  theme: ThemeClient = {},
  {
    palette = Palette.brand,
    variant,
    themeOverwrite,
  }: { palette?: Palette; variant: string; themeOverwrite?: LinkThemeType }
): LinkThemeType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Link.Themed component must pass a theme. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    if (variant === 'filled') {
      return getFilledStyle(selectedPalette, themeOverwrite);
    }
    if (variant === 'outlined') {
      return getOutlineStyle(selectedPalette, themeOverwrite);
    }
    printCoreWarning(
      `Unrecognized variant passed to Link.Themed component. Your theme is ignored.`
    );
    return undefined;
  }

  return undefined;
};

const ThemedLink = ({
  theme,
  palette = Palette.brand,
  variant = 'filled',
  themeOverwrite,
  ...otherProps
}: ThemedLinkProperties) => {
  const mappedTheme = themeMapper(theme || {}, {
    palette,
    variant,
    themeOverwrite,
  });
  return <StyledLink theme={mappedTheme} {...otherProps} />;
};

const getFilledStyle = (palette: ColorDefinition, themeOverwrite?: LinkThemeType) => {
  // TODO ADD MEMOIZATION !
  return {
    default: {
      backgroundColor: themeOverwrite?.default?.backgroundColor || palette.main,
      borderColor: themeOverwrite?.default?.borderColor || palette.main,
      color: themeOverwrite?.default?.color || palette.contrast,
    },
    hover: {
      backgroundColor: themeOverwrite?.hover?.backgroundColor || palette.main,
      borderColor: themeOverwrite?.hover?.borderColor || palette.main,
      color: themeOverwrite?.hover?.color || palette.contrast,
    },
    active: {
      backgroundColor: themeOverwrite?.active?.backgroundColor || palette.light,
      borderColor: themeOverwrite?.active?.borderColor || palette.light,
      color: themeOverwrite?.active?.color || palette.contrast,
    },
    focus: {
      backgroundColor: themeOverwrite?.focus?.backgroundColor || palette.main,
      borderColor: themeOverwrite?.focus?.borderColor || 'colors.grey.1200',
      color: themeOverwrite?.focus?.color || palette.contrast,
      outlineColor: 'transparent',
    },
    disabled: {
      backgroundColor: themeOverwrite?.disabled?.backgroundColor || palette.lighter,
      borderColor: themeOverwrite?.disabled?.borderColor || palette.lighter,
      color: themeOverwrite?.disabled?.color || palette.contrast,
    },
  };
};

const getOutlineStyle = (palette: ColorDefinition, themeOverwrite?: LinkThemeType) => {
  // TODO ADD MEMOIZATION !
  return {
    default: {
      backgroundColor: themeOverwrite?.default?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.hover?.borderColor || palette.main,
      color: themeOverwrite?.default?.color || palette.main,
    },
    hover: {
      backgroundColor: themeOverwrite?.hover?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.hover?.borderColor || palette.main,
      color: themeOverwrite?.hover?.color || palette.main,
    },
    active: {
      backgroundColor: themeOverwrite?.active?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.active?.borderColor || palette.light,
      color: themeOverwrite?.active?.color || palette.light,
    },
    focus: {
      backgroundColor: themeOverwrite?.focus?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.focus?.borderColor || 'colors.grey.1200',
      color: themeOverwrite?.focus?.color || palette.main,
      outlineColor: 'transparent',
    },
    disabled: {
      backgroundColor: themeOverwrite?.disabled?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.disabled?.borderColor || palette.lighter,
      color: themeOverwrite?.disabled?.color || palette.lighter,
    },
  };
};
/**
 * Themed variant of the Link component that provides the same style to the link as does the themed button.
 * Use this component if you need a link displayed with the same styling as a themed button but with the
 * semantic meaning of a link (href used).
 *
 * If you need additional styling applied, make sure to add a new variant.
 * @component
 * @visibleName Link.Themed
 */
const ThemedExport: React.FC<ThemedLinkProperties> & {
  mapper?: LinkThemeMapperType;
} = withTheme(ThemedLink);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'Link.Themed';
export default ThemedExport;
