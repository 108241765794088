import * as React from 'react';
import Basic from './components/Basic';
import Footer from './components/Footer';
import Header from './components/Header';
import Content from './components/Content';
import { BasicCardProperties } from './types';
import { ReactComponent as Svg } from './card.svg';

/** Static properties of the Card.
 * Useful when exporting different components under the same name */
type CardStatic = {
  Footer: any;
  Content: any;
  Header: any;
  svg: React.FunctionComponent;
};

/** Basic implementation of the Card component - an object that contains some content and actions related to that object.<br/>
 * This implementation takes the [Material Design's definition](https://material.io/components/cards) of cards for referrence.
 * @component
 */
const Card: React.FunctionComponent<BasicCardProperties> & CardStatic = (
  props: BasicCardProperties
) => <Basic {...props} />;

Card.Header = Header;
Card.Content = Content;
Card.Footer = Footer;

Card.displayName = 'Card';
Card.svg = Svg;

Card.defaultProps = {
  elevated: false,
};

export default Card;
