import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { TooltipChildType } from '../types';

/**
 * Our Tooltip component can have simple and complex content.
 * In case of more complex content, we split it into Header, Footer, and Content.
 * The Tooltip.Header component is passed as a child to the Tooltip and it makes sure to position itself
 * as first child. It is not required as a child but it can be used if needed.
 *
 * @component
 * @visibleName Tooltip.Header
 */
const TooltipHeader: React.FunctionComponent<TooltipChildType> = ({
  className,
  style,
  ...props
}) => (
  <div style={style} className={classnames(styles['tooltip__inner__header'], className)}>
    {props.children}
  </div>
);

TooltipHeader.displayName = 'Tooltip.Header';

export default TooltipHeader;
