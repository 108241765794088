import React from 'react';

const availableElementSelector = ":scope > li > [role='menuitem']:not(:disabled)";

const handleKeyDown = (onChildEnter, onChildLeave) => (
  event: React.KeyboardEvent<HTMLButtonElement>
) => {
  const { target } = event;
  // Escape
  /**
   * Close (sub)menu that was opened and return focus to the parent
   */
  if (event.keyCode === 27) {
    const parent = (target as HTMLElement).parentElement?.parentElement;
    const menuItem = parent?.previousElementSibling;
    (menuItem as HTMLElement)?.focus();
    onChildLeave();
    return;
  }
  // Enter
  /**
   * When focus is on a menuitem that has a submenu, opens the submenu and places focus on it's first item
   * When a focus is on a menu button, opens the menu and places focus on it's first item
   * Since our submenus and menu button are buttons, onClick handler will be called on this state
   * so we got that covered.
   * This piece of code needs to handle custom opening of child
   */
  if (event.keyCode === 13 || event.key === 'Enter') {
    // By aria proposal, the <li> element has a button with role menu item. That
    // element has a ul element as a direct sibling. Based on this
    // we can be sure to select the first desendent in such way
    const submenu = (target as HTMLElement).nextElementSibling;
    const focusable = submenu?.querySelector(availableElementSelector);
    onChildEnter && onChildEnter();
    // Needed to make sure the element is rendered
    setTimeout(() => {
      (focusable as HTMLElement)?.focus();
    }, 10);

    return;
  }
  // Arrow up
  if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopPropagation();

    const liElement = (target as HTMLElement).parentElement;
    const ulElement = liElement?.parentElement;

    // If both elements exist
    if (liElement && ulElement) {
      let focusable;
      const allChildren = ulElement.querySelectorAll(availableElementSelector);
      const lastChild = allChildren[allChildren.length - 1];

      let index = -1;
      allChildren.forEach((child, childIndex) => {
        if (child === target) {
          index = childIndex;
        }
      });
      if (index === 0) {
        focusable = lastChild;
      } else if (index !== -1) {
        focusable = allChildren[index - 1];
      }
      (focusable as HTMLElement)?.focus();
    }
  }
  // Arrow down
  if (event.key === 'ArrowDown' || event.key === 'Tab') {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
    const liElement = (target as HTMLElement).parentElement;
    const ulElement = liElement?.parentElement;

    // If both elements exist
    if (liElement && ulElement) {
      let focusable;
      const allChildren = ulElement.querySelectorAll(availableElementSelector);
      const firstChild = allChildren[0];

      let index = -1;
      allChildren.forEach((child, childIndex) => {
        if (child === target) {
          index = childIndex;
        }
      });
      if (index === allChildren.length - 1) {
        focusable = firstChild;
      } else if (index !== -1) {
        focusable = allChildren[index + 1];
      }
      (focusable as HTMLElement)?.focus();
    }
  }
};

const useOutsideClickHandler = (ref, clickOutsideCallback) => {
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const { target } = event;
      const wrapper = ref.current;

      if (wrapper && !(wrapper.contains(target as Node) || wrapper === target)) {
        clickOutsideCallback(event);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [ref, clickOutsideCallback]);
};

export { handleKeyDown, useOutsideClickHandler };
