import * as React from 'react';
import classnames from 'classnames';
import { StyledSublist } from './Styled';
import MenuButton from './Button';
import useVisibilityHook from './useVisibilityHook';
import styles from '../index.module.scss';
import { MenuProperties } from '../types';
import { handleKeyDown, useOutsideClickHandler } from './eventHandlers';

/**
 * Basic implementation of the Menu component.
 *
 * @since 0.6.0
 */

const Menu: React.FunctionComponent<MenuProperties> = ({
  children,
  className,
  id,
  testId,
  includeHover,
  elevation,
  ariaLabelledBy,
  position,
  disabled,
  classNames,
  label,
  icon,
}) => {
  const [
    isVisible,
    { childVisibilityUpdate, onChildEnter, onChildLeave, onClickHandler, onLeave, setShow },
  ] = useVisibilityHook({
    initialVisibility: false,
    includeHover,
  });
  const wrapperRef = React.useRef(null);
  useOutsideClickHandler(wrapperRef, () => setShow(false));

  const [menuButtonChild, onlyChildren] = React.useMemo(() => {
    const componentChildren = React.Children.toArray(children);
    return [
      componentChildren.find((child) => {
        return (child as React.ReactElement)?.type['displayName'] === 'Menu.Button';
      }),
      React.Children.toArray(children).filter(
        (child) => (child as React.ReactElement)?.type['displayName'] !== 'Menu.Button'
      ),
    ];
  }, [children]);

  const closeAll = React.useCallback(() => {
    onChildLeave();
    onLeave();
  }, [onChildLeave, onLeave]);

  const menuButton = React.useMemo(() => {
    if (!!menuButtonChild) {
      return React.cloneElement(menuButtonChild as React.ReactElement, {
        ...(menuButtonChild as React.ReactElement).props,
        onClick: !disabled ? onClickHandler : undefined,
        onKeyDown: !disabled ? handleKeyDown(onChildEnter, closeAll) : undefined,
        className: classnames(
          classNames?.button,
          styles.menu__button,
          className ? `${className}__menu-button` : undefined,
          (menuButtonChild as React.ReactElement).props.className
        ),
        ariaHasPopup: 'menu',
        disabled,
        ariaDisabled: disabled,
        ariaExpanded: isVisible && !disabled,
      });
    }
    return (
      <MenuButton
        onClick={onClickHandler as React.MouseEventHandler}
        ariaExpanded={isVisible}
        onKeyDown={handleKeyDown(onChildEnter, closeAll)}
        className={classnames(
          styles.menu__button,
          classNames?.button,
          className ? `${className}__menu-button` : undefined
        )}
        disabled={disabled}
        label={label}
        icon={icon}
      />
    );
  }, [
    menuButtonChild,
    onClickHandler,
    classNames,
    className,
    onChildEnter,
    closeAll,
    isVisible,
    disabled,
    label,
    icon,
  ]);

  return (
    <div
      ref={wrapperRef}
      className={classnames(styles.menu__wrapper, {
        [`${className}__wrapper`]: !!className,
        [styles.disabled]: disabled,
      })}
      onMouseEnter={includeHover ? onChildEnter : undefined}
      onMouseLeave={includeHover ? onChildLeave : undefined}
      id={id}
      data-test-id={testId}
    >
      {menuButton}
      <StyledSublist
        elevation={elevation}
        role="menu"
        ariaLabelledBy={ariaLabelledBy}
        className={classnames(styles.menu, classNames?.menu, styles[position || 'bottom'], {
          className: !!className,
          [styles['hovered']]: isVisible && !disabled,
        })}
      >
        {React.Children.map(onlyChildren, (child) =>
          React.cloneElement(child as React.ReactElement, {
            ...(child as React.ReactElement).props,
            notifyParent: childVisibilityUpdate,
            isParentVisible: isVisible,
            includeHover,
          })
        )}
      </StyledSublist>
    </div>
  );
};

Menu.displayName = 'Menu';
export default Menu;
