import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import styles from '../index.module.scss';
import checkValue from '../../../../styles/checkValue';
import Flex from '../../../Layout/Flex';
import Typography from '../../../General/Typography';
import Icon from '../../../General/Icon';

import { BasicButtonProperties } from '../types';
import { printCoreWarning } from '../../../shared';

const StyledButton = styled.button`
  font-size: ${({ fontSize }: BasicButtonProperties) => checkValue(fontSize)};
  ${({ theme }: BasicButtonProperties) => {
    let styles = '';
    if (theme?.default) {
      styles += `
        ${
          theme?.default.backgroundColor
            ? `background-color: ${checkValue(theme?.default.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.default.borderColor
            ? `border-color: ${checkValue(theme?.default.borderColor, 'colors')};`
            : ''
        }
        ${theme?.default.color ? `color: ${checkValue(theme?.default.color, 'colors')};` : ''}
      `;
    }
    if (theme?.hover) {
      styles += `
      &:hover {
        ${
          theme?.hover.backgroundColor
            ? `background-color: ${checkValue(theme?.hover.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.hover.borderColor
            ? `border-color: ${checkValue(theme?.hover.borderColor, 'colors')};`
            : ''
        }
        ${theme?.hover.color ? `color: ${checkValue(theme?.hover.color, 'colors')};` : ''}
      }`;
    }
    if (theme?.disabled) {
      styles += `
      &:disabled {
        ${
          theme?.disabled.backgroundColor
            ? `background-color: ${checkValue(theme?.disabled.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.disabled.borderColor
            ? `border-color: ${checkValue(theme?.disabled.borderColor, 'colors')};`
            : ''
        }
        ${theme?.disabled.color ? `color: ${checkValue(theme?.disabled.color, 'colors')};` : ''}
      }`;
    }
    if (theme?.focus) {
      styles += `
      &:focus{
        ${
          theme?.focus.backgroundColor
            ? `background-color: ${checkValue(theme?.focus.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.focus.borderColor
            ? `border-color: ${checkValue(theme?.focus.borderColor, 'colors')};`
            : ''
        }
        ${theme?.focus.color ? `color: ${checkValue(theme?.focus.color, 'colors')};` : ''}
        box-shadow: 0 0 5px 0 rgba(62, 62, 64, 0.5);
        outline: none;
      }`;
    }
    // must be after focus so it's not overwritten
    if (theme?.active) {
      styles += `
      &:active {
        ${
          theme?.active.backgroundColor
            ? `background-color: ${checkValue(theme?.active.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.active.borderColor
            ? `border-color: ${checkValue(theme?.active.borderColor, 'colors')};`
            : ''
        }
        ${theme?.active.color ? `color: ${checkValue(theme?.active.color, 'colors')};` : ''}
      }`;
    }
    return styles;
  }}
`;

/**
 * A basic component implementing the button element.
 * @visibleName Button.Basic
 */
const BasicButton: React.FunctionComponent<BasicButtonProperties> = ({
  disabled,
  children,
  className,
  style,
  round = 'small',
  theme,
  basic = true,
  padding = 'medium',
  sneaky = false,
  testId,
  onClick,
  hidden,
  id,
  styleAsLink,
  icon,
  label,
  ...other
}) => {
  if (styleAsLink && sneaky) {
    printCoreWarning(
      'You are trying to use sneaky with styleAsLink property in a Button. This will not work as styleAsLink will override these styles. Are you sure you wanted to do this?'
    );
  }
  if (styleAsLink && round) {
    printCoreWarning(
      'You are trying to use round with styleAsLink property in a Button. This will not work as styleAsLink will override the styles for a round button. Are you sure you wanted to do this?'
    );
  }
  if (children && (label || icon)) {
    printCoreWarning(
      'You are trying to use children as well as pass either label or icon. If you use children, we ignore label and icon props. Are you sure you want to do this?'
    );
  }

  const IconLabelContent = (label || icon) && (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={styles.iconLabelContentFlexWrapper}
    >
      {icon && typeof icon === 'string' && (
        <Icon icon={icon} fontSize="15px" className={styles.icon} />
      )}
      {icon && React.isValidElement(icon) && icon}
      {label && <Typography className={styles.label}>{label}</Typography>}
    </Flex>
  );

  return (
    <StyledButton
      id={id}
      data-test-id={testId ? testId : undefined}
      disabled={disabled}
      style={style}
      theme={theme}
      className={classnames(styles.button, className, {
        [styles[`button--${padding}`]]: true,
        [styles['button--sneaky']]: sneaky && !styleAsLink,
        [styles[`button--rounded-${round}`]]: true,
        [styles['button--basic']]: basic && !sneaky && !styleAsLink,
        [styles['button--link']]: styleAsLink,
      })}
      hidden={hidden}
      onClick={!disabled ? onClick : undefined}
      {...other}
    >
      {children ? children : IconLabelContent}
    </StyledButton>
  );
};

BasicButton.displayName = 'BasicButton';

export default BasicButton;
