import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import Flex from '../../../Layout/Flex';
import Typography from '../../../General/Typography';
import Icon from '../../../General/Icon';

import { SelectOptionProperties } from '../types';

/**
 * Basic implementation of the Option component intended
 * for using with the Select Component.
 * Accessible as `Select.Option`.
 *
 * @visibleName Select.Option
 * @since 0.4.0
 */

const Option: React.FunctionComponent<SelectOptionProperties> = ({
  className,
  selected,
  onSelect,
  children,
  outsideButton,
  id,
  style,
  disabled,
  onKeyDown,
  heading,
  testId,
  icon,
  label,
  autoMaxWidth,
}) => {
  const selectCallback = React.useCallback(() => {
    if (onSelect) {
      onSelect(id);
    }
  }, [onSelect, id]);

  const IconWithLabelComp = () => {
    return (
      <Flex justifyContent="flex-start" alignItems="center">
        {icon && typeof icon === 'string' && <Icon icon={icon} />}
        {icon && React.isValidElement(icon) && icon}
        {label && (
          <Typography
            className={classnames(styles.select__listbox__option__label, {
              [styles['select__listbox__option__label--autoWidth']]: autoMaxWidth,
            })}
          >
            {label}
          </Typography>
        )}
      </Flex>
    );
  };

  return (
    <div
      style={{ ...style, maxWidth: autoMaxWidth }}
      onKeyDown={onKeyDown}
      onClick={onSelect && outsideButton && !heading ? selectCallback : undefined}
      tabIndex={outsideButton && !heading ? 0 : undefined}
      className={classnames(className, styles.select__listbox__option, {
        [styles['select__listbox__option--disabled']]: disabled,
        [styles['select__listbox__option--selected']]: selected,
        [styles['select__listbox__option--autoWidth']]: autoMaxWidth,
      })}
      id={id}
      data-test-id={testId}
      role={outsideButton && !heading ? 'option' : 'presentation'}
      // in multiselect, if it's not selectable, it does not have this attribute present
      // in single select, can be omitted.
      aria-selected={!disabled && !heading ? selected : undefined}
    >
      {children ? children : IconWithLabelComp()}
    </div>
  );
};

Option.displayName = 'Select.Option';
Option.defaultProps = {
  outsideButton: false,
};

export default Option;
