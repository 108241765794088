/**
 * # HTML Adapter
 *
 *
 */

import type { AppConfig } from '@searchmetrics/sm-discover';
import type { SmDiscoverModuleVersion } from '~/utilities/module';
import { getSmDiscoverRemoteModule, initializeRemoteSmDiscoverApp } from '~/utilities/module';

/**
 *
 */
export type RenderToDOMConfig = AppConfig & {
  /**
   *
   */
  version?: SmDiscoverModuleVersion;
};

/**
 *
 */
export function renderToDOM(node: Element, config: RenderToDOMConfig = {}) {
  const { version, ...appConfig } = config;

  const remoteModule = getSmDiscoverRemoteModule(version);

  return initializeRemoteSmDiscoverApp(remoteModule, node, appConfig).catch((error) => {
    throw error;
  });
}
