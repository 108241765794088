import * as React from 'react';
import { printCoreWarning } from '../../../shared';
import { withTheme, ClientThemeType, ThemeClient, Palette } from '../../../../theme/index';
import Basic from './Basic';
import { ListThemeMapperType, ThemedListProperties } from '../types';

/**
 * Function that maps client passed theme to our component's theme.
 * Used to limit impacts of future changes to client theme to our List component.
 *
 */
const themeMapper: ListThemeMapperType = (theme, { palette = Palette.brand, themeOverwrite }) => {
  const clientTheme = theme as ClientThemeType;
  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `List.Themed component must pass a theme. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    return {
      color: themeOverwrite?.color || selectedPalette.main,
      fontSize: themeOverwrite?.fontSize,
    };
  }

  return undefined;
};

const ThemedList: React.FunctionComponent<ThemedListProperties> = ({
  theme,
  palette = Palette.brand,
  themeOverwrite,
  ...otherProps
}) => {
  const calculatedTheme = themeMapper(theme as ThemeClient, {
    palette,
    themeOverwrite,
  });
  return <Basic theme={calculatedTheme} {...otherProps} />;
};

/**
 * Default themed export (Themed component and the mapper function)
 * @component
 * @visibleName List.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedListProperties> & {
  mapper?: ListThemeMapperType;
} = withTheme(ThemedList);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'List.Themed';
export default ThemedExport;
