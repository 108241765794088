import * as React from 'react';
import styled from 'styled-components';

import { ArrowTagProps } from './types';
import valueExtractor from '../../../../../styles/checkValue';

export const StyledTag: React.FunctionComponent<ArrowTagProps> = styled.div<ArrowTagProps>`
  padding-left: calc(${(props) => props.height} / 2);
`;

export const StyledTagBody: React.FunctionComponent<ArrowTagProps> = styled.div<ArrowTagProps>`
  width: calc(100% - (${(props) => props.height} / 2));
  height: ${(props) => props.height};
  border-bottom-right-radius: 0.2em;
  border-top-right-radius: 0.2em;

  background-color: ${({ borderColor, theme }) =>
    valueExtractor(borderColor) || theme?.default?.borderColor};
  svg {
    polygon {
      fill: ${({ borderColor, theme }) =>
        valueExtractor(borderColor) || theme?.default?.borderColor};
    }
  }
`;

export const StyledTagBodyInner: React.FunctionComponent<ArrowTagProps> = styled.div<ArrowTagProps>`
  width: calc(
    100% - (${(props) => props.height} / 2) - ${(props) => props.borderWidth} -
      (${(props) => props.borderWidth} * 0.29)
  );
  height: calc(${(props) => props.height} - ${(props) => props.borderWidth} * 2);
  top: ${(props) => props.borderWidth};
  right: ${(props) => props.borderWidth};

  border-bottom-right-radius: calc(0.2em - ${(props) => props.borderWidth} * 0.8);
  border-top-right-radius: calc(0.2em - ${(props) => props.borderWidth} * 0.8);

  background-color: ${({ backgroundColor, theme }) =>
    valueExtractor(backgroundColor) || theme?.default?.backgroundColor};
  svg {
    polygon {
      fill: ${({ backgroundColor, theme }) =>
        valueExtractor(backgroundColor) || theme?.default?.backgroundColor};
    }
  }
`;

export const StyledTagContent: React.FunctionComponent<ArrowTagProps> = styled.div<ArrowTagProps>`
  height: ${(props) => props.height};
  color: ${({ color, theme }) => valueExtractor(color) || theme?.default?.color};
  padding-left: calc(${({ horizontalPadding }) => horizontalPadding} - 0.3em);
  padding-right: ${({ horizontalPadding }) => horizontalPadding};
`;
