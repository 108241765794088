import * as React from 'react';
import { ClientThemeType, Palette, withTheme } from '../../../../theme';
import { printCoreWarning } from '../../../shared';
import { ThemedTagProps, TagThemeMapperType, TagThemedExportType } from '../types';
import BasicTag from './Basic';

const themeMapper: TagThemeMapperType = (theme, { palette= Palette.brand, themeOverwrite }) => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Tag.Themed component must have a palette. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      // return undefined;
    }
    return {
      default: {
        backgroundColor: themeOverwrite?.default?.backgroundColor || selectedPalette.main,
        color: themeOverwrite?.default?.color || selectedPalette.contrast,
        borderColor: themeOverwrite?.default?.borderColor || selectedPalette.main,
      },
    };
  }

  return undefined;
};

/**
 * Themed toggle implementation.
 * Special parameters include theme, palette and the themeOverwrite object.
 * @param {ThemedTagProps} properties
 */
const ThemedTag = ({
  theme,
  palette = Palette.brand,
  themeOverwrite,
  ...otherProps
}: ThemedTagProps) => {
  const mappedTheme = themeMapper(theme || {}, { palette, themeOverwrite });
  return <BasicTag theme={mappedTheme} {...otherProps} />;
};

/**
 * @component
 * @visibleName Tag.Themed
 */
const ThemedExport: TagThemedExportType = withTheme(ThemedTag);

ThemedExport.mapper = themeMapper;

ThemedExport.displayName = 'Tag.Themed';
export default ThemedExport;
