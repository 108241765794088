import * as React from 'react';
import styles from '../index.module.scss';
import { TagProps, TagThemeOverwriteType } from '../types';
import { StyledTag } from './Styled';

export type BasicTagProps = {
  /** Determine shape of Tag */
  shape?: 'square' | 'round' | 'pill';
  color?: string;
  backgroundColor?: string;
  borderColor?: string;

  /**
   * Id of the wrapper element.
   */
  id?: string;

  /**
   * Passed as value to "data-test-id" attribute.
   */
  testId?: string;

  /** className passed to the wrapping div element */
  className?: string;

  /**
   * A prop that should not be visible in the documentation.
   * Passed to the basic component by the themed variant.

   *  @ignore
   */
  theme?: TagThemeOverwriteType;
};

const BasicTag: React.FunctionComponent<TagProps> = ({
  children,
  className,
  shape,
  color,
  backgroundColor,
  borderColor,
  testId,
  id,
  ...others
}) => {
  const shapeClass = styles[`tag--${shape}`];
  const cssClasses = `${styles.tag} ${shapeClass} ${className || ''}`; // override, do not replace

  return (
    <StyledTag
      id={id}
      data-test-id={testId ? testId : undefined}
      className={cssClasses}
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      children={children}
      {...others}
    />
  );
};

BasicTag.defaultProps = {
  shape: 'round',
};

export default BasicTag;
