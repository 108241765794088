import * as React from 'react';
import Basic from './components/Basic';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import Themed from './components/Themed';
import Icon from '../../../components/General/Icon';
import { ReactComponent as Svg } from './tooltip.svg';
import styles from './index.module.scss';

import { BasicTooltipProperties, ThemedTooltipProperties } from './types';

/**
 * Static properties of the Tooltip.
 * Used for exporting different components under the same
 * name
 * */
type TooltipStatic = {
  Themed: typeof Themed;
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
  svg: React.FunctionComponent;
};

/**
 * A tooltip is a popup that displays information related to an element when the element recieves keyboard focus or the
 * mouse hovers over it. In our implementation, we also support display on click.
 * Usually the tooltip appears with some delay.
 * @component
 * @since 0.5.0
 * @visibleName Tooltip
 * */
const Tooltip: React.FunctionComponent<BasicTooltipProperties> & TooltipStatic = (
  props: BasicTooltipProperties
) => <Basic {...props} />;

const defaultProperties: Omit<ThemedTooltipProperties, 'id' | 'children' | 'palette'> = {
  triggerAction: 'click',
  position: 'top',
  arrow: false,
  trigger: <Icon icon="icons.icon.help" className={styles.icon} />,
  delay: 0,
  animation: 0,
};

Tooltip.Header = Header;
Tooltip.Content = Content;
Tooltip.Footer = Footer;
Tooltip.Themed = Themed;

Tooltip.svg = Svg;
Tooltip.defaultProps = defaultProperties;
Tooltip.Themed.defaultProps = defaultProperties;
Tooltip.displayName = 'Tooltip';

export default Tooltip;
