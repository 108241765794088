import * as React from 'react';
import styled from 'styled-components';
import checkValue from '../../../../styles/checkValue';
import { printCoreWarning } from '../../../shared';
import styles from '../index.module.scss';
import { BasicTypographyProperties, Component } from '../types';

const printWarning = (propName: string) => {
  printCoreWarning(
    `\n\rYou are passing both the theme and ${propName} property. The property ${propName} will override the passed ${propName} from the theme.\n\rIs this what you wanted to achieve?`
  );
};

export const defaultStyles = {
  color: '#000',
  component: Component.div,
  dangerouslySetValues: false,
};

/**
 * General component description in JSDoc format. Markdown is *supported*.
 * PS this is a comment on top of my component! Not in md file :)
 * The only true Typography component.
 */

const BasicTypography: React.FunctionComponent<BasicTypographyProperties> = (
  props: React.PropsWithChildren<BasicTypographyProperties> = defaultStyles
) => {
  const { children, component, className, style, id, testId } = props;
  let renderedComponent = Component.div;
  if (component && Object.values(Component).includes(component)) renderedComponent = component;
  return React.createElement(
    renderedComponent,
    {
      className: `${styles.typography} ${className}`,
      'data-test-id': testId ? testId : undefined,
      id,
      style,
    },
    children
  );
};

/**
 * A styled wrapper around BasicTypography element that handles how styles
 * should be passed down.
 * Generates a class name with selected styles that is passed down to BasicTypography
 * @component
 */
const StyledTypography = styled(BasicTypography)<BasicTypographyProperties>`
  ${(props: BasicTypographyProperties) => {
    let styles = ``;
    styles += setPropertyStyle(
      props.clientTheme?.default.color,
      checkValue(props.color, 'colors'),
      'color'
    );
    styles += setPropertyStyle(
      props.clientTheme?.default.fontSize,
      checkValue(props.fontSize),
      'font-size'
    );
    styles += setPropertyStyle(
      props.clientTheme?.default.weight,
      checkValue(props.fontWeight),
      'font-weight'
    );
    styles += setPropertyStyle(props.clientTheme?.default.alignment, undefined, 'text-align');
    styles += setPropertyStyle(
      props.clientTheme?.default.letterSpacing,
      undefined,
      'letter-spacing'
    );
    styles += setPropertyStyle(props.clientTheme?.default.lineHeight, undefined, 'line-height');
    return styles;
  }}
  // Hover styles
  ${(props: BasicTypographyProperties) => {
    let styles = `&:hover {`;
    styles += setPropertyStyle(
      props.clientTheme?.hover?.color,
      checkValue(props.color, 'colors'),
      'color'
    );
    styles += setPropertyStyle(
      props.clientTheme?.hover?.fontSize,
      checkValue(props.fontSize),
      'font-size'
    );
    styles += setPropertyStyle(
      props.clientTheme?.hover?.weight,
      checkValue(props.fontWeight),
      'font-weight'
    );
    styles += setPropertyStyle(props.clientTheme?.hover?.alignment, undefined, 'text-align');
    styles += setPropertyStyle(
      props.clientTheme?.hover?.letterSpacing,
      undefined,
      'letter-spacing'
    );
    styles += setPropertyStyle(props.clientTheme?.hover?.lineHeight, undefined, 'line-height');
    styles += '}';
    return styles;
  }}
`;

const setPropertyStyle = (
  themeProperty: string | undefined,
  property: string | undefined,
  cssPropertyName: string
) => {
  if (themeProperty && property) {
    printWarning(cssPropertyName);
  }
  if (!themeProperty && !property) return ``;
  return `
    ${cssPropertyName}: ${property || themeProperty};
  `;
};

StyledTypography.displayName = 'StyledTypography';
export default StyledTypography;
