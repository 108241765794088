import * as React from 'react';
import classnames from 'classnames';
import Button from '../../../General/Button';
import styles from '../index.module.scss';
import { BasicAccordionProperties } from '../types';
import { printCoreWarning } from '../../../shared';

type ChildElementType = React.ReactElement & {
  type: {
    displayName: string;
  };
};

/**
 * Basic implementation of the Accordion component.
 *
 * @displayName Accordion.Basic
 * @since 0.3.0
 */

const BasicAccordion: React.FunctionComponent<BasicAccordionProperties> = (props) => {
  const { expanded, onClick, className, style, disabled, children, id, testId, classNames } = props;

  const componentChildren = React.Children.toArray(children);

  const titleChild = componentChildren.find(
    (child) => (child as ChildElementType).type.displayName === 'Accordion.Title'
  );

  const contentChild = componentChildren.find(
    (child) => (child as ChildElementType)?.type?.displayName === 'Accordion.Content'
  );

  if (!titleChild || !contentChild || !children) {
    printCoreWarning(
      'Accordion requires Accordion.Title and Accordion.Content as children. Both are needed to work.'
    );
  }

  return (
    <div
      id={id}
      data-test-id={testId ? testId : undefined}
      className={classnames({
        Accordion: true,
        [className as string]: !!className,
        [styles.Accordion]: true,
        [styles.Accordion__disabled]: disabled,
      })}
      style={style}
    >
      {!!onClick ? (
        <Button
          sneaky
          className={classnames(styles.accordion__button, {
            [`${className}__button`]: !!className,
            [classNames?.button || '']: !!classNames?.button,
          })}
          onClick={!disabled ? onClick : undefined}
          disabled={disabled}
        >
          {titleChild}
        </Button>
      ) : (
        titleChild
      )}
      {expanded && contentChild}
    </div>
  );
};

BasicAccordion.displayName = 'Accordion.Basic';
export default BasicAccordion;
