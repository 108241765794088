import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import Group from './components/Group';
import {
  BasicButtonProperties,
  ButtonGroupType,
  ThemedButtonProperties,
  ButtonThemeVariant,
  ButtonThemeOverwriteType,
  ButtonThemeType,
} from './types';
import { Palette, ThemeClient } from '../../../theme';
import { ReactComponent as Svg } from './buttons.svg';

type ButtonStatic = {
  Themed: React.FunctionComponent<ThemedButtonProperties> & {
    mapper?: (
      theme: ThemeClient,
      params: {
        palette: Palette;
        variant: ButtonThemeVariant;
        themeOverwrite: ButtonThemeOverwriteType;
      }
    ) => ButtonThemeType | undefined;
  };
  Group: React.FunctionComponent<ButtonGroupType>;
  svg: React.FunctionComponent;
};

/**
 * A element that enables users to trigger an action or event. In it's basic form, it's rendered as an `button` HTML element. <br>
 * Exposes **Button.Themed** as a themed component, and **Button.Group** as a wrapper aroung these button related components.
 * This button is very opinionated on styles as it generates a lot of code based on it.
 * @component
 */
const Button: React.FunctionComponent<BasicButtonProperties> & ButtonStatic = (
  props: BasicButtonProperties
) => <Basic {...props} />;

Button.Themed = Themed;
Button.Group = Group;

Button.displayName = 'Button';
Button.defaultProps = {
  basic: true,
  sneaky: false,
  round: 'small',
  padding: 'medium',
  styleAsLink: false,
};
Button.svg = Svg;
export default Button;
