import * as React from 'react';
import { printCoreWarning } from '../../../shared';
import { Component } from '../types';
import { ClientThemeType, Palette, withTheme } from '../../../../theme/index';
import Basic from './Basic';
import { TypographyThemeMapperType, ThemedTypographyProperties } from '../types';

/**
 * Function that mappes client passed theme to our component's theme.
 * Used to limit impacts of future changes to client theme to ourTypography component.
 */
const themeMapper: TypographyThemeMapperType = (theme, { themeOverwrite, element, palette = Palette.brand }) => {
  const clientTheme = theme as ClientThemeType;
  let colors, typings;
  if (clientTheme.palette && palette) {
    colors = clientTheme.palette[palette];
  }
  if (clientTheme.typography) {
    switch (element) {
      case Component.h1: {
        typings = clientTheme.typography.headings[1];
        break;
      }
      case Component.h2: {
        typings = clientTheme.typography.headings[2];
        break;
      }
      case Component.h3: {
        typings = clientTheme.typography.headings[3];
        break;
      }
      case Component.h4: {
        typings = clientTheme.typography.headings[4];
        break;
      }
      case Component.h5: {
        typings = clientTheme.typography.headings[5];
        break;
      }
      case Component.h6: {
        typings = clientTheme.typography.headings[6];
        break;
      }
      default: {
        typings = clientTheme.typography.paragraph;
      }
    }
  }
  if (!colors && !typings) {
    printCoreWarning(
      `Typography.Themed -you did not pass element or palette. Are you sure this was what you were going to do?`
    );
    return undefined;
  }
  let hover = {};
  if (themeOverwrite?.hover) {
    hover = themeOverwrite.hover;
  }

  return {
    default: {
      fontSize: themeOverwrite?.default.fontSize || typings?.fontSize,
      weight: themeOverwrite?.default.weight || typings?.weight,
      alignment: themeOverwrite?.default.alignment || typings?.alignment,
      lineHeight: themeOverwrite?.default.lineHeight || typings?.lineHeight,
      letterSpacing: themeOverwrite?.default.letterSpacing || typings?.letterSpacing,
      color: themeOverwrite?.default.color || colors?.contrast || typings?.color,
    },
    ...hover,
  };
};

const ThemedTypography: React.FunctionComponent<ThemedTypographyProperties> = ({
  palette,
  component,
  theme,
  themeOverwrite,
  ...otherProps
}) => {
  const mappedTheme = themeMapper(theme || {}, {
    palette,
    element: component,
    themeOverwrite,
  });
  return <Basic component={component} clientTheme={mappedTheme} {...otherProps} />;
};

/**
 * Default themed export (Themed component and the mapper function)
 * @visibleName Typography.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedTypographyProperties> & {
  mapper?: TypographyThemeMapperType;
} = withTheme(ThemedTypography);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'Typography.Themed';

export default ThemedExport;
