import * as React from 'react';
import classnames from 'classnames';
import componentStyles from './index.module.scss';
import BaseIcon, { BaseIconType } from './components/Base';
import Flag from './components/Flag';
import { ReactComponent as Svg } from './icons.svg';

import { printCoreWarning } from '../../shared';
import checkValue from '../../../styles/checkValue';

/**
 *
 * A basic component for displaying icons in core-ui-kit.<br/>
 * Created using a [span](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/span) HTML element so you can pass all allowed
 * HTML properties that a `span` can recieve.
 */
const Icon = (props: StyledIconProps) => {
  const {
    className,
    disabled,
    fontSize,
    onClick,
    icon = 'icons.icon.globe',
    color,
    hoverColor,
    testId,
    id,
    ...other
  } = props;
  if (
    icon &&
    icon.includes &&
    icon.includes('flag') &&
    icon !== '$icon--flag' &&
    icon !== 'icons.icon.flag'
  ) {
    printCoreWarning(
      `\n\rYou're passing a flag to the Icon component.\n\rDid you mean to use Icon.Flag instead?`
    );
  }

  return (
    <BaseIcon
      flag={false}
      clickable={!!onClick}
      className={classnames(checkValue(icon, 'icons'), {
        [componentStyles.icon]: true,
        [className as string]: !!className,
      })}
      disabled={disabled}
      fontSize={fontSize}
      id={id}
      onClick={onClick}
      color={color}
      hoverColor={hoverColor}
      {...other}
      testId={testId}
    />
  );
};

type StyledIconProps = Omit<BaseIconType, 'icon' | 'flag' | 'color'> & {
  /**
   * Either exact value to be set, scss variable or js property path.<br/>
   * If not set, the element has color set to inherit.
   */
  color?: string;
  /**
   * Icon you wish to show. Defaults to <i>icon.globe</i> if not valid property is passed. <br />
   * For a full list of allowed components check the Design Token Icon section.
   */
  icon: string;
};

Icon.Flag = Flag;
Icon.Flag.displayName = 'Icon.Flag';

Icon.displayName = 'Icon';
Icon.svg = Svg;

export default Icon;
