import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import { ReactComponent as Svg } from './link.svg';

import { BasicLinkProperties, LinkThemeMapperType, ThemedLinkProperties } from './types';

/** Static properties of the Link.
 * Useful when exporting different components under the same name */
type LinkStatic = {
  Themed: React.FunctionComponent<ThemedLinkProperties> & {
    mapper?: LinkThemeMapperType;
  };
  svg: React.FunctionComponent;
};

/** Basic implementation of the Link component.
 * @component
 * */
const Link: React.FunctionComponent<BasicLinkProperties> & LinkStatic = (
  props: BasicLinkProperties
) => <Basic {...props} />;

Link.svg = Svg;
Link.defaultProps = {
  external: false,
  iconAlignment: 'left',
  disabled: false,
};
Link.Themed = Themed;
Link.displayName = 'Link';

export default Link;
