import * as React from 'react';

import Basic from './Basic';
import { ThemedToggleProps, ToggleThemeMapper } from '../types';
import { ToggleThemeOverwriteType } from '../types';
import { printCoreWarning } from '../../../shared';
import { ClientThemeType, Palette, ThemeClient, withTheme } from '../../../../theme';

const themeMapper = (
  theme: ThemeClient | undefined,
  { palette = Palette.brand, themeOverwrite }: { palette?: Palette; themeOverwrite?: ToggleThemeOverwriteType }
): ToggleThemeOverwriteType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Toggle.Themed component must have a palette. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    return {
      default: {
        color: themeOverwrite?.default?.color || selectedPalette.main,
      },
    };
  }

  return undefined;
};

/**
 * Themed toggle implementation.
 * Special parameters include theme, palete and the themeOverwrite object.
 * @param {ThemedToggleProps} properties
 */
const ThemedToggle = ({
  theme,
  palette = Palette.brand,
  themeOverwrite,
  ...otherProps
}: ThemedToggleProps) => {
  const mappedTheme = themeMapper(theme || {}, { palette, themeOverwrite });
  return <Basic theme={mappedTheme} {...otherProps} />;
};

/**
 * @component
 * @visibleName Toggle.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedToggleProps> & {
  mapper?: ToggleThemeMapper;
} = withTheme(ThemedToggle);

ThemedExport.mapper = themeMapper;

ThemedExport.displayName = 'Toggle.Themed';
export default ThemedExport;
