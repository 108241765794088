import * as React from 'react';
import Basic from './components/Basic';
import Tab from './components/Tab';
import TabList from './components/TabList';
import Panel from './components/Panel';
import PanelContent from './components/PanelContent';
import { ReactComponent as Svg } from './tabs.svg';

import { BasicTabsProperties } from './types';

/** Static properties of the Tabs.
 * Useful when exporting different components under the same name */
type TabsStatic = {
  Tab: typeof Tab;
  TabList: typeof TabList;
  Panel: typeof Panel;
  PanelContent: typeof PanelContent;
  svg: React.FunctionComponent;
};

/** Basic implementation of the Tabs component [ALPHA].
 * @component
 * @since 0.3.0
 * @_example ./migration.md
 * */
const Tabs: React.FunctionComponent<BasicTabsProperties> & TabsStatic = (
  props: BasicTabsProperties
) => <Basic {...props} />;

Tabs.Tab = Tab;
Tabs.TabList = TabList;
Tabs.Panel = Panel;
Tabs.PanelContent = PanelContent;

Tabs.displayName = 'Tabs';
Tabs.svg = Svg;

export default Tabs;
