import { Palette } from '../../../theme';
import { ThemeContextType } from '../../../theme/context';
import { BasicAriaAttributes } from '../../../types';

/**
 * This enumerator shows all variants that can happen when applying
 * a theme. Based on this value a color in the theme definition
 * will be mapped to a specific css property. <br/>
 * Check documentation for more examples.
 */
export enum ButtonThemeVariant {
  /**
   * Button that applies border and background color.
   */
  filled = 'filled',
  /**
   * Button that applies border and text color.
   */
  outlined = 'outlined',
  /**
   * A button that is switched between an active and inactive state on press.
   */
  toggable = 'toggable',
}

/**
 * Type that shows what style
 * changes exist among button css states.
 */
export type ButtonStyleVariation = {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  outlineColor?: string;
};

/**
 * Out button component's theme.
 * As buttons are very interactive elements, their styles must change
 * for a number of css states.
 * This theme shows how do those styles look when in each specific state.
 */
export type ButtonThemeType = {
  default: ButtonStyleVariation;
  hover: ButtonStyleVariation;
  active: ButtonStyleVariation;
  focus: ButtonStyleVariation;
  disabled: ButtonStyleVariation;
};

/**
 * If an user wishes to overwrite the button theme, he can pick and
 * choose which properties to overwrite.
 */
export type ButtonThemeOverwriteType = {
  default?: ButtonStyleVariation;
  hover?: ButtonStyleVariation;
  active?: ButtonStyleVariation;
  focus?: ButtonStyleVariation;
  disabled?: ButtonStyleVariation;
};

export type BasicButtonContentProperties = {
  /**
   * Button text
   */
  label?: string;
  /**
   * pass the name of the icon or Icon component to display the icon
   */
  icon?: string | React.ReactNode;
};

/**
 * Styles for the BasicButton component.
 * The one exported as Button.
 */
export type BasicButtonProperties = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'key' | 'type' | 'ref' | 'style' | 'id' | 'disabled' | 'hidden'
> &
  BasicAriaAttributes &
  BasicButtonContentProperties & {
    /**
     * If set to true, this button will not be styled as a basic button but will be styled as a link would.
     * This is to prevent using non-semantic usages of <button> and <a> elements.
     */
    styleAsLink?: boolean;
    /**
     * Indicates the availability and type of interactive popup element (dialog/menu) that can be triggered by the element.
     */
    ariaHasPopup?: boolean | string;
    /**
     * Indicates whether the interactive popup element is expanded or not
     */
    ariaExpanded?: boolean;
    /**
     * If set to true the rendered button is disabled.
     * The onClick function is ignored if this property is set to true.
     */
    disabled?: boolean;
    /**
     * Id of the wrapper element.
     */
    id?: string;

    /**
     * Passed as value to "data-test-id" attribute.
     */
    testId?: string;
    /**
     * If set to true, the button loses all of it's styles.<br/>
     * Intended for use with other components that need to have clickable capabilities but don't need to
     * implement all accessibility considerations themselves.
     */
    sneaky?: boolean;
    /**
     * Defaults to small. Sets one of the pre-defined radius values for the button.
     * As it applies a class that applies styling, it should be easy to overwrite if need be.
     */
    round?: 'none' | 'small' | 'medium' | 'large';
    /**
     * Defaults to medium. Sets one of the pre-defined padding values for the button.
     * As it applies a class that applies styling, it should be easy to overwrite if need be.
     */
    padding?: 'none' | 'small' | 'medium' | 'large';
    /**
     * Font size that will be applied to the element, values can be chosen from the Design Tokens
     */
    fontSize?: string;

    /**
     * Classname applied to the button element.
     */
    className?: string;
    /**
    Style applied directly to the button element.
  */
    style?: React.CSSProperties;
    /**  A prop that should not be visible in the documentation.
     *
     *  @ignore
     */
    theme?: ButtonThemeType;
    /**  A prop that should not be visible in the documentation.
     * Set to true defaultly, and set to false for themed button.
     * Applies a .button--basic class to the button element if true.
     *
     *  @ignore
     */
    basic?: boolean;

    /**
     * Global boolean attribute indicating that the element is not
     * yet or is no longer, relevant
     * If something is hidden, it will not be shown to all presentations
     * (so screen readers too!)
     */
    hidden?: boolean;
  };

/**
 * Properties that apply for the Button.Themed component.
 */
export type ThemedButtonProperties = ThemeContextType &
  BasicButtonProperties & {
    /** Variant of styling. Tells the component how to map client specified colors
     * to css properties defined in the ButtonStyleVariation type.
     */
    variant?: ButtonThemeVariant | string;
    /**
     * Custom palette that defines mapping of colors.
     */
    palette?: Palette;
    /**
     * Overwrite some calculated values for the theme.
     * Has the same object type as the theme object.
     */
    themeOverwrite?: ButtonThemeOverwriteType;
  };

export type ButtonGroupType = {
  children:
    | React.ComponentType<BasicButtonProperties>
    | Array<React.ComponentType<BasicButtonProperties>>;
  className?: string;
  /**
   * If set to true, the buttons will get some margins between them.
   * Applies additional styling.
   */
  separated?: boolean;
  style?: React.CSSProperties;

  /** Style applied to all children */
  childrenStyle?: React.CSSProperties;

  /** ClassName applied to all children */
  childrenClassName?: string;

  vertical: boolean;
  role?: string;

  /**
   * Maps to the `flex-basis` CSS property.
   * Useful when a Flex component is both a parent of Flex.Items and a child of another Flex.
   */
  flexBasis?: string;
  /**
   * If set to true, the component will allow children to span
   * through multiple lines.
   */
  wrap?: boolean;
  /**
   * Maps to the `flex-direction` CSS property.
   */
  direction: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  /**
   * Defines the alignment along the main axis (the main axis is the one specified
   * by the direction property).
   * Defaults to 'flex-start'.
   *
   */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
  /**
   *
   * Defines the alignment along the cross axis (the main axis is the one specified
   * by the direction property. Cross axis is vertical to the main axis).
   * Defaults to 'stretch'
   */
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
};
