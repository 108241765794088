import * as React from 'react';
import classnames from 'classnames';
import { ButtonGroupType } from '../types';
import Flex from '../../../Layout/Flex';
import styles from '../index.module.scss';

/**
 * Default implementation of the button group. This component knows how to display
 * several buttons. Knows how to handle Button components as direct children, if additional elements present,
 * you might introduce a styling bug.
 * Built on top of the Flex component.
 * @param {ButtonGroupProps} props
 * @visibleName Button.Group
 */
const ButtonGroup: React.FunctionComponent<ButtonGroupType> = ({
  children,
  className,
  childrenClassName,
  style,
  childrenStyle,
  separated,
  vertical,
  wrap,
  direction,
  alignItems = 'center',
  justifyContent,
  ...otherProps
}) => {
  const styledChildren = React.useMemo(
    () =>
      React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child as React.ReactElement<any>, {
            ...(child as React.ReactElement).props,
            style: {
              ...(child as React.ReactElement).props.style,
              ...childrenStyle,
            },
            className: classnames((child as React.ReactElement).props.className, childrenClassName),
          })
      ),
    [children, childrenClassName, childrenStyle]
  );

  return (
    <Flex
      justifyContent={justifyContent}
      wrap={wrap}
      direction={direction || (vertical ? 'column' : undefined)}
      alignItems={alignItems}
      style={style}
      className={classnames(styles.button__group, className, {
        [styles['button__group--connected']]: !separated,
        [styles['button__group--vertical']]: vertical,
      })}
      {...otherProps}
    >
      {styledChildren}
    </Flex>
  );
};

ButtonGroup.defaultProps = {
  separated: false,
  vertical: false,
};
ButtonGroup.displayName = 'Button.Group';
export default ButtonGroup;
