import styled from 'styled-components';
import { FlexItemProperties } from '../types';

/**
 * Simple component that allows you to easily add flex item properties.
 * Will render a div HTML element.
 *
 *
 * Does not need to be used as direct child of Flex, but we'd recommend it.
 * @visibleName Flex.Item
 * @since 0.3.0
 */

const FlexItem = styled.div<FlexItemProperties>`
  align-self: ${(props: FlexItemProperties) => props.alignSelf};
  flex-basis: ${(props: FlexItemProperties) => props.flexBasis};
  flex-grow: ${(props: FlexItemProperties) => props.flexGrow};
  order: ${(props: FlexItemProperties) => props.order};
`;

FlexItem.displayName = 'Flex.Item';
FlexItem.defaultProps = {
  flexGrow: 0,
  flexBasis: 'auto',
  alignSelf: 'auto',
  order: 0,
};
export default FlexItem;
