import * as React from 'react';
import { PanelContentProps } from '../types';

const PanelContent: React.FunctionComponent<PanelContentProps> & {
  _internalType: string;
} = ({ selected, mountChildrenStrategy, children }) => {
  const [previouslyActive, setPreviouslyActive] = React.useState<string[]>([]);

  const panelsToRender = React.useMemo(() => {
    const renderChildren: React.ReactElement[] = [];
    const newPreviousActive: string[] = [];

    React.Children.forEach(children, (panel) => {
      const { value } = (panel as any)?.props;
      const isActive = value === selected;
      const wasActive = previouslyActive.includes(value);

      const shouldMount =
        isActive ||
        mountChildrenStrategy === 'all' ||
        (wasActive && mountChildrenStrategy === 'keepActivated');

      if (shouldMount) {
        newPreviousActive.push(value);
        renderChildren.push(
          React.cloneElement(panel as React.ReactElement, {
            key: value,
            ...(panel as any)?.props,
            selected: isActive,
          })
        );
      }
    });

    if (newPreviousActive.join(',') !== previouslyActive.join(',')) {
      setPreviouslyActive(newPreviousActive);
    }
    return renderChildren;
  }, [children, mountChildrenStrategy, selected, previouslyActive]);

  return <>{panelsToRender}</>;
};

PanelContent.defaultProps = {
  mountChildrenStrategy: 'activeOnly',
};
PanelContent.displayName = 'Tabs.PanelContent';
PanelContent._internalType = 'Tabs.PanelContent';
export default PanelContent;
