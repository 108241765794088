import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import Icon from '../../../General/Icon';
import { InputProperties } from '../types';

/**
 *Basic implementation of the input component.
 *
 * @since 0.7.0
 */

const BasicInput: React.FunctionComponent<InputProperties> = ({
  className,
  style,
  id,
  testId,
  forwardedRef,
  hidden,
  size,
  round,
  message,
  icon,
  placeholder,
  error,
  warning,
  includeMessageHeight,
  classNames,
  iconPosition,
  ...other
}) => {
  return (
    <div
      className={classnames(styles.input__wrapper, className, {
        [styles.warning]: warning,
        [styles.error]: error,
        [styles['input__wrapper--withHeight']]: includeMessageHeight,
        [styles[`${size || ''}--wrapper`]]: !!size,
        [styles[`input--rounded-${round || 'none'}`]]: !!round,
      })}
      style={style}
    >
      <input
        ref={forwardedRef}
        placeholder={placeholder}
        hidden={hidden}
        id={id}
        data-test-id={testId}
        className={classnames(styles.input, classNames?.input, {
          [styles[size || '']]: !!size,
          [styles.warning]: warning,
          [styles.error]: error,
          [styles['input--withIcon']]: !!icon,
          [styles['input--withIcon--left']]: !!icon && iconPosition === 'left',
          [`${className}__input`]: !!className,
        })}
        {...other}
      />
      {icon && (
        <Icon
          icon={icon}
          className={classnames(styles.input__icon, classNames?.icon, {
            [`${className}__icon`]: !!className,
            [styles.input__icon__left]: iconPosition === 'left',
          })}
        />
      )}
      {message && (
        <span
          title={message}
          className={classnames(styles.input__message, classNames?.message, {
            [`${className}__message`]: !!className,
          })}
        >
          {message}
        </span>
      )}
    </div>
  );
};

BasicInput.displayName = 'Input';
export default BasicInput;
