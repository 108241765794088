import * as React from 'react';
import Circle from './components/Circle';
import Bar from './components/Bar';
import Progress from './components/Progress';
import Skeleton from './components/Skeleton';
import {
  CircleLoaderProperties,
  BarLoaderProperties,
  ProgressLoaderProperties,
  ThemedLoaderProperties,
  SkeletonLoaderProperties,
  LoaderThemeMapperType,
} from './types';
import { ReactComponent as Svg } from './loader.svg';

/** Static properties of the Loader.
 * Useful when exporting different components under the same name */
type LoaderStatic = {
  Progress: React.FunctionComponent<ProgressLoaderProperties>;
  Bar: React.FunctionComponent<BarLoaderProperties>;
  Themed: React.FunctionComponent<ThemedLoaderProperties & { Component: any }> & {
    mapper?: LoaderThemeMapperType;
  };
  svg: React.FunctionComponent;
  Skeleton: React.FunctionComponent<SkeletonLoaderProperties>;
};

/**
 * The Loader component provides some out-of-box loading styles and can be used as basis for elements indicating progress
 * or an active process going on. The default export shows a circle loader while other types of loaders are available as
 * static exports.
 *
 * @component
 * @since 0.3.0
 * */
const Loader: React.FunctionComponent<CircleLoaderProperties> & LoaderStatic = (
  props: CircleLoaderProperties
) => <Circle {...props} />;

Loader.Progress = Progress;
Loader.Bar = Bar;

Loader.displayName = 'Loader';
Loader.Themed = Circle.Themed;
Loader.Skeleton = Skeleton;

Loader.svg = Svg;

export default Loader;
