import React from 'react';
import classnames from 'classnames';
import Card from '../../../DataDisplay/Card';
import { ModalElementProperties } from '../types';
import styles from '../index.module.scss';

/**
 *
 * @component
 * @visibleName Modal.Content
 */
const Content = ({ className, children, ...other }: ModalElementProperties) => (
  <Card.Content
    className={classnames(styles.modal__content, {
      [`${className}__content`]: !!className,
    })}
    {...other}
  >
    {children}
  </Card.Content>
);

Content.displayName = 'Modal.Content';

export default Content;
