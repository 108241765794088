import * as React from 'react';
import Basic from './components/Basic';
import { ReactComponent as Svg } from './label.svg';
import { BasicLabelProperties } from './types';

type LabelStatic = {
  svg: React.FunctionComponent;
};

/**
 * A basic label implementation that uses the native HTML label element. Use this component whenever you
 * wish to display a caption for an item in a user interface.
 * This is especially important when thinking about screenreaders because they will read out the label
 * whenever the user focused on an input element or a widget with the `aria-labelledby` set to the id of the
 * label in question.
 *
 * @component
 * @since 0.7.0
 * @visibleName Label
 * */
const Label: React.FunctionComponent<BasicLabelProperties> & LabelStatic = (
  props: BasicLabelProperties
) => <Basic {...props} />;

Label.displayName = 'Label';
Label.defaultProps = {
  block: false,
  hidden: false,
};
Label.svg = Svg;

export default Label;
