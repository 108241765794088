import * as React from 'react';
import { BasicTypographyProperties } from '../types';
import Basic from './Basic';

/**
 * Mapper for original label's props and new Typography related properties
 * that achieve the same effect.
 * @param isBold Ui-kit label property isBold
 * @param isBig  Ui-kit label property isBig
 */
const basicProps = (isBold: boolean, isBig: boolean) => ({
  color: 'colors.grey.800',
  fontWeight: isBold ? 'typography.font.weight.bold' : undefined,
  fontSize: isBig ? 'typography.font.size.20' : '13px',
});

/**
 * @visibleName Typography.Label
 * @displayName Typography.Label
 * @component
 * @deprecated This component is added for easier migration guide and will soon be deprecated. To handle labels in forms please use our Label component.
 */
export default ({
  isBold,
  isBig,
  ...props
}: { isBold: boolean; isBig: boolean } & BasicTypographyProperties) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      '%c CORE-UI-KIT WARNING',
      'color: red',
      '\n\rYou are using the deprecated Typography.Label component.\n\rPlease switch to using Typography. Check the migration guide for more info.'
    );
  }
  return <Basic {...props} {...basicProps(isBold, isBig)} />;
};
