import * as React from 'react';
import styled from 'styled-components';
import { CircleLoaderProperties, ThemedLoaderProperties, LoaderThemeMapperType } from '../types';
import checkValue from '../../../../styles/checkValue';
import classnames from 'classnames';
import Themed from './Themed';
import { StyledFlex } from './Styled';
import styles from '../index.module.scss';

const Circle = styled.div<CircleLoaderProperties>`
  border: ${({ thickness }: CircleLoaderProperties) => thickness} solid
    ${({ color }: CircleLoaderProperties) => checkValue(color, 'colors')};
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 100%;
  margin: 2px;
  ${(props: CircleLoaderProperties) => `
    width: ${props.size};
    height: ${props.size};
  `}
`;

/**
 * Basic implementation of the Loader component.
 *
 * @since 0.3.0
 */

const CircleLoader: React.FunctionComponent<CircleLoaderProperties> & {
  Themed: React.FunctionComponent<ThemedLoaderProperties & { Component: any }> & {
    mapper?: LoaderThemeMapperType;
  };
} = ({
  alignItems,
  thickness,
  centered,
  children,
  className,
  color,
  direction,
  flexBasis,
  justifyContent,
  loaderClassName,
  size,
  style,
  theme,
  wrap,
  id,
  testId,
  invert,
}) => {
  return (
    <StyledFlex
      alignItems={alignItems}
      thickness={thickness}
      className={classnames(className, {
        [styles.centered]: centered,
      })}
      direction={direction}
      flexBasis={flexBasis}
      justifyContent={justifyContent}
      size={size}
      style={style}
      wrap={wrap}
      id={id}
      data-test-id={testId ? testId : undefined}
    >
      {invert && children}
      <Circle
        thickness={thickness}
        className={classnames(styles.rotate, loaderClassName)}
        color={color || theme?.color}
        size={size || '40px'}
      />
      {!invert && children}
    </StyledFlex>
  );
};

CircleLoader.displayName = 'Loader';

CircleLoader.Themed = (props) => <Themed {...props} Component={CircleLoader} />;
CircleLoader.Themed.displayName = 'Loader.Themed';
CircleLoader.defaultProps = {
  thickness: '4px',
  size: '40px',
};
export default CircleLoader;
