import base from '../index.scss';

export type BoxShadowType = {
  [key: string]: string;
};

const boxShadow: BoxShadowType = {
  'box-shadow.1': base['box-shadow_1'],
  'box-shadow.2': base['box-shadow_2'],
  'box-shadow.3': base['box-shadow_3'],
  'box-shadow.4': base['box-shadow_4'],
  'box-shadow.5': base['box-shadow_5'],
  'box-shadow.6': base['box-shadow_6'],
  'box-shadow.7': base['box-shadow_7'],
  'box-shadow.8': base['box-shadow_8'],
  'box-shadow.soft': base['box-shadow_soft'],
  'box-shadow.medium': base['box-shadow_medium'],
  'box-shadow.hard': base['box-shadow_hard'],
  'box-shadow.button': base['box-shadow_button'],
  'box-shadow.button-hover': base['box-shadow_button-hover'],
  'box-shadow.toast': base['box-shadow_toast'],
};

// Is not autogenerated, do not remove
const old = {
  'box-shadow.1.old': 'soft',
  'box-shadow.2.old': 'medium',
  'box-shadow.3.old': 'button',
  'box-shadow.4.old': 'filter',
  'box-shadow.5.old': 'hard',
  'box-shadow.6.old': 'toast',
  'box-shadow.7.old': '',
  'box-shadow.8.old': '',
  'box-shadow.soft.old': 'soft',
  'box-shadow.medium.old': 'medium',
  'box-shadow.hard.old': 'hard',
  'box-shadow.button.old': 'button',
  'box-shadow.button-hover.old': '',
  'box-shadow.toast.old': 'softBottom',
};

export default { ...boxShadow, ...old };
