/**
 * # React Adapter (Hooks)
 *
 *
 */

import type { AppConfig } from '@searchmetrics/sm-discover';

import type { SmDiscoverModuleVersion } from '~/utilities/module';
import { getSmDiscoverRemoteModule, initializeRemoteSmDiscoverApp } from '~/utilities/module';

/**
 *
 *
 * NOTE: while the function is not really a "react hook" depending on the React component
 *       lifecycles it allows to be referenced as such to handle preloading pior to rendering
 *
 * @param version - [description]
 * @param config  - application configuration
 */
export function useReactAdapter(version?: SmDiscoverModuleVersion, config?: AppConfig) {
  const remoteModule = getSmDiscoverRemoteModule(version);

  const registerHostContainerElement = (node: HTMLDivElement) => {
    if (!node) {
      // unmount
      return;
    }

    initializeRemoteSmDiscoverApp(remoteModule, node, config).catch((error) => {
      throw error;
    });
  };

  return {
    registerHostContainerElement,
  };
}
