import { ThemeContextType } from '../../../theme/context';
import { Palette, ThemeClient, TypographyDefinition } from '../../../theme';

export enum Component {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  div = 'div',
  span = 'span',
  p = 'p',
}

export type BasicTypographyProperties = {
  /** Component that will be rendered as the element. Default's to div */
  component?: Component;
  /** 
    Element className
  */
  className?: string;
  /** 
    Styles passed to element
  */
  style?: React.CSSProperties;
  /**
   * Either exact value to be set, scss variable or js property path.\n
   * Is overriden by theme's global default color.
   */
  color?: string;
  /**
   * Either exact value to be set, scss variable or js property path.
   * Is overriden by theme's global default font-size.
   * Does not have a default font-size because we want it do default
   * to element's (browser based css).
   */
  fontSize?: string;
  /**
   * Either exact value to be set, scss variable or js property path.
   * Is overriden by theme's global default font-weight.
   */
  fontWeight?: string;
  /**
   *  General theme object
   *  @ignore
   */
  clientTheme?: TypographyThemeType;
  /**
   * Id of the wrapper element.
   */
  id?: string;
  /**
   * Passed as value to "data-test-id" attribute.
   */
  testId?: string;
};

/** A type showing what style variations exist for the Typography component.
 *  Each property represents a CSS style and is of type TypographyDefinition.
 */
export type TypographyThemeType = {
  default: TypographyDefinition;
  hover?: TypographyDefinition;
};

export type TypographyThemeMapperType = (
  theme: ThemeClient,
  params: {
    /**
     * Defines which element should be taken
     */
    element?: Component;
    /**
     * Defines which palette from the theme
     * will be used to define the mapping of colors.
     */
    palette?: Palette;
    themeOverwrite?: TypographyThemeType;
  }
) => TypographyThemeType | undefined;

/**
 * Properties for the themed version of the ${componentName.toLowerCase()}
 * component. <br/>
 * In addition to properties from BasicTypographyProperties
 * and ThemeContextType, should include some specifics.
 */
export type ThemedTypographyProperties = ThemeContextType &
  BasicTypographyProperties & {
    /**
     * Defines which palette from the theme
     * will be used to define the mapping of colors.
     */
    palette?: Palette;
    /**
     * Passed values overwrite the values mapped from the client's theme.
     */
    themeOverwrite?: TypographyThemeType;
  };
