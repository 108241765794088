import React from 'react';

/**
 * Checks the position of the passed element (target) in regards to
 * the parent's scroll. If the element is not visible,
 * will alter parent's scrollTop value to scroll element into view.
 */
const scrollHoveredIntoView = (target) => {
  if (target && target.parentElement) {
    const parentStyle = getComputedStyle(target.parentElement);
    const listboxVerticalPadding =
      parseFloat(parentStyle.paddingTop) + parseFloat(parentStyle.paddingBottom);
    const contentHeight = target.parentElement.offsetHeight - 2 * listboxVerticalPadding;
    const contentTop = target.parentElement.scrollTop;
    const contentEnd = contentTop + contentHeight;

    // If element is positioned after the currently visible elements, scroll down
    if (target.offsetTop > contentEnd) {
      target.parentElement.scrollTop =
        target.parentElement.scrollTop + (target.offsetTop - contentEnd) + listboxVerticalPadding;
    }
    // If element is positioned before the currently visible elements, scroll up
    if (target.offsetTop < contentTop) {
      target.parentElement.scrollTop = target.offsetTop;
    }
  }
};

const handleEnter = ({
  hoveredIndex,
  isExpandedOnSelect,
  options,
  onChange,
  setExpanded,
  expanded,
}) => {
  const hoveredElement = options[hoveredIndex];
  if (!!hoveredElement) {
    if (!isExpandedOnSelect) {
      setExpanded(!expanded);
    }
    if (onChange && !hoveredElement.disabled) {
      onChange(hoveredElement.id);
    }
  } else {
    // Expand even if expand on select
    setExpanded(!expanded);
  }
};

const handleArrows = ({
  hoveredIndex,
  options,
  setHovered,
  setExpanded,
  expanded,
  isArrowDown,
}) => {
  /** Not showing options. Open options */
  if (!expanded) {
    setExpanded(true);
  } else {
    let firstCandidate;
    let getNextCandidate;
    if (isArrowDown) {
      firstCandidate = hoveredIndex === -1 ? 0 : (hoveredIndex + 1) % options.length;
      getNextCandidate = (i) => (firstCandidate + i) % options.length;
    } else {
      firstCandidate = hoveredIndex - 1 < 0 ? options.length - 1 : hoveredIndex - 1;
      getNextCandidate = (_i, candidate) =>
        candidate - 1 < 0 ? options.length - 1 : candidate - 1;
    }
    /**
     * Next element that is supposed to be hovered is
     * disabled. You need to find another.
     */
    if (options[firstCandidate].disabled) {
      let found = false;
      let candidate = firstCandidate;
      /**
       * Go once around all elements to check
       * if there is a single element that's hoverable
       */
      for (let i = 0; i <= options.length; i += 1) {
        candidate = getNextCandidate(i, candidate);
        if (options[candidate] && !options[candidate].disabled) {
          found = true;
          break;
        }
      }
      if (found) setHovered(candidate);
    } else {
      setHovered(firstCandidate);
    }
  }
};

/**
 * Will handle key press in various elements of the Select component (Button and each individual Option).
 * Handles hovering and selection of options using the keyboard (keys Enter, ArrowUp and ArrowDown).
 * Will bubble other keyboard events.
 */
const handleKeyPress = ({
  hoveredIndex, // Index of the currently hovered element
  isExpandedOnSelect, // Indicates whether the expand property should be toggled on selection
  options, // Array of available options (includes id, index and whether the option is disabled)
  onChange, // handler on selection - Enter press
  setExpanded, // function that changes expanded value
  expanded, // indicates whether the listbox is visible or not
  setHovered, // function that changes the hovered index value
}) => (event: React.KeyboardEvent<HTMLElement>) => {
  const { key } = event;

  /**
   * Recieves the new id (element that needs to be scrolled)
   * Will scroll listbox up/down based on the position of element
   */
  const hoverAndScroll = (newHover) => {
    setHovered(newHover);
    if (options) {
      scrollHoveredIntoView(document.getElementById(options[newHover]?.id));
    }
  };

  // Prevent only handled keys, propagate others
  if (['Enter', 'ArrowDown', 'ArrowUp'].includes(key)) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (key === 'Enter') {
    handleEnter({
      hoveredIndex,
      isExpandedOnSelect,
      options,
      onChange,
      setExpanded,
      expanded,
    });
  }
  if (key === 'ArrowDown' || key === 'ArrowUp') {
    handleArrows({
      hoveredIndex,
      options,
      setHovered: hoverAndScroll,
      setExpanded,
      expanded,
      isArrowDown: key === 'ArrowDown',
    });
  }
};

/**
 * Will track outside clicks when element expanded.
 * If the user clicks outside, the function will close the component listbox.
 * In addition, to support the same functioning of the select element, the
 * button in question will be focused after close.
 * @param id - identification of the component, used for selecting wrapper element.
 * @param expanded - boolean indicating if the component is currently expanded ("open")
 * @param setExpanded - function altering the expanded value
 */
function useOutsideClickHandler(id, expanded, setExpanded) {
  const handleClickOutside = React.useCallback(
    ({ target }: MouseEvent) => {
      const wrapper = document.getElementById(`${id}_wrapper`);
      if (wrapper && !(wrapper.contains(target as Node) || wrapper === target)) {
        setExpanded(false);
        document.getElementById(id)?.focus();
      }
    },
    [id, setExpanded]
  );

  React.useEffect(() => {
    if (expanded) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => window.removeEventListener('click', handleClickOutside);
  }, [expanded, handleClickOutside]);

  return handleClickOutside;
}

export { handleKeyPress, useOutsideClickHandler };
