import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import { BasicToggleProps, ThemedToggleProps, ToggleThemeMapper } from './types';
import { ReactComponent as Svg } from './toggle.svg';

type ToggleStaticProps = {
  Themed: React.FunctionComponent<ThemedToggleProps> & {
    mapper?: ToggleThemeMapper;
  };
  svg: React.FunctionComponent;
};

/**
 * Basic component for representing binary state (`yes/no`, `on/off`, `true/false`).
 * * It is controlled component, which means that state has to be held outside
 * and passed as property.
 * * Implemented as HTML button and can have all button's properties.
 * Contains hidden HTML checkbox input element.
 */
const Toggle: React.FC<BasicToggleProps> & ToggleStaticProps = (props) => <Basic {...props} />;

Toggle.Themed = Themed;
Toggle.displayName = 'Toggle';
Toggle.svg = Svg;

export default Toggle;
