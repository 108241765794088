import styled from 'styled-components';
import checkValue from '../../../../styles/checkValue';
import { BasicTooltipProperties, TooltipStyleVariation } from '../types';
import scssStyles from '../index.module.scss';

const getStateStyles = (state: TooltipStyleVariation) => {
  if (!state) return '';
  const { color, backgroundColor, borderColor, fontSize } = state;
  return `
    ${color ? `color: ${checkValue(color, 'colors')};` : ''}
    ${backgroundColor ? `background-color: ${checkValue(backgroundColor, 'colors')};` : ''}
    ${borderColor ? `border-color: ${checkValue(borderColor, 'colors')};` : ''}
    ${fontSize ? `font-size: ${checkValue(`${fontSize}px`)};` : ''}
  `;
};

export const StyledWrapper = styled.div`
  ${({ disabled }: BasicTooltipProperties) => (disabled ? 'cursor: not-allowed;' : '')}
  ${({ theme }: BasicTooltipProperties) => {
    let styles = '';
    // If styling for trigger exists, add
    // theme specific styling for each CSS state
    if (theme?.trigger) {
      if (theme?.trigger?.default) {
        styles += `
        .${scssStyles['tooltip__trigger']} {
          ${getStateStyles(theme?.trigger?.default)}
        }
      `;
      }
      if (theme?.trigger?.active) {
        styles += `
      &.${scssStyles['tooltip--active']} {
        .${scssStyles['tooltip__trigger']} {
          ${getStateStyles(theme?.trigger?.active)}
        }
      }
      `;
      }
      if (theme?.trigger?.disabled) {
        styles += `
        .${scssStyles['tooltip__trigger--disabled']} {          
          ${getStateStyles(theme?.trigger?.disabled)}
        }
      
      `;
      }
      if (theme?.trigger?.hover) {
        styles += `
        .${scssStyles['tooltip__trigger']}:hover {   
          ${getStateStyles(theme?.trigger?.hover)}
        }
      `;
      }
    }
    return styles;
  }}
`;

export const StyledTooltip = styled.span`
  transition: opacity visibility;
  transition-duration: ${(props) => props.animation}ms;
  transition-delay: ${(props) => props.delay}ms;
  ${({ theme }: BasicTooltipProperties) => {
    let styles = '';
    // Wrapper styles
    if (theme?.wrapper) {
      styles += `
      ${
        theme?.wrapper.borderColor
          ? `border: 1px solid ${checkValue(theme?.wrapper.borderColor, 'colors')};`
          : ''
      }
      ${
        theme?.wrapper.backgroundColor
          ? `background-color: ${checkValue(theme?.wrapper.backgroundColor, 'colors')};`
          : ''
      }
      ${theme?.wrapper.color ? `color: ${checkValue(theme?.wrapper.color, 'colors')};` : ''}
      ${theme?.wrapper.fontSize ? `font-size: ${checkValue(`${theme?.wrapper.fontSize}px`)};` : ''}

      &.${scssStyles['left']} {
        &:after {
          ${
            theme?.wrapper.borderColor
              ? `border-color: transparent transparent transparent ${checkValue(
                  theme?.wrapper.borderColor,
                  'colors'
                )};`
              : ''
          }
        }
      }

      &.${scssStyles['top']}  {
        &:after {
          ${
            theme?.wrapper.borderColor
              ? `border-color: ${checkValue(
                  theme?.wrapper.borderColor,
                  'colors'
                )} transparent transparent transparent;`
              : ''
          }
        }
      }

      &.${scssStyles['right']}  {
        &:after {
          ${
            theme?.wrapper.borderColor
              ? `border-color: transparent ${checkValue(
                  theme?.wrapper.borderColor,
                  'colors'
                )} transparent transparent;`
              : ''
          }
        }
      }

      &.${scssStyles['bottom']} {
        &:after {
          ${
            theme?.wrapper.borderColor
              ? `border-color: transparent transparent ${checkValue(
                  theme?.wrapper.borderColor,
                  'colors'
                )} transparent;`
              : ''
          }
        }
      }
      `;
    }

    // Footer, Header and Content styles
    if (theme?.header) {
      styles += `
      .${scssStyles['tooltip__inner__header']} {
        ${
          theme?.header.backgroundColor
            ? `background-color: ${checkValue(theme?.header.backgroundColor, 'colors')};`
            : ''
        }
        ${
          theme?.header.borderColor
            ? `border-bottom: 1px solid ${checkValue(theme?.header.borderColor, 'colors')};`
            : ''
        }
        ${theme?.header.color ? `color: ${checkValue(theme?.header.color, 'colors')};` : ''}
        ${theme?.header.fontSize ? `font-size: ${checkValue(`${theme?.header.fontSize}px`)};` : ''}
      }
      `;
    }
    if (theme?.content) {
      styles += `
      .${scssStyles['tooltip__inner__content']}:not(.${
        scssStyles['tooltip__inner__content--light']
      }) {
        ${
          theme?.content.backgroundColor
            ? `background-color: ${checkValue(theme?.content.backgroundColor, 'colors')};`
            : ''
        }
        ${theme?.content.color ? `color: ${checkValue(theme?.content.color, 'colors')};` : ''}
        ${
          theme?.content.fontSize ? `font-size: ${checkValue(`${theme?.content.fontSize}px`)};` : ''
        }
      }
    `;
    }
    if (theme?.footer) {
      styles += `
      .${scssStyles['tooltip__inner__footer']} {
        ${
          theme?.footer.borderColor
            ? `border-top: 1px solid ${checkValue(theme?.footer.borderColor, 'colors')};`
            : ''
        }
        ${
          theme?.footer.backgroundColor
            ? `background-color: ${checkValue(theme?.footer.backgroundColor, 'colors')};`
            : ''
        }
        ${theme?.footer.color ? `color: ${checkValue(theme?.footer.color, 'colors')};` : ''}
        ${theme?.footer.fontSize ? `font-size: ${checkValue(`${theme?.footer.fontSize}px`)};` : ''}
      }
      `;
    }
    return styles;
  }}
`;
