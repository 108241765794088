import { useState, useCallback } from 'react';

type MenuVisibilityHookParams = {
  initialVisibility: boolean;
  notifyParent?: Function;
  includeHover?: boolean;
};
type MenuVisibilityHookReturn = [
  boolean,
  {
    onEnter: () => void;
    onLeave: () => void;
    onChildEnter: () => void;
    onChildLeave: () => void;
    onChildLeaveParentStay: () => void;
    setShow: (e: boolean) => void;
    childVisibilityUpdate: (newValue: boolean) => void;
    onClickHandler: () => void;
  }
];

/**
 * Use for handling submenu visibility.
 */
function useVisibilityHook({
  initialVisibility,
  notifyParent,
  includeHover,
}: MenuVisibilityHookParams): MenuVisibilityHookReturn {
  const [isTriggeringVisibility, setIsTriggeringVisibility] = useState(initialVisibility);
  const [isChildVisible, setIsChildVisible] = useState(initialVisibility);

  /**
   * Whenever the element itself is the reason for
   * displaying submenu.
   * This happens when the button is hovered or when Enter is pressed
   * after it being focused.
   */
  const onEnter = useCallback(() => {
    notifyParent && notifyParent(true);
    setIsTriggeringVisibility(true);
  }, [notifyParent]);

  /**
   * Indicates the element itself is not the reason the submenu
   * is opened.
   */
  const onLeave = useCallback(() => {
    notifyParent && notifyParent(isChildVisible);
    setIsTriggeringVisibility(false);
  }, [isChildVisible, notifyParent]);

  /**
   * Indication that the element's child was hovered or focused
   */
  const onChildEnter = useCallback(() => {
    notifyParent && notifyParent(true);
    setIsChildVisible(true);
    setIsTriggeringVisibility(false);
  }, [notifyParent]);

  /**
   * Indication that the element should be potentially
   * closed and that the child element is no longer hovered/focused
   */
  const onChildLeave = useCallback(() => {
    setIsChildVisible(false);
    notifyParent && notifyParent(isTriggeringVisibility);
  }, [notifyParent, isTriggeringVisibility]);

  /**
   * Indication that the child element should be noted as
   * non-visible but that the parent element should stay visible.
   * This happens when you press ESC key when an menuitem is focused.
   */
  const onChildLeaveParentStay = useCallback(() => {
    setIsChildVisible(false);
    setIsTriggeringVisibility(true);
    notifyParent && notifyParent(true);
  }, [notifyParent]);

  /**
   * Will be passed to child as notifyParent function
   */
  const childVisibilityUpdate = useCallback(
    (newValueForChild) => {
      if (newValueForChild === true) {
        onChildEnter();
      } else if (newValueForChild === false) {
        onChildLeave();
      } else {
        onChildLeaveParentStay();
      }
    },
    [onChildEnter, onChildLeaveParentStay, onChildLeave]
  );

  /**
   * Should be called on click of menuitems with children (submenu)
   * and menu button.
   *
   * This allows us to distinguish between two ways of working
   * (allowing hover or allowing click only).
   */
  const onClickHandler = useCallback(() => {
    if (!includeHover) {
      setIsChildVisible(!isChildVisible);
    }
    onEnter();
  }, [onEnter, isChildVisible, includeHover]);

  return [
    isChildVisible,
    {
      onEnter,
      onLeave,
      onChildEnter,
      onChildLeave,
      onChildLeaveParentStay,
      setShow: setIsChildVisible,
      childVisibilityUpdate,
      onClickHandler,
    },
  ];
}

export default useVisibilityHook;
