import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import Group from './components/Group';
import { BasicCheckboxProps, ThemedCheckboxProps, CheckboxThemeMapper } from './types';
import { ReactComponent as Svg } from './checkbox.svg';

type CheckboxStaticProps = {
  Themed: React.FunctionComponent<ThemedCheckboxProps> & {
    mapper?: CheckboxThemeMapper;
  };
  Group: React.FunctionComponent<any>;
  svg: React.FunctionComponent;
};

/**
 * Basic checkbox implemented as a [controlled component](https://reactjs.org/docs/forms.html#controlled-components).<br/>
 * It's HTML structure is implemented using the input element with `type` attribute set to 'checkbox'. (
 * For more information about the input element check [here](https://www.w3schools.com/jsref/dom_obj_checkbox.asp). <br/>
 * When using multiple instances for same form input, we recommend using `Checkbox.Group` component which is a basic implementation of the wrapper of a checkbox group.
 * @component
 */
const Checkbox: React.FunctionComponent<BasicCheckboxProps> & CheckboxStaticProps = (props) => (
  <Basic {...props} />
);

Checkbox.Group = Group;
Checkbox.Themed = Themed;
Checkbox.displayName = 'Checkbox';
Checkbox.svg = Svg;

export default Checkbox;
