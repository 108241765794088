import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import Item from './components/Item';
import { ReactComponent as Svg } from './list.svg';

import {
  BasicListProperties,
  ListItemProperties,
  ThemedListProperties,
  ListThemeMapperType,
} from './types';

/** Static properties of the List component*/
type ListStatic = {
  Themed: React.FunctionComponent<ThemedListProperties> & {
    mapper?: ListThemeMapperType;
  };
  Item: React.FunctionComponent<ListItemProperties>;
  svg: React.FunctionComponent;
};

/**
 * @since v0.3.0
 *
 * Represents a list of items. If the order is important it's rendered as an
 * ordered list and if the property type is set to `unordered` then the
 * ordering of the children displayed is not important.
 * Importance is defined whether the difference in order would change the meaning
 * of the document - in ordered lists this is the case.
 *
 * As we are using `ul` and `ol` for rendering this element, you either must use `List.Item` as children
 * or make sure only `li` HTML elements are rendered directly underneath.
 * @component
 * */
const List: React.FunctionComponent<BasicListProperties> & ListStatic = (
  props: BasicListProperties
) => <Basic {...props} />;

List.Themed = Themed;
List.Item = Item;

List.displayName = 'List';
List.svg = Svg;
List.defaultProps = {
  type: 'ul',
  listItemIcon: '$icon--circle',
  role: 'list',
};

export default List;
