import * as React from 'react';
import classnames from 'classnames';
import { StyledLi } from './Styled';
import Button from '../../../General/Button';
import Icon from '../../../General/Icon';
import Typography from '../../../General/Typography';
import Flex from '../../../Layout/Flex';
import styles from '../index.module.scss';
import { handleKeyDown } from './eventHandlers';
import { MenuItemProperties } from '../types';

/**
 * A component that represents each individual action inside the system.
 * By clicking or pressing enter, this component will trigger the passed `onClick`
 * function.
 *
 * @visibleName Menu.Item
 * @since 0.6.0
 */

const MenuItem: React.FunctionComponent<MenuItemProperties> = ({
  onClick,
  id,
  style,
  children,
  notifyParent,
  testId,
  className,
  elevation,
  includeHover,
  disabled,
  isParentVisible,
  icon,
  label,
}) => {
  const [clicked, setClicked] = React.useState(false);

  const onClickHandler = React.useCallback(
    (e) => {
      e && e.stopPropagation();
      e && e.nativeEvent.stopImmediatePropagation();
      setClicked(true);
      notifyParent(false);
      onClick && onClick(e);
    },
    [onClick, notifyParent]
  );

  const onEnterHandler = React.useCallback(
    (e) => {
      e && e.stopPropagation();
      e && e.nativeEvent.stopImmediatePropagation();
      notifyParent(true);
    },
    [notifyParent]
  );

  const onLeaveHandler = React.useCallback(
    (e) => {
      e && e.stopPropagation();
      e && e.nativeEvent.stopImmediatePropagation();
      notifyParent(clicked ? false : undefined);
    },
    [notifyParent, clicked]
  );

  const notifyTrue = React.useCallback(() => notifyParent(true), [notifyParent]);

  const IconLabelContent = (label || icon) && (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      className={styles.iconLabelContentFlexWrapper}
    >
      {icon && typeof icon === 'string' && (
        <Icon icon={icon} fontSize="15px" className={styles.icon} />
      )}
      {icon && React.isValidElement(icon) && icon}
      {label && <Typography className={styles.label}>{label}</Typography>}
    </Flex>
  );

  return (
    <StyledLi
      elevation={elevation}
      role="none"
      className={classnames(styles.menu__item, className, {
        [styles.disabled]: disabled,
      })}
      onMouseEnter={includeHover ? onEnterHandler : undefined}
      onMouseLeave={includeHover ? onLeaveHandler : undefined}
      testId={testId}
      id={id}
      style={style}
    >
      <Button
        role="menuitem"
        className={classnames(styles.menu__item__button, {
          [`${className}__button`]: !!className,
        })}
        sneaky
        disabled={disabled}
        hidden={!isParentVisible}
        onFocus={!disabled ? onEnterHandler : undefined}
        onClick={!disabled ? onClickHandler : undefined}
        onKeyDown={!disabled ? handleKeyDown(notifyTrue, notifyParent) : undefined}
      >
        {children ? children : IconLabelContent}
      </Button>
    </StyledLi>
  );
};

MenuItem.displayName = 'Menu.Item';
export default MenuItem;
