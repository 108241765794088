import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import checkValue from '../../../../styles/checkValue';
import styles from '../index.module.scss';

const StyledList = styled.div`
  z-index: ${(props: { elevation?: string | number }) => checkValue(props.elevation?.toString())};
`;

/**
 * A part of the `Select` component.
 * An element containing a list of options which the user is allowed to select (one or more).
 * Note that this implementation uses the listbox role, which means that it's not expected
 * that the user will interact with elements inside of the `Select.Option` component.
 *
 * **Note** For most cases, it will be enough just to display different styling on display.
 * If you really need interactivity inside the `Select.Option` you might need a different component
 * (one that implements role="grid" and has more complex keyboard navigation handling).
 */
const SelectListbox = ({
  elevation,
  expanded,
  children,
  id,
  header,
  footer,
  className,
  ariaActiveDesendant,
  testId,
  ariaMultiSelectable,
  optionsClassname,
  autoMaxWidth,
}) => (
  <StyledList
    role="presentation"
    elevation={elevation}
    className={
      !expanded
        ? classnames(
            styles.hidden,
            { [styles['select__listbox--autoWidth']]: autoMaxWidth },
            className
          )
        : classnames(
            styles.select__listbox,
            { [styles['select__listbox--autoWidth']]: autoMaxWidth },
            className
          )
    }
  >
    {header}
    <div
      tabIndex={0}
      id={id ? `${id}_listbox` : undefined}
      data-test-id={testId ? `${testId}_listbox` : undefined}
      aria-multiselectable={ariaMultiSelectable}
      aria-activedescendant={ariaActiveDesendant}
      role="listbox"
      className={classnames(styles.select__listbox__options, optionsClassname, {
        [`${className}__options`]: !!className,
      })}
      style={{ ...(autoMaxWidth && { maxWidth: autoMaxWidth }) }}
    >
      {children}
    </div>
    {footer}
  </StyledList>
);

export default SelectListbox;
