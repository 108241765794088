import * as React from 'react';
import styles from '../index.module.scss';

import List from '../../../DataDisplay/List';
import { DefaultPageBreadcrumbsProperties } from '../types';

/**
 * A component that accepts an array of components (preferably Link components) that leads the user to the chosen path
 * @since 1.4.0
 *
 * @component
 * @visibleName DefaultPage.Breadcrumbs
 */
const DefaultPageBreadcrumbs = ({ id, testId, activeRoutes }: DefaultPageBreadcrumbsProperties) => {
  const { defaultPageBreadcrumbs, activeBreadcrumb } = styles;

  const activeRoutesCount = activeRoutes.length;

  const isActive = (index) => index + 1 === activeRoutesCount;
  const hasNext = (index) => index + 1 < activeRoutesCount;

  return (
    <List id={id} testId={testId} type={'ul'} listItemIcon="" className={defaultPageBreadcrumbs}>
      {activeRoutes.length > 1 ? (
        activeRoutes.map((route, index) => (
          <List.Item key={index} className={isActive(index) ? activeBreadcrumb : ''}>
            {route} {hasNext(index) && '/ '}
          </List.Item>
        ))
      ) : (
        <List.Item />
      )}
    </List>
  );
};
DefaultPageBreadcrumbs.displayName = 'DefaultPage.Breadcrumbs';

export default DefaultPageBreadcrumbs;
