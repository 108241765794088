import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { BasicCardProperties } from '../types';

/**
 * Basic implementation of the Card component.
 * Applies some basic styling
 */

const BasicCard: React.FunctionComponent<BasicCardProperties> = ({
  style,
  onClick,
  elevated,
  alignment,
  className,
  role,
  testId,
  id,
  ...props
}) => {
  const keyDownHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        onClick && onClick(event);
      }
    },
    [onClick]
  );

  const clickHandler = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onClick && onClick(event);
    },
    [onClick]
  );

  return (
    <div
      id={id}
      data-test-id={testId ? testId : undefined}
      style={style}
      onKeyDown={onClick && keyDownHandler}
      onClick={onClick && clickHandler}
      role={role}
      className={classnames(styles.card, { [styles.elevated]: elevated }, className)}
    >
      {!alignment && props.children}
      {alignment &&
        React.Children.map(props.children, (child) =>
          React.cloneElement(child as React.ReactElement<any>, { alignment })
        )}
    </div>
  );
};

BasicCard.defaultProps = {
  elevated: false,
};

BasicCard.displayName = 'BasicCard';
export default BasicCard;
