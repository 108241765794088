import React from 'react';
import classnames from 'classnames';
import Button from '../../../General/Button';
import Icon from '../../../General/Icon';
import styles from '../index.module.scss';

/**
 * A button that triggers the display of a listbox. One of two main parts of a
 * collapsible select-listbox widget which has a functionality similar to an HTML select input.
 */
const SelectButton = ({
  children,
  expanded,
  id,
  disabled,
  setExpanded,
  ariaLabelledBy,
  className,
  onKeyDown,
  testId,
  autoMaxWidth,
  round,
  padding,
}) => {
  const onClickCallback = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setExpanded(!expanded);
    },
    [expanded, setExpanded]
  );

  return (
    <Button
      disabled={disabled}
      sneaky
      onKeyDown={onKeyDown}
      className={classnames(
        styles.select__button,
        { [styles['select__button--autoWidth']]: autoMaxWidth },
        className
      )}
      aria-expanded={expanded}
      aria-labelledby={ariaLabelledBy}
      aria-controls={`${id}_listbox`}
      aria-haspopup="listbox"
      id={id}
      data-test-id={testId ? testId : undefined}
      onClick={onClickCallback}
      round={round || 'none'}
      padding={padding || 'none'}
      style={{ ...(autoMaxWidth && { maxWidth: autoMaxWidth }) }}

    >
      {children}
      <Icon
        className={classnames(styles.select__button__icon, {
          [`${className}__icon`]: !!className,
        })}
        icon="$icon--menu-down"
      />
    </Button>
  );
};

export default SelectButton;
