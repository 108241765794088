import * as React from 'react';
import Basic from './components/Basic';
import Group from './components/Group';
import Textarea from './components/Textarea';
import { InputProperties, GroupInputProperties, TextareaProperties } from './types';
import { ReactComponent as Svg } from './input.svg';

type InputStaticProps = {
  Group: React.FunctionComponent<GroupInputProperties>;
  Textarea: React.FunctionComponent<TextareaProperties>;
  svg: React.FunctionComponent;
};

/**
 * Basic component that specifies an input field where the user can enter data. You can change the type of the data even though
 * this component has it's design adjusted to textual input. We suggest using this as a controlled component - meaning
 * you controll it's value and handle the onChange updates to the value. This makes it easier to handle cleanup or programmatic
 * setup of textual value.
 *
 * @since 0.7.0
 */

const Input: React.FunctionComponent<InputProperties> & InputStaticProps = (props) => (
  <Basic {...props} />
);

Input.displayName = 'Input';
Input.svg = Svg;
Input.Group = Group;
Input.Textarea = Textarea;

Input.defaultProps = {
  size: 'small',
  error: false,
  warning: false,
  hidden: false,
  iconPosition: 'right',
};

export default Input;
