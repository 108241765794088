import * as React from 'react';
import { ClientThemeType, ThemeClient, Palette, withTheme } from '../../../../theme/index';
import LayerSetter from './LayerSetter';
import { printCoreWarning } from '../../../shared';
import { ModalThemeMapperType, ThemedModalProperties } from '../types';

/**
 * Function that maps client passed theme to our component's theme.
 * Used to limit impacts of future changes to client theme to our Modal component.
 */
const themeMapper: ModalThemeMapperType = (theme, { palette = Palette.brand, themeOverwrite }) => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Modal.Themed component must pass a theme. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }

    return {
      backgroundColor: themeOverwrite?.backgroundColor || selectedPalette.main,
      color: themeOverwrite?.color || selectedPalette.contrast,
    };
  }
  return undefined;
};

const ThemedModal: React.FunctionComponent<ThemedModalProperties> = ({
  theme,
  palette = Palette.brand,
  themeOverwrite,
  ...otherProps
}) => {
  const mappedTheme = themeMapper(theme as ThemeClient, {
    palette,
    themeOverwrite,
  });
  return <LayerSetter theme={mappedTheme} {...otherProps} />;
};

/**
 * Themed implementation of the Modal component. Will apply styling to the Modal.Title component.
 * @component
 * @visibleName Modal.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedModalProperties> & {
  mapper?: ModalThemeMapperType;
} = withTheme(ThemedModal);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'Modal.Themed';

ThemedExport.defaultProps = {
  removeCloseButton: false,
  xPosition: 'center',
  yPosition: 'center',
  modalRoot: 'modal-layer',
  size: 'medium',
};

export default ThemedExport;
