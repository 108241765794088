import React from 'react';
import classnames from 'classnames';
import Card from '../../../DataDisplay/Card';
import { ModalElementProperties } from '../types';
import styles from '../index.module.scss';

/**
 *
 * @component
 * @visibleName Modal.Title
 */
const Title = ({ className, children, icon, ...other }: ModalElementProperties) => (
  <Card.Header
    className={classnames(styles.modal__title, {
      [`${className}__title`]: !!className,
      [styles['modal__title--with-icon']]: !!icon,
    })}
    {...other}
  >
    {children}
    {icon}
  </Card.Header>
);

Title.displayName = 'Modal.Title';
export default Title;
