import * as React from 'react';
import classnames from 'classnames';
import Icon from '../../../General/Icon';
import styles from '../index.module.scss';
import { ListItemProperties } from '../types';

/**
 * Basic implementation of the list item that uses the icon for displaying the list points .
 * Should be used as a child of the `List` component.
 * @component
 * @visibleName List.Item
 */

const ListItem: React.FunctionComponent<ListItemProperties> = ({
  children,
  listItemIcon,
  theme,
  style,
  hidden,
  className,
  tabIndex,
  onClick,
  onMouseEnter,
  onMouseLeave,
  forwardedRef,
  role,
  testId,
  id,
}) => {
  return (
    <li
      ref={forwardedRef}
      style={style}
      className={classnames(styles.list__item, className)}
      tabIndex={tabIndex}
      role={role}
      hidden={hidden}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      data-test-id={testId}
      key={id}
    >
      {listItemIcon && (
        <Icon
          className={classnames(styles.list__item__icon, {
            [`${className}__icon`]: !!className,
          })}
          icon={listItemIcon}
          hoverColor={theme?.color}
          color={theme?.color}
          fontSize={theme?.fontSize}
        />
      )}
      {children}
    </li>
  );
};

ListItem.displayName = 'List.Item';
export default ListItem;
