import * as React from 'react';
import classnames from 'classnames';
import Flex from '../../../Layout/Flex';
import { printCoreWarning } from '../../../shared';
import { BasicCheckboxProps, CheckboxGroupProps, ThemedCheckboxProps } from '../types';
import styles from '../index.module.scss';

/**
 * Helper component that makes sure the each checkbox's function
 * is not created on each render cycle.
 * @param properties
 */
const SelectableCheckboxButton = ({
  id,
  value,
  onChange,
  child,
  ...props
}: Omit<BasicCheckboxProps | ThemedCheckboxProps, 'onChange'> & {
  child: React.ReactNode;
  onChange: (id: string) => void;
}) => {
  /**
   * Adding to avoid the need for the client to supply this
   * optimization himself.
   */
  const selectThisCheckbox = React.useCallback(() => {
    if (onChange) {
      onChange(id || value);
    }
  }, [onChange, id, value]);

  return React.cloneElement(child as React.ReactElement<any>, {
    ...props,
    id,
    value,
    onChange: selectThisCheckbox,
  });
};

/**
 * A group of checkbox buttons with the same name.
 * Each checkbox button passed as child must have either an unique id or
 * unique value so the parent can know if it's checked.
 * This is the recommended wrapper around the `Checkbox` component
 * because it provides some basic styling and makes sure that the onChange
 * function retrieves the checkbox id (or value) on selection.
 *
 * @visibleName Checkbox.Group
 */
const Group = ({
  className,
  children,
  name,
  onChange,
  selected,
  vertical,
  style,
  flexBasis,
  wrap,
  justifyContent,
  alignItems = vertical ? 'flex-start' : 'center',
}: CheckboxGroupProps) => {
  if (React.Children.count(children) < 2) {
    printCoreWarning(`You passed one or none children to the Checkbox.Group component that expects
    to have at least two Checkboxes. Did you wish to use a single Checkbox component instead?`);
  }

  return (
    <Flex
      alignItems={alignItems}
      wrap={wrap}
      justifyContent={justifyContent}
      flexBasis={flexBasis}
      direction={vertical ? 'column' : 'row'}
      style={style}
      className={classnames(styles['checkbox__group'], {
        [className as string]: !!className,
        [styles['checkbox__group--horizontal']]: !vertical,
      })}
    >
      {React.Children.map(children, (child) => (
        <SelectableCheckboxButton
          {...child.props}
          checked={selected && selected.includes(child.props.id)}
          name={name || child.props.name}
          onChange={onChange}
          child={child}
        />
      ))}
    </Flex>
  );
};

Group.displayName = 'Checkbox.Group';
export default Group;
