import * as React from 'react';
import classnames from 'classnames';
import { StyledLi } from './Styled';
import Separator from '../../../Layout/Separator';
import useVisibilityHook from './useVisibilityHook';
import styles from '../index.module.scss';
import { MenuSeparatorProperties } from '../types';

/**
 *
 * Single horizontal line - can be used to group various menu items
 * together. Is not focusable or clickable.
 *
 * @visibleName Menu.Separator
 * @since 0.6.0
 */

const MenuSeparator: React.FunctionComponent<MenuSeparatorProperties> = ({
  id,
  style,
  notifyParent,
  testId,
  className,
  elevation,
}) => {
  const [, { onEnter, onLeave }] = useVisibilityHook({
    notifyParent,
    initialVisibility: false,
  });

  const onEnterHandler = React.useCallback(
    (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onEnter();
    },
    [onEnter]
  );

  return (
    <StyledLi
      elevation={elevation}
      role="none"
      listItemIcon=""
      className={classnames(styles.menu__item__separator, className)}
      onMouseEnter={onEnterHandler}
      onMouseLeave={onLeave}
      testId={testId}
      id={id}
      style={style}
    >
      <Separator className={styles.separator} />
    </StyledLi>
  );
};

MenuSeparator.displayName = 'Menu.Separator';
export default MenuSeparator;
