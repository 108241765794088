import React from 'react';
import classnames from 'classnames';
import Card from '../../../DataDisplay/Card';
import { ModalElementProperties } from '../types';
import styles from '../index.module.scss';

/**
 *
 * @component
 * @visibleName Modal.Footer
 */
const Footer = ({ className, children, ...other }: ModalElementProperties) => (
  <Card.Footer
    className={classnames(styles.modal__footer, {
      [`${className}__footer`]: !!className,
    })}
    {...other}
  >
    {children}
  </Card.Footer>
);

Footer.defaultProps = {
  alignment: 'right',
};

Footer.displayName = 'Modal.Footer';

export default Footer;
