import * as React from 'react';
import styled from 'styled-components';

import { BasicTagProps } from './Basic';
import valueExtractor from '../../../../styles/checkValue';

const printWarning = (propName: string) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      '%c CORE-UI-KIT WARNING',
      'color: red',
      `\n\rYou are passing both the theme and ${propName} property. The property ${propName} will override the passed ${propName} from the theme.\n\rIs this what you wanted to achieve?`
    );
  }
};

export type StyledTagProps = React.HTMLAttributes<HTMLDivElement> & BasicTagProps;

export const StyledTag: React.FunctionComponent<StyledTagProps> = styled.div<StyledTagProps>`
  color: ${(props: StyledTagProps) => {
    if (props.color && props.theme?.default?.color) {
      printWarning('color');
    }
    return valueExtractor(props.color) || props.theme?.default?.color;
  }};

  background-color: ${(props: StyledTagProps) => {
    if (props.backgroundColor && props.theme?.default?.backgroundColor) {
      printWarning('backgroundColor');
    }
    return valueExtractor(props.backgroundColor) || props.theme?.default?.backgroundColor;
  }};

  border-color: ${(props: StyledTagProps) => {
    if (props.borderColor && props.theme?.default?.borderColor) {
      printWarning('borderColor');
    }
    return valueExtractor(props.borderColor) || props.theme?.default?.borderColor;
  }};
`;
