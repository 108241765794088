import * as React from 'react';
import Basic from './components/Basic';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import Filter from './components/Filter';
import Headline from './components/Headline';
import SectionHeadline from './components/SectionHeadline';

import {
  BasicDefaultPageProperties,
  DefaultPageHeaderProperties,
  DefaultPageBreadcrumbsProperties,
  DefaultPageFilterProperties,
  DefaultPageHeadlineProperties,
  DefaultPageSectionHeadlineProperties,
} from './types';

/** Static properties of the DefaultPage.
 * Useful when exporting different components under the same name */
type DefaultPageStatic = {
  Header: React.FunctionComponent<DefaultPageHeaderProperties>;
  Breadcrumbs: React.FunctionComponent<DefaultPageBreadcrumbsProperties>;
  Filter: React.FunctionComponent<DefaultPageFilterProperties>;
  Headline: React.FunctionComponent<DefaultPageHeadlineProperties>;
  SectionHeadline: React.FunctionComponent<DefaultPageSectionHeadlineProperties>;
  svg: React.FunctionComponent;
};

/** Basic implementation of the DefaultPage component.
 * @component
 * @since 1.3.0
 * */
const DefaultPage: React.FunctionComponent<BasicDefaultPageProperties> & DefaultPageStatic = (
  props: BasicDefaultPageProperties
) => <Basic {...props} />;

DefaultPage.displayName = 'DefaultPage';
DefaultPage.Header = Header;
DefaultPage.Breadcrumbs = Breadcrumbs;
DefaultPage.Filter = Filter;
DefaultPage.Headline = Headline;
DefaultPage.SectionHeadline = SectionHeadline;
DefaultPage.svg = () => <div> Missing component svg </div>;

export default DefaultPage;
