import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { CardChildType } from '../types';

/**
 * Container for the header of the card.
 * Makes sure to position itself on bottom of content.
 *
 * @component
 * @visibleName Card.Footer
 */
const CardFooter: React.FunctionComponent<CardChildType> = ({
  alignment,
  className,
  style,
  border,
  ...props
}) => {
  return (
    <div
      style={style}
      className={classnames(
        styles.card__footer,
        styles[alignment || 'left'],
        { [styles.border]: !!border },
        className
      )}
    >
      {props.children}
    </div>
  );
};

CardFooter.displayName = 'Card.Footer';
export default CardFooter;
