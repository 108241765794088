import React from 'react';
import classnames from 'classnames';
import Typography from '../../../General/Typography';
import { AccordionElementProperties } from '../types';

/**
 *
 * @visibleName Accordion.Title
 * @component
 */
const Title = ({ className, children, ...other }: AccordionElementProperties) => (
  <Typography
    className={classnames({
      // eslint-disable-next-line @typescript-eslint/camelcase
      Accordion__title: true,
      className,
    })}
    {...other}
  >
    {children}
  </Typography>
);

export default Title;
