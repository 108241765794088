import * as React from 'react';
import { BasicToggleProps } from '../types';
import { StyledToggle } from './Styled';

import styles from '../index.module.scss';

class BasicToggle extends React.Component<BasicToggleProps> {
  static defaultProps = {
    active: false,
    disabled: false,
    onChange: () => {
      /* do nothing */
    },
  };

  toggleId: string;

  constructor(props: BasicToggleProps) {
    super(props);
    this.toggleId = props.id || 'toggle_' + Math.random().toString(16).slice(2);
  }

  handleChange = () => this.props.onChange(this.toggleId, !this.props.active);

  render() {
    const { active, disabled, testId } = this.props;

    const cssActive = styles[`toggle--${active ? 'active' : 'inactive'}`];
    const cssDisabled = disabled ? styles['toggle--disabled'] : '';

    return (
      <StyledToggle
        {...this.props}
        id={this.toggleId}
        data-test-id={testId ? testId : undefined}
        onClick={this.handleChange}
        className={`${styles.toggle} ${cssActive} ${cssDisabled}`}
      >
        <input
          type="checkbox"
          className={styles['toggle--input']}
          tabIndex={-1}
          disabled={disabled}
          checked={active}
          readOnly
        />
        <span className={styles['toggle--slider']} />
      </StyledToggle>
    );
  }
}

export default BasicToggle;
