import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import Icon from '../../../General/Icon';
import LinkContent from './Content';
import { BasicLinkProperties } from '../types';
import { printCoreWarning } from '../../../shared';

/**
 * Basic component for hyperlinks which can lead to the same application (inner routes) or external
 * links.
 */

const BasicLink: React.FunctionComponent<BasicLinkProperties> = ({
  Component,
  children,
  className,
  componentProps,
  sneaky,
  disabled,
  external,
  href,
  icon,
  id,
  iconAlignment,
  title,
  showHref,
  onClick,
  style,
  testId,
  iconColor,
}) => {
  const IconComponent =
    icon || external ? (
      <Icon
        color={iconColor}
        hoverColor={iconColor}
        className={classnames(styles.link__icon, {
          [styles.link__icon__custom]: !!iconColor,
        })}
        icon={icon && !external ? icon : '$icon--open-external'}
      />
    ) : null;

  if (Component) {
    return (
      <Component
        style={style}
        className={classnames(styles.link, className, {
          [styles.external]: external,
          [styles.disabled]: !!disabled,
        })}
        id={id}
        data-test-id={testId ? testId : undefined}
        onClick={onClick}
        aria-disabled={disabled}
        title={title}
        {...componentProps}
      >
        <LinkContent
          iconAlignment={iconAlignment}
          showIcon={external || !!icon}
          href={null}
          Icon={IconComponent}
          showHref={false}
        >
          {children}
        </LinkContent>
      </Component>
    );
  }
  if (!href) {
    printCoreWarning(
      'Link - You are not using a custom component but have not passed the href property.'
    );
  }
  if (disabled) {
    printCoreWarning(
      'Link - native elements do not support the disabled property. Are you sure you do not need text instead of link?'
    );
  }
  return (
    <a
      href={disabled ? window.location.href : href}
      style={style}
      id={id}
      data-test-id={testId ? testId : undefined}
      aria-disabled={disabled}
      title={disabled ? '' : title || href}
      className={classnames(className, {
        [styles.external]: external,
        [styles.disabled]: disabled,
        [styles.sneaky]: !!sneaky,
        [styles.link]: !sneaky,
      })}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener noreferrer' : undefined}
      onClick={onClick}
    >
      <LinkContent
        iconAlignment={iconAlignment}
        showIcon={external || !!icon}
        href={href}
        Icon={IconComponent}
        showHref={showHref}
      >
        {children}
      </LinkContent>
    </a>
  );
};

export default BasicLink;
