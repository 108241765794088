import * as React from 'react';
import BasicArrowTag from './ArrowTag/Basic';
import { ArrowTagExportType } from './ArrowTag/types';
import Themed from './ArrowTag/Themed';

const ArrowTag: ArrowTagExportType = (props) => <BasicArrowTag {...props} />;

ArrowTag.Themed = Themed;
ArrowTag.displayName = 'Tag.Arrow';

export default ArrowTag;
