import * as React from 'react';

import { TabListProps } from '../types';
import Button from '../../../General/Button';
import styles from '../index.module.scss';

const columnProps = {
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
};
const rowProps = {
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
};

const getFirstTabValue = (tabValues: string[]): string => tabValues[0];

const getLastTabValue = (tabValues: string[]): string => tabValues[tabValues.length - 1];

// TODO: select first if selectedTab is not present in tabValues
const getNextTabValue = (tabValues: string[], selectedTab): string =>
  tabValues[(tabValues.indexOf(selectedTab) + 1) % tabValues.length];

const getPreviousTabValue = (tabValues: string[], selectedTab): string =>
  tabValues[(tabValues.length + tabValues.indexOf(selectedTab) - 1) % tabValues.length];

const keyDownHandlerFactory = (tabValues, selected, onChangeTab, vertical) => (
  event: React.KeyboardEvent<HTMLElement>
) => {
  // previous tab
  if ((!vertical && event.key === 'ArrowLeft') || (vertical && event.key === 'ArrowUp')) {
    event.preventDefault();
    event.stopPropagation();
    const previous = getPreviousTabValue(tabValues, selected);
    onChangeTab(previous);
  }
  // next tab
  if ((!vertical && event.key === 'ArrowRight') || (vertical && event.key === 'ArrowDown')) {
    event.preventDefault();
    event.stopPropagation();
    const next = getNextTabValue(tabValues, selected);
    onChangeTab(next);
  }
  // first tab
  if (event.key === 'Home') {
    event.preventDefault();
    event.stopPropagation();
    const previous = getFirstTabValue(tabValues);
    onChangeTab(previous);
  }
  // last tab
  if (event.key === 'End') {
    event.preventDefault();
    event.stopPropagation();
    const previous = getLastTabValue(tabValues);
    onChangeTab(previous);
  }
};

/** Parent to multiple Tabs.Tab components. */
const TabList: React.FunctionComponent<TabListProps> & {
  _internalType: string;
} = ({ selected, style, className, children, onChangeTab, vertical, narrowTabs }) => {
  // get tab values from children
  const tabValues: string[] = React.useMemo(
    () => React.Children.map(children, (child: any) => child.props.value) || [],
    [children]
  );

  const keyDownHandler = React.useMemo(
    () => keyDownHandlerFactory(tabValues, selected, onChangeTab, vertical),
    [tabValues, selected, onChangeTab, vertical]
  );

  const rendered = React.useMemo(
    () =>
      React.Children.map(children, (child) => {
        const isTabComponent: boolean = (child as any).type?._internalType?.startsWith('Tabs.Tab');
        const childProps = (child as any)?.props;

        if (isTabComponent) {
          // child is Tabs.Tab component
          return React.cloneElement(child as React.ReactElement, {
            ...childProps,
            selected: selected === childProps?.value,
            onChangeTab,
            className: narrowTabs ? styles['tab-narrow'] : styles['tab-wide'],
          });
        }

        // child is custom component
        return child;
      }),
    [children, narrowTabs, selected, onChangeTab]
  );

  const flexProps = vertical ? columnProps : rowProps;

  return (
    <Button.Group
      role="tablist"
      className={className}
      style={style}
      vertical={vertical}
      aria-orientation={vertical ? 'vertical' : 'horizontal'}
      onKeyDown={keyDownHandler}
      {...(flexProps as any)}
    >
      {rendered as any}
      {(vertical || narrowTabs) && <div className={`${styles['tab-stretcher']} tab-stretcher`} />}
    </Button.Group>
  );
};

TabList.defaultProps = {
  narrowTabs: false,
};

TabList.displayName = 'Tabs.TabList';
TabList._internalType = 'Tabs.TabList';
export default TabList;
