import * as React from 'react';

import Typography from '../../../General/Typography';
import { DefaultPageHeadlineProperties } from '../types';
import Flex from '../../../Layout/Flex';
import styles from '../index.module.scss';

/** DefaultPage implementation of the Headline component used at the top of every page
 * based on the Page anatomy: https://app.zeplin.io/project/589d88f4523d53d3d55f5117/screen/5f771da3505fe789d49d4808
 *
 * Mandatory page element. Placed on the top of every page. Centered.
 * @since 1.1.1
 * 
 * @component
 * @visibleName DefaultPage.Headline
 */

const DefaultPage: React.FunctionComponent<DefaultPageHeadlineProperties> = (
  props: React.PropsWithChildren<DefaultPageHeadlineProperties>
) => {
  const { children, subHeadline } = props;

  const HeadlineMarkup = () => (
    <Typography
      color={'$grey--1200'}
      fontSize={'$font_size--32'}
      fontWeight={'$font_weight--light'}
    >
      {children}
    </Typography>
  );

  const SubHeadlineMarkup = () => (
    <Typography
      color={'$grey--1200'}
      fontWeight={'$font_weight--light'}
      className={styles.subHeadline}
    >
      {subHeadline}
    </Typography>
  );

  return (
    <Flex direction="column" alignItems="center">
      <HeadlineMarkup/>
      {subHeadline && <SubHeadlineMarkup/>}
    </Flex>
  );
};

DefaultPage.displayName = 'DefaultPage.Headline';
export default DefaultPage;
