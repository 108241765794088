import base from '../index.scss';

export type ColorsType = {
  white: string;
  black: string;
  [key: string]: string;
};

// Is not autogenerated, do not remove
const oldValues = {
  'white.old': 'indicatorText severityHintText severityCriticalText severityWarningText',
  'black.old': 'modalBackground',
  'grey.000.old': 'grey13',
  'grey.100.old': 'grey12 colorProgressbarGreyRight',
  'grey.200.old': 'grey11',
  'grey.300.old': 'grey10 colorProgressbarGreyLeft chartColors.backgroundGrey',
  'grey.400.old': 'grey9 calendarColors.disabled glyphColors.disabled',
  'grey.500.old': 'grey8',
  'grey.600.old': 'grey7 charts11 chartColors.rankings8 glyphColors.disabledHover',
  'grey.700.old': 'grey6 glyphColors.active glyphColors.trendNeutral',
  'grey.800.old': 'grey5  calendarColors.default chartColors.rootTag',
  'grey.900.old': 'grey4 glyphColors.default',
  'grey.1000.old': 'grey15',
  'grey.1100.old': 'grey3',
  'grey.1200.old': 'grey2 glyphColors.trendDark',
  'grey.1300.old': 'grey1 tooltipBackground',
  'grey.light.500.old': 'colorTextLightModule',
  'blue.dark.500.old': 'charts4 chartColors.charts5 chartColors.rankings4',
  'blue.dark.700.old': 'colorTextDarkModule',
  'blue.soft.100.old': 'chartColors.charts13',
  'blue.soft.300.old': 'tooltipLinkHoverColor',
  'blue.soft.500.old': 'newsFeedTitleIconBlue',
  'blue.strong.500.old': 'chartColors.charts9',
  'blue.moderate.500.old': 'tooltipLinkColor',
  'blue.moderate.700.old':
    'indicatorInfo searchIntentInformational colorSimilarity buyingCycleRetention charts10',
  'blue.desaturated.500.old': 'chartColors.charts19',
  'cyan.light.300.old': 'calendarColors.reportHover',
  'cyan.light.500.old': 'tableColors.projectDomain',
  'cyan.light.700.old': 'calendarColors.report',
  'cyan.moderate.100.old': 'blueGradient5 colorProgressbarBlueRight',
  'cyan.moderate.300.old': 'blueGradient6',
  'cyan.moderate.500.old': 'blueGradient4',
  'cyan.moderate.700.old': 'blueGradient3',
  'cyan.moderate.900.old': 'blueGradient2',
  'cyan.moderate.1100.old': 'blueGradient1',
  'cyan.moderate.1300.old': 'newsFeedTitleIconTurquoise',
  'soft.cyan.500.old': 'colorProgressbarBlueLeft',
  'cyan.strong.200.old':
    'elementBlueLight chartColors.rankingSpreadLight chartColors.marketshareCompetitor chartColors.benchmarkColChart1light',
  'cyan.strong.500.old':
    'elementBlue charts1 chartColors.organic chartColors.marketShare chartColors.rankingSpreadDark chartColors marketshareDomain chartColors.charts1 chartColors.benchmarkColChart1 chartColors.rankings3 glyphColors.activeHover',
  'cyan.dark.100.old': 'researchBlueDisabled',
  'cyan.dark.400.old': 'researchBlueLighter',
  'cyan.dark.700.old': 'researchBlueLight glyphColors.info',
  'cyan.dark.1000.old': 'researchBlue',
  'cyan.dark.1300.old': 'globalLink',
  'cyan.desaturated.200.old': 'newsFeedTitleIconSteelBlue',
  'cyan.desaturated.300.old': 'charts6',
  'cyan.desaturated.500.old': 'globalLinkLight',
  'orange.light.000.old': 'calendarColors.trendHover',
  'orange.light.100.old': 'tableColors.projectBenchmark',
  'orange.light.200.old': 'calendarColors.trend',
  'orange.light.300.old': 'colorProgressbarOrangeRight',
  'orange.light.500.old': 'colorProgressbarOrangeLeft',
  'orange.light.600.old': 'chartColors.charts10',
  'orange.light.900.old': 'chartColors.charts16',
  'orange.bright.100.old': 'chartColors.charts2 chartColors.benchmarkColChart2',
  'orange.bright.200.old': 'glyphColors.benchmark',
  'orange.bright.300.old': 'globalHighlightText',
  'orange.bright.500.old':
    'colorLineGradientTwo trafficLightDarkOrange kpiGradient4 kpiGradient3 chartColors.gradient2',
  'orange.very.soft.500.old': 'chartColors.benchmarkColChart2light',
  'orange.soft.100.old': 'contentOrangeDisabled',
  'orange.soft.400.old': 'contentOrangeLighter',
  'orange.soft.700.old': 'contentOrangeActive',
  'orange.soft.1000.old':
    'contentOrangeLight colorLineGradientThree trafficLightOrange kpiGradient5 kpiGradient6 chartColors.trafficIndex chartColors.gradient3 chartColors.rankings6',
  'orange.soft.1300.old': 'contentOrange',
  'orange.soft.1600.old':
    'indicatorWarn severityWarningBackground colorQuickWin charts2 glyphColors.warning',
  'orange.soft.1900.old': 'chartColors.social',
  'orange.vivid.200.old': 'elementOrangeLight chartColors.trafficIndexLight',
  'orange.vivid.500.old': 'elementOrange',
  'orange.dark.moderate.500.old': 'chartColors.charts11',
  'green.light.300.old': 'colorProgressbarGreenRight',
  'green.light.500.old': 'colorProgressbarGreenLeft',
  'green.soft.500.old': 'charts7',
  'green.dark.moderate.500.old': 'chartColors.paid ',
  'green.moderate.100.old': 'seoGreenDisabled searchExperience',
  'green.moderate.400.old': 'searchExperience seoGreenLighter chartColors.trafficValueLight',
  'green.moderate.700.old': 'searchExperience seoGreenLight chartColors.trafficValue',
  'green.moderate.1000.old': 'searchExperience seoGreenActive',
  'green.moderate.1300.old': 'searchExperience seoGreen',
  'green.strong.500.old': 'iFeedGreen',
  'green.dark.500.old': 'chartColors.charts7',
  'green.soft.lime.300.old': 'brand searchmetrics brandDisabled brandInactive',
  'green.soft.lime.500.old': 'chartColors.charts20',
  'green.soft.lime.700.old': 'brand searchmetrics brandActive',
  'green.moderate.cyan.100.old': 'chartColors.charts17',
  'green.moderate.cyan.500.old': 'charts3 newsfeedTitleIconGreen chartColors.rankings2',
  'green.moderate.cyan.700.old': 'chartColors.charts3',
  'green.moderate.cyan.900.old': 'brand searchmetrics',
  'green.light.lime.300.old': 'colorProgressbarGreenComparisonRight',
  'green.light.lime.500.old': 'colorProgressbarGreenComparisonLeft',
  'green.light.lime.700.old': 'chartColores.newKeywords',
  'green.dark.moderate.lime.500.old':
    'indicatorGood colorLineGradientFive trafficLightGreen searchIntentTransactional severityHintBackground kpiGradient10 kpiGradient9 buyingCyclePurchase chartColors.winner chartColors.winnerKeywords chartColors.gradient5 chartColors.rankings1  glyphColors.success glyphColors.trendPositive',
  'green.dark.moderate.lime.700.old': 'chartColors.charts15',
  'green.desaturated.200.old': 'elementGreenLight',
  'green.desaturated.500.old': 'elementGreen',
  'pink.desaturated.200.old': 'elementPurpleLight',
  'pink.desaturated.500.old': 'elementPurple chartColors.link chartColors.pageStrength',
  'pink.moderate.500.old': 'colorSeasonality',
  'pink.moderate.700.old': 'chartColors.charts8',
  'pink.dark.500.old': 'charts9',
  'magenta.desaturated.500.old': 'charts5',
  'magenta.desaturated.700.old': 'chartColors.charts12',
  'yellow.desaturated.100.old': 'settingsGreenDisabled',
  'yellow.desaturated.400.old': 'settingsActive',
  'yellow.desaturated.700.old': 'settingsGreen',
  'yellow.desaturated.1000.old': 'settingsGreenDarker',
  'yellow.moderate.500.old': 'chartColors.charts14 ',
  'yellow.dark.moderate.500.old':
    'colorLineGradientFour kpiGradient7 kpiGradient8 chartColors.domainScore chartColors.gradient4',
  'yellow.bright.300.old': 'chartColors.charts6 ',
  'yellow.bright.500.old': 'highlightFeatureLabelBackground',
  'yellow.soft.300.old':
    'trafficLightYellow buyingCycleConsideration charts8 chartColors.rankings5',
  'yellow.soft.500.old': 'searchIntentNavigational highlightFeatureLabelBorder',
  'red.bright.100.old': 'siteRedDisabled',
  'red.bright.400.old': 'siteRedLight',
  'red.bright.700.old': 'siteRed',
  'red.bright.1000.old':
    'indicatorBad colorLineGradientOne severityCriticalBackground kpiGradient2 kpiGradient1 kpiGradient0 chartColors.loser chartColors.loserKeywords chartColors.gradient1 chartColors.rankings7  glyphColors.error glyphColors.trendNegative',
  'red.bright.1200.old': 'buyingCycleAwareness',
  'red.pale.300.old': 'indicatorBadBackground tableColors.minValue',
  'red.pale.500.old': 'chartColors.charts18',
  'red.soft.300.old': 'colorProgressbarRedRight',
  'red.soft.500.old': 'colorProgressbarRedLeft',
  'red.soft.700.old': 'chartColors.keywordsOut',
  'red.soft.900.old': 'chartColors.charts4',
  'yellow.pale.500.old': 'indicatorWarnBackground',
  'green.pale.500.old': 'indicatorGoodBackground tableColors.maxValue',
  'blue.grayish.500.old': 'mainNav',
};

const availableColors: ColorsType = {
  white: base.white,
  black: base.black,
  'grey.000': base.grey_000,
  'grey.100': base.grey_100,
  'grey.200': base.grey_200,
  'grey.300': base.grey_300,
  'grey.400': base.grey_400,
  'grey.500': base.grey_500,
  'grey.600': base.grey_600,
  'grey.700': base.grey_700,
  'grey.800': base.grey_800,
  'grey.900': base.grey_900,
  'grey.1000': base.grey_1000,
  'grey.1100': base.grey_1100,
  'grey.1200': base.grey_1200,
  'grey.1300': base.grey_1300,
  'grey.light.500': base.grey_light_500,
  'blue.dark.500': base.blue_dark_500,
  'blue.dark.700': base.blue_dark_700,
  'blue.soft.100': base.blue_soft_100,
  'blue.soft.300': base.blue_soft_300,
  'blue.soft.500': base.blue_soft_500,
  'blue.strong.500': base.blue_strong_500,
  'blue.moderate.500': base.blue_moderate_500,
  'blue.moderate.700': base.blue_moderate_700,
  'blue.desaturated.500': base.blue_desaturated_500,
  'cyan.light.300': base.cyan_light_300,
  'cyan.light.500': base.cyan_light_500,
  'cyan.light.700': base.cyan_light_700,
  'cyan.moderate.100': base.cyan_moderate_100,
  'cyan.moderate.300': base.cyan_moderate_300,
  'cyan.moderate.500': base.cyan_moderate_500,
  'cyan.moderate.700': base.cyan_moderate_700,
  'cyan.moderate.900': base.cyan_moderate_900,
  'cyan.moderate.1100': base.cyan_moderate_1100,
  'cyan.moderate.1300': base.cyan_moderate_1300,
  'soft.cyan.500': base.soft_cyan_500,
  'cyan.strong.200': base.cyan_strong_200,
  'cyan.strong.500': base.cyan_strong_500,
  'cyan.dark.100': base.cyan_dark_100,
  'cyan.dark.400': base.cyan_dark_400,
  'cyan.dark.700': base.cyan_dark_700,
  'cyan.dark.1000': base.cyan_dark_1000,
  'cyan.dark.1300': base.cyan_dark_1300,
  'cyan.desaturated.200': base.cyan_desaturated_200,
  'cyan.desaturated.300': base.cyan_desaturated_300,
  'cyan.desaturated.500': base.cyan_desaturated_500,
  'orange.light.000': base.orange_light_000,
  'orange.light.100': base.orange_light_100,
  'orange.light.200': base.orange_light_200,
  'orange.light.300': base.orange_light_300,
  'orange.light.500': base.orange_light_500,
  'orange.light.600': base.orange_light_600,
  'orange.light.900': base.orange_light_900,
  'orange.bright.100': base.orange_bright_100,
  'orange.bright.200': base.orange_bright_200,
  'orange.bright.300': base.orange_bright_300,
  'orange.bright.500': base.orange_bright_500,
  'orange.very.soft.500': base.orange_very_soft_500,
  'orange.soft.100': base.orange_soft_100,
  'orange.soft.400': base.orange_soft_400,
  'orange.soft.700': base.orange_soft_700,
  'orange.soft.1000': base.orange_soft_1000,
  'orange.soft.1300': base.orange_soft_1300,
  'orange.soft.1600': base.orange_soft_1600,
  'orange.soft.1900': base.orange_soft_1900,
  'orange.vivid.200': base.orange_vivid_200,
  'orange.vivid.500': base.orange_vivid_500,
  'orange.dark.moderate.500': base.orange_dark_moderate_500,
  'green.light.300': base.green_light_300,
  'green.light.500': base.green_light_500,
  'green.soft.500': base.green_soft_500,
  'green.dark.moderate.500': base.green_dark_moderate_500,
  'green.moderate.100': base.green_moderate_100,
  'green.moderate.400': base.green_moderate_400,
  'green.moderate.700': base.green_moderate_700,
  'green.moderate.1000': base.green_moderate_1000,
  'green.moderate.1300': base.green_moderate_1300,
  'green.strong.500': base.green_strong_500,
  'green.dark.500': base.green_dark_500,
  'green.soft.lime.300': base.green_soft_lime_300,
  'green.soft.lime.500': base.green_soft_lime_500,
  'green.soft.lime.700': base.green_soft_lime_700,
  'green.moderate.cyan.100': base.green_moderate_cyan_100,
  'green.moderate.cyan.500': base.green_moderate_cyan_500,
  'green.moderate.cyan.700': base.green_moderate_cyan_700,
  'green.moderate.cyan.900': base.green_moderate_cyan_900,
  'green.light.lime.300': base.green_light_lime_300,
  'green.light.lime.500': base.green_light_lime_500,
  'green.light.lime.700': base.green_light_lime_700,
  'green.dark.moderate.lime.500': base.green_dark_moderate_lime_500,
  'green.dark.moderate.lime.700': base.green_dark_moderate_lime_700,
  'green.desaturated.200': base.green_desaturated_200,
  'green.desaturated.500': base.green_desaturated_500,
  'pink.desaturated.200': base.pink_desaturated_200,
  'pink.desaturated.500': base.pink_desaturated_500,
  'pink.moderate.500': base.pink_moderate_500,
  'pink.moderate.700': base.pink_moderate_700,
  'pink.dark.500': base.pink_dark_500,
  'magenta.desaturated.500': base.magenta_desaturated_500,
  'magenta.desaturated.700': base.magenta_desaturated_700,
  'yellow.desaturated.100': base.yellow_desaturated_100,
  'yellow.desaturated.400': base.yellow_desaturated_400,
  'yellow.desaturated.700': base.yellow_desaturated_700,
  'yellow.desaturated.1000': base.yellow_desaturated_1000,
  'yellow.moderate.500': base.yellow_moderate_500,
  'yellow.dark.moderate.500': base.yellow_dark_moderate_500,
  'yellow.bright.300': base.yellow_bright_300,
  'yellow.bright.500': base.yellow_bright_500,
  'yellow.soft.300': base.yellow_soft_300,
  'yellow.soft.500': base.yellow_soft_500,
  'red.bright.100': base.red_bright_100,
  'red.bright.400': base.red_bright_400,
  'red.bright.700': base.red_bright_700,
  'red.bright.1000': base.red_bright_1000,
  'red.bright.1200': base.red_bright_1200,
  'red.pale.300': base.red_pale_300,
  'red.pale.500': base.red_pale_500,
  'red.soft.300': base.red_soft_300,
  'red.soft.500': base.red_soft_500,
  'red.soft.700': base.red_soft_700,
  'red.soft.900': base.red_soft_900,
  'yellow.pale.500': base.yellow_pale_500,
  'green.pale.500': base.green_pale_500,
  'blue.grayish.500': base.blue_grayish_500,
  'purple.moderate.100': base.purple_moderate_100,
  'purple.moderate.300': base.purple_moderate_300,
  'purple.moderate.400': base.purple_moderate_400,
  'purple.moderate.700': base.purple_moderate_700,
};

export default { ...availableColors, ...oldValues };
