/**
 * # Module
 *
 *
 */

import type { initializeApp, AppConfig } from '@searchmetrics/sm-discover';

// minimal subset reference to invoke the application
export interface SmDiscoverModule {
  initializeApp: typeof initializeApp;
}

export const DEFAULT_REMOTE_MODULE_VERSION = 'latest';

/**
 * Available supported versions of the remote module
 */
export type SmDiscoverModuleVersion = typeof DEFAULT_REMOTE_MODULE_VERSION | '1.0' | '0.1';

/**
 * Retrieve a remote module accessor and preload related styles
 *
 * @param version - [description]
 */
export function getSmDiscoverRemoteModule(
  //
  version: SmDiscoverModuleVersion = DEFAULT_REMOTE_MODULE_VERSION,
) {
  // @ts-expect-error -- while normally the index signature should be accessed using the bracket
  // notation in this case the value will be replaced after string pattern matching: 'process.env.*'
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- availability ensured
  const baseUrl = process.env.FREEFORM_FRONTEND_URL!;
  const productPath = `sm-discover/versions/${version}`;

  const stylesUrl = `${baseUrl}/${productPath}/dist-module/sm-discover.css`;
  const moduleUrl = `${baseUrl}/${productPath}/dist-module/sm-discover.esm.js`;

  loadStyles(stylesUrl).catch((error) => {
    throw error;
  });

  return getRemoteModule<SmDiscoverModule>(moduleUrl);
}

/**
 * Inmemory cache to access retrieved resources.
 */
const remoteModulesMap = new Map<string, Promise<unknown>>();

/**
 * Access a dynamically imported ECMAScript module
 *
 * @param moduleUrl - resource module URL
 */
function getRemoteModule<T>(moduleUrl: string) {
  if (remoteModulesMap.has(moduleUrl)) {
    return remoteModulesMap.get(moduleUrl) as Promise<T>;
  }

  // NOTE: while dynamic external loaded modules are natively available in Webpack 5
  // (https://webpack.js.org/blog/2020-10-10-webpack-5-release/#async-modules), this is not the case
  // for all bundling processes so the following line is replaced for now in favor of the custom
  // 'importModule' helper
  //
  // > const remoteModule = import(moduleUrl) as Promise<T>;

  const remoteModule = importModule(moduleUrl) as Promise<T>;

  remoteModulesMap.set(moduleUrl, remoteModule);

  return remoteModule;
}

/**
 *
 *
 * @param remoteModule - [description]
 * @param node         - HTML element used as the root node to mount the application
 * @param [config]     - [description]
 */
export async function initializeRemoteSmDiscoverApp(
  remoteModule: ReturnType<typeof getSmDiscoverRemoteModule>,
  node: Element,
  config?: AppConfig,
) {
  const module = await remoteModule;

  return module.initializeApp(node, config);
}

/**
 * Load an ECMAScript Module in the context of a browser (also supporting none 'module' scopes)
 *
 * Based on: https://github.com/tc39/proposal-dynamic-import#using-host-specific-mechanisms
 *
 * @param url - resource module URL
 */
export function importModule(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'module';
    script.id = url;

    const onceAddEventListenerOptions: AddEventListenerOptions = {
      once: true,
    };

    script.addEventListener(
      'load',
      () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access -- use temporary global reference
        const module = window[url as any] as unknown;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access -- cleanup temporary references
        delete window[url as any];
        script.remove();

        resolve(module);
      },
      onceAddEventListenerOptions,
    );

    script.addEventListener(
      'error',
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access -- cleanup temporary references
        delete window[url as any];
        script.remove();

        reject(error);
      },
      onceAddEventListenerOptions,
    );

    script.textContent = `
      import * as module from '${url}';
      window['${url}'] = module;

      const script = document.getElementById('${url}');
      const event = new Event('load');
      script.dispatchEvent(event);
    `;

    document.body.append(script);
  });
}

/**
 * Load a CSS stylesheet
 *
 * @param url - resource stylesheet URL
 */
export function loadStyles(url: string) {
  return new Promise<void>((resolve, reject) => {
    // styles already available
    if (document.querySelector(`link[href="${url}"]`)) {
      return resolve();
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;

    const onceAddEventListenerOptions: AddEventListenerOptions = {
      once: true,
    };

    link.addEventListener(
      'load',
      () => {
        resolve();
      },
      onceAddEventListenerOptions,
    );

    link.addEventListener(
      'error',
      (error) => {
        link.remove();

        reject(error);
      },
      onceAddEventListenerOptions,
    );

    document.body.append(link);
  });
}
