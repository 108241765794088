import * as React from 'react';
import classnames from 'classnames';

import Content from './Content';
import Trigger from './Trigger';
import styles from '../index.module.scss';
import { BasicTooltipProperties } from '../types';
import { StyledTooltip, StyledWrapper } from './Styled';
import useOutsideClickHandler from '../handleClickOutside';

/**
 * Basic implementation of the Tooltip component.
 *
 * @visibleName Tooltip.Basic
 * @since 0.5.0
 */

const BasicTooltip: React.FunctionComponent<BasicTooltipProperties> = ({
  animation,
  arrow,
  children,
  className,
  classNames,
  delay,
  disabled,
  id,
  position,
  style,
  testId,
  theme,
  trigger,
  triggerAction,
}) => {
  const [hidden, setHidden] = React.useState(true);
  const wrapperRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const triggerRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const closeTooltip = React.useCallback(() => {
    if (triggerAction !== 'click') {
      return;
    }
    setHidden(true);
  }, [triggerAction]);

  useOutsideClickHandler(id, closeTooltip, triggerAction);

  const applyTrigger = React.useCallback(
    (currentTrigger, newValue) => {
      if (disabled || triggerAction !== currentTrigger) {
        return;
      }
      // add focus to hovered trigger (must have by accessibility rules)
      if (hidden && currentTrigger === 'hover') {
        // without triggerRef && triggerRef.current TypeScript is throwing errors in build
        triggerRef && triggerRef.current && triggerRef.current.focus();
      }
      setHidden(newValue);
    },
    [disabled, hidden, triggerAction, triggerRef]
  );

  const handleKeyDown = React.useCallback(
    (e) => {
      // tooltip must close on "ESC" key press by accessibility rules, key code 27 is code for "ESC" key
      if (e.keyCode === 27) {
        applyTrigger(triggerAction, true);
      }
      // tooltip must open on "ENTER" key press by accessibility rules, key code 13 is code for "ENTER" key
      if (e.keyCode === 13 || e.code === 'Enter') {
        applyTrigger(triggerAction, false);
      }
    },
    [applyTrigger, triggerAction]
  );

  const applyTriggerHandler = React.useCallback(
    (currentTrigger, newValue) => () => {
      applyTrigger(currentTrigger, newValue);
      if (currentTrigger === 'hover' && newValue) {
        triggerRef && triggerRef.current && triggerRef.current.blur();
      }
    },
    [applyTrigger, triggerRef]
  );

  // Specify inner content of the tooltip
  const tooltipInner = React.useMemo(() => {
    let hasHeader = false;
    let hasFooter = false;
    if (typeof children === 'string') {
      return (
        <Content className={classNames?.content} theme={theme}>
          {children}
        </Content>
      );
    } else {
      // check does tooltip have footer and header, so content can style appropriately
      React.Children.forEach(children, (child) => {
        hasHeader = hasHeader || (child as any).type?.displayName?.startsWith('Tooltip.Header');
        hasFooter = hasFooter || (child as any).type?.displayName?.startsWith('Tooltip.Footer');
      });

      return React.Children.map(children, (child, index) =>
        React.cloneElement(child as React.ReactElement<any>, {
          theme,
          hasHeader,
          hasFooter,
          key: index,
          ...(child as React.ReactElement<any>).props,
        })
      );
    }
  }, [children, theme, classNames]);

  return (
    <StyledWrapper
      id={`${id}_wrapper`}
      className={classnames(styles.tooltip, className, {
        [styles['tooltip--active']]: !hidden,
      })}
      onClick={applyTriggerHandler('click', false)}
      onMouseEnter={applyTriggerHandler('hover', false)}
      onMouseLeave={applyTriggerHandler('hover', true)}
      onKeyDown={handleKeyDown}
      theme={theme}
      disabled={disabled}
      style={style}
      data-test-id={testId ? testId : undefined}
      ref={wrapperRef}
    >
      <Trigger
        triggerRef={triggerRef}
        id={`${id}_trigger`}
        hidden={hidden}
        disabled={disabled}
        className={classnames(classNames?.trigger, {
          [`${className}__trigger`]: className,
        })}
      >
        {trigger}
      </Trigger>
      <StyledTooltip
        id={id}
        theme={theme}
        role="tooltip"
        delay={delay}
        animation={animation}
        aria-hidden={hidden}
        className={classnames(styles['tooltip__inner'], classNames?.contentWrapper, {
          [`${className}__tooltip`]: className,
          [styles['tooltip__inner--arrow']]: arrow,
          [styles[`${position}`]]: !!position,
        })}
      >
        {tooltipInner}
      </StyledTooltip>
    </StyledWrapper>
  );
};

BasicTooltip.displayName = 'Tooltip.Basic';
export default BasicTooltip;
