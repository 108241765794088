import * as React from 'react';
import classnames from 'classnames';
import checkValue from '../../../../styles/checkValue';
import BaseIcon, { BaseIconType } from './Base';

type FlagProps = Omit<BaseIconType, 'icon' | 'flag'> & {
  /**
   * Flag design token value
   */
  countryCode: string;
};

/**
 * Component for displaying flags.
 *
 * @component
 * @visibleName Icon.Flag
 */
const Flag: React.FunctionComponent<FlagProps> = ({
  className,
  countryCode,
  id,
  testId,
  ...other
}: FlagProps) => (
  <BaseIcon
    {...other}
    id={id}
    testId={testId}
    className={classnames(checkValue(countryCode, 'flags'), className)}
    flag
  />
);

Flag.displayName = 'Icon.Flag';
export default Flag;
