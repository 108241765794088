import * as React from 'react';
import { defaultTheme, defaultClientTheme } from './theme';
import { ThemeType, ClientThemeType } from './types';

export interface ThemeContextType {
  theme?: ThemeType | ClientThemeType;
  toggleTheme?: Function;
}

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: { ...defaultTheme, ...defaultClientTheme },
  toggleTheme: () => {
    /* do nothing */
  },
});
