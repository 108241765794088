import * as React from 'react';
import {
  ClientThemeType,
  ThemeClient,
  Palette,
  ColorDefinition,
  withTheme,
} from '../../../../theme/index';
import { printCoreWarning } from '../../../shared';
import Basic from './Basic';
import { TooltipThemeType, ThemedTooltipProperties, TooltipThemeOverwriteType } from '../types';

/**
 * Function that mappes client passed theme to our component's theme.
 * Used to limit impacts of future changes to client theme to our Tooltip component.
 */
const themeMapper = (
  theme: ThemeClient = {},
  {
    palette = Palette.brand,
    variant,
    themeOverwrite,
    styleTooltip,
  }: {
    palette?: Palette;
    variant: string;
    themeOverwrite?: TooltipThemeOverwriteType;
    styleTooltip: boolean;
  }
): TooltipThemeType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (styleTooltip) {
    printCoreWarning(
      `Tooltip.Themed: you are using a design not pre-approved as default. Check with your designer if this is alright.`
    );
  }

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Tooltip.Themed component must pass a theme. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    if (variant === 'filled') {
      return getFilledStyle(selectedPalette, themeOverwrite, styleTooltip);
    }
    if (variant === 'outlined') {
      if (!styleTooltip) {
        printCoreWarning(
          `Tooltip.Themed - you have selected the outlined variant without setting the "styleTooltip" property. This will not make outline style visible, are you sure this is what you were going to do?`
        );
      }
      return getOutlinedStyle(selectedPalette, themeOverwrite, styleTooltip);
    }
    printCoreWarning(
      `Unrecognized variant passed to Tooltip.Themed component. Your theme is ignored.`
    );
    return undefined;
  }

  return undefined;
};

const ThemedTooltip: React.FunctionComponent<ThemedTooltipProperties> = ({
  theme,
  palette = Palette.brand,
  variant = 'filled',
  themeOverwrite,
  styleTooltip,
  ...otherProps
}: ThemedTooltipProperties) => {
  const mappedTheme = themeMapper(theme || {}, {
    palette,
    variant,
    themeOverwrite,
    styleTooltip: styleTooltip || false,
  });
  return <Basic theme={mappedTheme} {...otherProps} />;
};

const getFilledStyle = (
  palette: ColorDefinition,
  themeOverwrite?: TooltipThemeOverwriteType,
  styleTooltip?: boolean
) => {
  // TODO ADD MEMOIZATION !
  return {
    trigger: {
      default: {
        borderColor: themeOverwrite?.trigger?.default?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.default?.backgroundColor || 'transparent',
        color: themeOverwrite?.trigger?.default?.color || undefined,
        fontSize: themeOverwrite?.trigger?.default?.fontSize || undefined,
      },
      active: {
        borderColor: themeOverwrite?.trigger?.active?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.active?.backgroundColor || 'transparent',
        color: themeOverwrite?.trigger?.active?.color || palette.light,
        fontSize: themeOverwrite?.trigger?.active?.fontSize || undefined,
      },
      disabled: {
        color: themeOverwrite?.trigger?.disabled?.color || palette.lighter,
        borderColor: themeOverwrite?.trigger?.disabled?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.disabled?.backgroundColor || 'transparent',
        fontSize: themeOverwrite?.trigger?.disabled?.fontSize || undefined,
      },
      hover: {
        color: themeOverwrite?.trigger?.hover?.color || palette.light,
        borderColor: themeOverwrite?.trigger?.hover?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.hover?.backgroundColor || 'transparent',
        fontSize: themeOverwrite?.trigger?.hover?.fontSize || undefined,
      },
    },
    wrapper: {
      backgroundColor:
        themeOverwrite?.wrapper?.backgroundColor || (styleTooltip ? palette.main : undefined),
      borderColor:
        themeOverwrite?.wrapper?.borderColor || (styleTooltip ? palette.main : undefined),
      color: themeOverwrite?.wrapper?.color || (styleTooltip ? palette.contrast : undefined),
      fontSize: themeOverwrite?.wrapper?.fontSize || undefined,
    },
    header: {
      backgroundColor:
        themeOverwrite?.header?.backgroundColor || (styleTooltip ? palette.main : undefined),
      borderColor: themeOverwrite?.header?.borderColor || 'transparent',
      color: themeOverwrite?.header?.color || (styleTooltip ? palette.contrast : undefined),
      fontSize: themeOverwrite?.header?.fontSize || undefined,
    },
    content: {
      backgroundColor:
        themeOverwrite?.content?.backgroundColor || (styleTooltip ? palette.main : undefined),
      color: themeOverwrite?.content?.color || (styleTooltip ? palette.contrast : undefined),
      fontSize: themeOverwrite?.content?.fontSize || undefined,
    },
    footer: {
      backgroundColor: themeOverwrite?.footer?.backgroundColor || undefined,
      borderColor: themeOverwrite?.footer?.borderColor || undefined,
      color: themeOverwrite?.footer?.color || undefined,
      fontSize: themeOverwrite?.footer?.fontSize || undefined,
    },
  };
};

const getOutlinedStyle = (
  palette: ColorDefinition,
  themeOverwrite?: TooltipThemeOverwriteType,
  styleTooltip?: boolean
) => {
  // TODO ADD MEMOIZATION !
  return {
    trigger: {
      default: {
        borderColor: themeOverwrite?.trigger?.default?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.default?.backgroundColor || 'transparent',
        color: themeOverwrite?.trigger?.default?.color || undefined,
        fontSize: themeOverwrite?.trigger?.default?.fontSize || undefined,
      },
      active: {
        borderColor: themeOverwrite?.trigger?.active?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.active?.backgroundColor || 'transparent',
        color: themeOverwrite?.trigger?.active?.color || palette.light,
        fontSize: themeOverwrite?.trigger?.active?.fontSize || undefined,
      },
      disabled: {
        color: themeOverwrite?.trigger?.disabled?.color || palette.lighter,
        borderColor: themeOverwrite?.trigger?.disabled?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.disabled?.backgroundColor || 'transparent',
        fontSize: themeOverwrite?.trigger?.disabled?.fontSize || undefined,
      },
      hover: {
        color: themeOverwrite?.trigger?.hover?.color || palette.light,
        borderColor: themeOverwrite?.trigger?.hover?.borderColor || 'transparent',
        backgroundColor: themeOverwrite?.trigger?.hover?.backgroundColor || 'transparent',
        fontSize: themeOverwrite?.trigger?.hover?.fontSize || undefined,
      },
    },
    wrapper: {
      backgroundColor:
        themeOverwrite?.wrapper?.backgroundColor || (styleTooltip ? palette.contrast : undefined),
      borderColor:
        themeOverwrite?.wrapper?.borderColor || (styleTooltip ? palette.main : undefined),
      color: themeOverwrite?.wrapper?.color || (styleTooltip ? palette.main : undefined),
      fontSize: themeOverwrite?.wrapper?.fontSize || undefined,
    },
    header: {
      backgroundColor:
        themeOverwrite?.header?.backgroundColor || (styleTooltip ? palette.contrast : undefined),
      borderColor: themeOverwrite?.header?.borderColor || (styleTooltip ? palette.main : undefined),
      color: themeOverwrite?.header?.color || (styleTooltip ? palette.main : undefined),
      fontSize: themeOverwrite?.header?.fontSize || undefined,
    },
    content: {
      backgroundColor:
        themeOverwrite?.content?.backgroundColor || (styleTooltip ? palette.contrast : undefined),
      color: themeOverwrite?.content?.color || (styleTooltip ? palette.main : undefined),
      fontSize: themeOverwrite?.content?.fontSize || undefined,
    },
    footer: {
      backgroundColor:
        themeOverwrite?.footer?.backgroundColor || (styleTooltip ? palette.contrast : undefined),
      borderColor: themeOverwrite?.footer?.borderColor || (styleTooltip ? palette.main : undefined),
      color: themeOverwrite?.footer?.color || (styleTooltip ? palette.main : undefined),
      fontSize: themeOverwrite?.footer?.fontSize || undefined,
    },
  };
};

/**
 * The tooltip themed component is an implementation of the Tooltip component that understands how to handle
 * colors of the certain palette. Using the `themeOverwrite` property you can overwrite color, background color, font size and the border
 * color of each section of the tooltip. In addition, you can change default, active, hover and disabled states of the trigger element.
 * @component
 * @visibleName Tooltip.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedTooltipProperties> & {
  mapper?: (
    theme: ThemeClient,
    params: {
      palette?: Palette;
      variant: string;
      themeOverwrite?: TooltipThemeOverwriteType;
      styleTooltip: boolean;
    }
  ) => TooltipThemeType | undefined;
} = withTheme(ThemedTooltip);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'Tooltip.Themed';
export default ThemedExport;
