import * as React from 'react';
import styled from 'styled-components';
import checkValue from '../../../styles/checkValue';
import styles from './index.module.scss';
import { ReactComponent as Svg } from './separator.svg';

enum SeparatorStyle {
  solid = 'solid',
  dashed = 'dashed',
  double = 'double',
  groove = 'groove',
  ridge = 'ridge',
}

export enum YPosition {
  top = '-100%',
  middle = '50%',
  bottom = '100%',
}

export enum XPosition {
  left = '0',
  middle = '50%',
  right = '0%',
}

type SeparatorProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Used to define label style, width and color.<br/>
   * Corresponding to border-bottom applied style.
   */
  line?: {
    style: SeparatorStyle;
    width?: string;
    color?: string;
  };
  /**
   * Text placed relative to our border. Usually shows some additional information.<br/>
   * Is rendered as a **:before** pseudo element.
   */
  text?: {
    backgroundColor?: string;
    color?: string;
    value?: string;
    position?: [XPosition, YPosition]; // (x,y)
  };
  width?: string;

  /**
   * Id of the wrapper element.
   */
  id?: string;

  /**
   * Passed as value to "data-test-id" attribute.
   */
  testId?: string;
};

const defaultProps: SeparatorProps = {
  line: {
    style: SeparatorStyle.solid,
  },
  width: '100%',
};

const calculateStyleFromPosition = (x: XPosition, y: YPosition) => {
  return `
    ${x === XPosition.left ? 'left' : 'right'}: ${x};
    ${y === YPosition.top ? 'bottom' : 'top'}: ${y};
    transform: translate(${x === XPosition.middle ? '50%' : '0'},
                         ${y === YPosition.bottom ? '100%' : '0'});
  `;
};

/**
 * A styled div that's going to be creating our Separator component.
 * Displays all applicable styling.
 * @component
 */
const StyledSeparator = styled.div<SeparatorProps>`
  color: ${(props: SeparatorProps) => checkValue(props.color, 'colors')};
  width: ${(props: SeparatorProps) => props.width};
  border-bottom-style: ${(props: SeparatorProps) => props.line?.style || defaultProps.line?.style};
  border-bottom-width: ${(props: SeparatorProps) => props.line?.width || defaultProps.line?.width};
  border-bottom-color: ${(props: SeparatorProps) => props.line?.color || defaultProps.line?.color};
  ${(props: SeparatorProps) => {
    if (props.text) {
      return `
      position: relative;
      &:before {
        font-size: ${props.line?.width || defaultProps.line?.width};
        content: '${props.text?.value}';
        background-color: ${checkValue(props.text.backgroundColor, 'colors')};
        ${
          props.text?.position
            ? calculateStyleFromPosition(props.text.position[0], props.text.position[1])
            : ''
        }
      }
      `;
    }
    return '';
  }}
`;

/**
 * A **horizontal divider** that separates and distinguishes section of content or groups of menuitems.
 * Implemented using a basic div element because the [hr element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/hr)
 * is defined in semantic terms.
 * In addition, the hr element has some issues with coloring and it is recommended to use other elements.
 * This div implements the separator as bottom border.
 * This is also a **static separator** as it's not focusable.
 *
 */
const Separator: React.FunctionComponent<SeparatorProps> & {
  svg: React.FunctionComponent;
} = ({ className, id, testId, ...props }: SeparatorProps) => (
  <StyledSeparator
    aria-orientation="horizontal"
    role="separator"
    tabIndex={-1}
    id={id}
    data-test-id={testId ? testId : undefined}
    className={`${styles.separator} ${className}`}
    {...props}
  />
);

Separator.displayName = 'Separator';
Separator.svg = Svg;

export default Separator;
