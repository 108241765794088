import base from '../index.scss';

export type FlagsType = {
  [key: string]: string;
};

const flags: FlagsType = {
  'flag.ad': base.flag_ad,
  'flag.ae': base.flag_ae,
  'flag.af': base.flag_af,
  'flag.ag': base.flag_ag,
  'flag.ai': base.flag_ai,
  'flag.al': base.flag_al,
  'flag.am': base.flag_am,
  'flag.ao': base.flag_ao,
  'flag.aq': base.flag_aq,
  'flag.ar': base.flag_ar,
  'flag.as': base.flag_as,
  'flag.at': base.flag_at,
  'flag.au': base.flag_au,
  'flag.aw': base.flag_aw,
  'flag.ax': base.flag_ax,
  'flag.az': base.flag_az,
  'flag.ba': base.flag_ba,
  'flag.bb': base.flag_bb,
  'flag.bd': base.flag_bd,
  'flag.be': base.flag_be,
  'flag.bf': base.flag_bf,
  'flag.bg': base.flag_bg,
  'flag.bh': base.flag_bh,
  'flag.bi': base.flag_bi,
  'flag.bj': base.flag_bj,
  'flag.bl': base.flag_bl,
  'flag.bm': base.flag_bm,
  'flag.bn': base.flag_bn,
  'flag.bo': base.flag_bo,
  'flag.br': base.flag_br,
  'flag.bs': base.flag_bs,
  'flag.bt': base.flag_bt,
  'flag.bv': base.flag_bv,
  'flag.bw': base.flag_bw,
  'flag.by': base.flag_by,
  'flag.bz': base.flag_bz,
  'flag.ca': base.flag_ca,
  'flag.cc': base.flag_cc,
  'flag.cd': base.flag_cd,
  'flag.cf': base.flag_cf,
  'flag.cg': base.flag_cg,
  'flag.ch': base.flag_ch,
  'flag.ci': base.flag_ci,
  'flag.ck': base.flag_ck,
  'flag.cl': base.flag_cl,
  'flag.cm': base.flag_cm,
  'flag.cn': base.flag_cn,
  'flag.co': base.flag_co,
  'flag.cr': base.flag_cr,
  'flag.cu': base.flag_cu,
  'flag.cv': base.flag_cv,
  'flag.cw': base.flag_cw,
  'flag.cx': base.flag_cx,
  'flag.cy': base.flag_cy,
  'flag.cz': base.flag_cz,
  'flag.de': base.flag_de,
  'flag.dj': base.flag_dj,
  'flag.dk': base.flag_dk,
  'flag.dm': base.flag_dm,
  'flag.do': base.flag_do,
  'flag.dz': base.flag_dz,
  'flag.ec': base.flag_ec,
  'flag.ee': base.flag_ee,
  'flag.eg': base.flag_eg,
  'flag.er': base.flag_er,
  'flag.es': base.flag_es,
  'flag.et': base.flag_et,
  'flag.eu': base.flag_eu,
  'flag.fi': base.flag_fi,
  'flag.fj': base.flag_fj,
  'flag.fk': base.flag_fk,
  'flag.fm': base.flag_fm,
  'flag.fo': base.flag_fo,
  'flag.fr': base.flag_fr,
  'flag.ga': base.flag_ga,
  'flag.gb': base.flag_gb,
  'flag.gd': base.flag_gd,
  'flag.ge': base.flag_ge,
  'flag.gf': base.flag_gf,
  'flag.gg': base.flag_gg,
  'flag.gh': base.flag_gh,
  'flag.gi': base.flag_gi,
  'flag.gl': base.flag_gl,
  'flag.gm': base.flag_gm,
  'flag.gn': base.flag_gn,
  'flag.gp': base.flag_gp,
  'flag.gq': base.flag_gq,
  'flag.gr': base.flag_gr,
  'flag.gs': base.flag_gs,
  'flag.gt': base.flag_gt,
  'flag.gu': base.flag_gu,
  'flag.gw': base.flag_gw,
  'flag.gy': base.flag_gy,
  'flag.hk': base.flag_hk,
  'flag.hm': base.flag_hm,
  'flag.hn': base.flag_hn,
  'flag.hr': base.flag_hr,
  'flag.ht': base.flag_ht,
  'flag.hu': base.flag_hu,
  'flag.id': base.flag_id,
  'flag.ie': base.flag_ie,
  'flag.il': base.flag_il,
  'flag.im': base.flag_im,
  'flag.in': base.flag_in,
  'flag.io': base.flag_io,
  'flag.iq': base.flag_iq,
  'flag.ir': base.flag_ir,
  'flag.is': base.flag_is,
  'flag.it': base.flag_it,
  'flag.je': base.flag_je,
  'flag.jm': base.flag_jm,
  'flag.jo': base.flag_jo,
  'flag.jp': base.flag_jp,
  'flag.ke': base.flag_ke,
  'flag.kg': base.flag_kg,
  'flag.kh': base.flag_kh,
  'flag.ki': base.flag_ki,
  'flag.km': base.flag_km,
  'flag.kn': base.flag_kn,
  'flag.kp': base.flag_kp,
  'flag.kr': base.flag_kr,
  'flag.kw': base.flag_kw,
  'flag.ky': base.flag_ky,
  'flag.kz': base.flag_kz,
  'flag.la': base.flag_la,
  'flag.lb': base.flag_lb,
  'flag.lc': base.flag_lc,
  'flag.li': base.flag_li,
  'flag.lk': base.flag_lk,
  'flag.lr': base.flag_lr,
  'flag.ls': base.flag_ls,
  'flag.lt': base.flag_lt,
  'flag.lu': base.flag_lu,
  'flag.lv': base.flag_lv,
  'flag.ly': base.flag_ly,
  'flag.ma': base.flag_ma,
  'flag.mc': base.flag_mc,
  'flag.md': base.flag_md,
  'flag.me': base.flag_me,
  'flag.mf': base.flag_mf,
  'flag.mg': base.flag_mg,
  'flag.mh': base.flag_mh,
  'flag.mk': base.flag_mk,
  'flag.ml': base.flag_ml,
  'flag.mm': base.flag_mm,
  'flag.mn': base.flag_mn,
  'flag.mo': base.flag_mo,
  'flag.mp': base.flag_mp,
  'flag.mq': base.flag_mq,
  'flag.mr': base.flag_mr,
  'flag.ms': base.flag_ms,
  'flag.mt': base.flag_mt,
  'flag.mu': base.flag_mu,
  'flag.mv': base.flag_mv,
  'flag.mw': base.flag_mw,
  'flag.mx': base.flag_mx,
  'flag.my': base.flag_my,
  'flag.mz': base.flag_mz,
  'flag.na': base.flag_na,
  'flag.nc': base.flag_nc,
  'flag.ne': base.flag_ne,
  'flag.nf': base.flag_nf,
  'flag.ng': base.flag_ng,
  'flag.ni': base.flag_ni,
  'flag.nl': base.flag_nl,
  'flag.no': base.flag_no,
  'flag.np': base.flag_np,
  'flag.nr': base.flag_nr,
  'flag.nu': base.flag_nu,
  'flag.nz': base.flag_nz,
  'flag.om': base.flag_om,
  'flag.pa': base.flag_pa,
  'flag.pe': base.flag_pe,
  'flag.pf': base.flag_pf,
  'flag.pg': base.flag_pg,
  'flag.ph': base.flag_ph,
  'flag.pk': base.flag_pk,
  'flag.pl': base.flag_pl,
  'flag.pm': base.flag_pm,
  'flag.pn': base.flag_pn,
  'flag.po': base.flag_po,
  'flag.pr': base.flag_pr,
  'flag.ps': base.flag_ps,
  'flag.pt': base.flag_pt,
  'flag.pw': base.flag_pw,
  'flag.py': base.flag_py,
  'flag.qa': base.flag_qa,
  'flag.re': base.flag_re,
  'flag.ro': base.flag_ro,
  'flag.rs': base.flag_rs,
  'flag.ru': base.flag_ru,
  'flag.rw': base.flag_rw,
  'flag.sa': base.flag_sa,
  'flag.sb': base.flag_sb,
  'flag.sc': base.flag_sc,
  'flag.sd': base.flag_sd,
  'flag.se': base.flag_se,
  'flag.sg': base.flag_sg,
  'flag.sh': base.flag_sh,
  'flag.si': base.flag_si,
  'flag.sj': base.flag_sj,
  'flag.sk': base.flag_sk,
  'flag.sl': base.flag_sl,
  'flag.sm': base.flag_sm,
  'flag.sn': base.flag_sn,
  'flag.so': base.flag_so,
  'flag.sr': base.flag_sr,
  'flag.ss': base.flag_ss,
  'flag.st': base.flag_st,
  'flag.sv': base.flag_sv,
  'flag.sx': base.flag_sx,
  'flag.sy': base.flag_sy,
  'flag.sz': base.flag_sz,
  'flag.tc': base.flag_tc,
  'flag.td': base.flag_td,
  'flag.tf': base.flag_tf,
  'flag.tg': base.flag_tg,
  'flag.th': base.flag_th,
  'flag.tj': base.flag_tj,
  'flag.tk': base.flag_tk,
  'flag.tl': base.flag_tl,
  'flag.tm': base.flag_tm,
  'flag.tn': base.flag_tn,
  'flag.to': base.flag_to,
  'flag.tr': base.flag_tr,
  'flag.tt': base.flag_tt,
  'flag.tv': base.flag_tv,
  'flag.tw': base.flag_tw,
  'flag.tz': base.flag_tz,
  'flag.ua': base.flag_ua,
  'flag.ug': base.flag_ug,
  'flag.uk': base.flag_uk,
  'flag.um': base.flag_um,
  'flag.us': base.flag_us,
  'flag.uy': base.flag_uy,
  'flag.uz': base.flag_uz,
  'flag.va': base.flag_va,
  'flag.vc': base.flag_vc,
  'flag.ve': base.flag_ve,
  'flag.vg': base.flag_vg,
  'flag.vi': base.flag_vi,
  'flag.vn': base.flag_vn,
  'flag.vu': base.flag_vu,
  'flag.wf': base.flag_wf,
  'flag.ws': base.flag_ws,
  'flag.ye': base.flag_ye,
  'flag.yt': base.flag_yt,
  'flag.za': base.flag_za,
  'flag.zm': base.flag_zm,
  'flag.zw': base.flag_zw,
  'flag.world': base.flag_world,
};

// Is not autogenerated, do not remove
const old = {
  'flag.ad.old': '',
  'flag.ae.old': '',
  'flag.af.old': '',
  'flag.ag.old': '',
  'flag.ai.old': '',
  'flag.al.old': '',
  'flag.am.old': '',
  'flag.ao.old': '',
  'flag.aq.old': '',
  'flag.ar.old': '',
  'flag.as.old': '',
  'flag.at.old': '',
  'flag.au.old': '',
  'flag.aw.old': '',
  'flag.ax.old': '',
  'flag.az.old': '',
  'flag.ba.old': '',
  'flag.bb.old': '',
  'flag.bd.old': '',
  'flag.be.old': '',
  'flag.bf.old': '',
  'flag.bg.old': '',
  'flag.bh.old': '',
  'flag.bi.old': '',
  'flag.bj.old': '',
  'flag.bl.old': '',
  'flag.bm.old': '',
  'flag.bn.old': '',
  'flag.bo.old': '',
  'flag.br.old': '',
  'flag.bs.old': '',
  'flag.bt.old': '',
  'flag.bv.old': '',
  'flag.bw.old': '',
  'flag.by.old': '',
  'flag.bz.old': '',
  'flag.ca.old': '',
  'flag.cc.old': '',
  'flag.cd.old': '',
  'flag.cf.old': '',
  'flag.cg.old': '',
  'flag.ch.old': '',
  'flag.ci.old': '',
  'flag.ck.old': '',
  'flag.cl.old': '',
  'flag.cm.old': '',
  'flag.cn.old': '',
  'flag.co.old': '',
  'flag.cr.old': '',
  'flag.cu.old': '',
  'flag.cv.old': '',
  'flag.cw.old': '',
  'flag.cx.old': '',
  'flag.cy.old': '',
  'flag.cz.old': '',
  'flag.de.old': '',
  'flag.dj.old': '',
  'flag.dk.old': '',
  'flag.dm.old': '',
  'flag.do.old': '',
  'flag.dz.old': '',
  'flag.ec.old': '',
  'flag.ee.old': '',
  'flag.eg.old': '',
  'flag.er.old': '',
  'flag.es.old': '',
  'flag.et.old': '',
  'flag.eu.old': '',
  'flag.fi.old': '',
  'flag.fj.old': '',
  'flag.fk.old': '',
  'flag.fm.old': '',
  'flag.fo.old': '',
  'flag.fr.old': '',
  'flag.ga.old': '',
  'flag.gb.old': '',
  'flag.gd.old': '',
  'flag.ge.old': '',
  'flag.gf.old': '',
  'flag.gg.old': '',
  'flag.gh.old': '',
  'flag.gi.old': '',
  'flag.gl.old': '',
  'flag.gm.old': '',
  'flag.gn.old': '',
  'flag.gp.old': '',
  'flag.gq.old': '',
  'flag.gr.old': '',
  'flag.gs.old': '',
  'flag.gt.old': '',
  'flag.gu.old': '',
  'flag.gw.old': '',
  'flag.gy.old': '',
  'flag.hk.old': '',
  'flag.hm.old': '',
  'flag.hn.old': '',
  'flag.hr.old': '',
  'flag.ht.old': '',
  'flag.hu.old': '',
  'flag.id.old': '',
  'flag.ie.old': '',
  'flag.il.old': '',
  'flag.im.old': '',
  'flag.in.old': '',
  'flag.io.old': '',
  'flag.iq.old': '',
  'flag.ir.old': '',
  'flag.is.old': '',
  'flag.it.old': '',
  'flag.je.old': '',
  'flag.jm.old': '',
  'flag.jo.old': '',
  'flag.jp.old': '',
  'flag.ke.old': '',
  'flag.kg.old': '',
  'flag.kh.old': '',
  'flag.ki.old': '',
  'flag.km.old': '',
  'flag.kn.old': '',
  'flag.kp.old': '',
  'flag.kr.old': '',
  'flag.kw.old': '',
  'flag.ky.old': '',
  'flag.kz.old': '',
  'flag.la.old': '',
  'flag.lb.old': '',
  'flag.lc.old': '',
  'flag.li.old': '',
  'flag.lk.old': '',
  'flag.lr.old': '',
  'flag.ls.old': '',
  'flag.lt.old': '',
  'flag.lu.old': '',
  'flag.lv.old': '',
  'flag.ly.old': '',
  'flag.ma.old': '',
  'flag.mc.old': '',
  'flag.md.old': '',
  'flag.me.old': '',
  'flag.mf.old': '',
  'flag.mg.old': '',
  'flag.mh.old': '',
  'flag.mk.old': '',
  'flag.ml.old': '',
  'flag.mm.old': '',
  'flag.mn.old': '',
  'flag.mo.old': '',
  'flag.mp.old': '',
  'flag.mq.old': '',
  'flag.mr.old': '',
  'flag.ms.old': '',
  'flag.mt.old': '',
  'flag.mu.old': '',
  'flag.mv.old': '',
  'flag.mw.old': '',
  'flag.mx.old': '',
  'flag.my.old': '',
  'flag.mz.old': '',
  'flag.na.old': '',
  'flag.nc.old': '',
  'flag.ne.old': '',
  'flag.nf.old': '',
  'flag.ng.old': '',
  'flag.ni.old': '',
  'flag.nl.old': '',
  'flag.no.old': '',
  'flag.np.old': '',
  'flag.nr.old': '',
  'flag.nu.old': '',
  'flag.nz.old': '',
  'flag.om.old': '',
  'flag.pa.old': '',
  'flag.pe.old': '',
  'flag.pf.old': '',
  'flag.pg.old': '',
  'flag.ph.old': '',
  'flag.pk.old': '',
  'flag.pl.old': '',
  'flag.pm.old': '',
  'flag.pn.old': '',
  'flag.po.old': '',
  'flag.pr.old': '',
  'flag.ps.old': '',
  'flag.pt.old': '',
  'flag.pw.old': '',
  'flag.py.old': '',
  'flag.qa.old': '',
  'flag.re.old': '',
  'flag.ro.old': '',
  'flag.rs.old': '',
  'flag.ru.old': '',
  'flag.rw.old': '',
  'flag.sa.old': '',
  'flag.sb.old': '',
  'flag.sc.old': '',
  'flag.sd.old': '',
  'flag.se.old': '',
  'flag.sg.old': '',
  'flag.sh.old': '',
  'flag.si.old': '',
  'flag.sj.old': '',
  'flag.sk.old': '',
  'flag.sl.old': '',
  'flag.sm.old': '',
  'flag.sn.old': '',
  'flag.so.old': '',
  'flag.sr.old': '',
  'flag.ss.old': '',
  'flag.st.old': '',
  'flag.sv.old': '',
  'flag.sx.old': '',
  'flag.sy.old': '',
  'flag.sz.old': '',
  'flag.tc.old': '',
  'flag.td.old': '',
  'flag.tf.old': '',
  'flag.tg.old': '',
  'flag.th.old': '',
  'flag.tj.old': '',
  'flag.tk.old': '',
  'flag.tl.old': '',
  'flag.tm.old': '',
  'flag.tn.old': '',
  'flag.to.old': '',
  'flag.tr.old': '',
  'flag.tt.old': '',
  'flag.tv.old': '',
  'flag.tw.old': '',
  'flag.tz.old': '',
  'flag.ua.old': '',
  'flag.ug.old': '',
  'flag.uk.old': '',
  'flag.um.old': '',
  'flag.us.old': '',
  'flag.uy.old': '',
  'flag.uz.old': '',
  'flag.va.old': '',
  'flag.vc.old': '',
  'flag.ve.old': '',
  'flag.vg.old': '',
  'flag.vi.old': '',
  'flag.vn.old': '',
  'flag.vu.old': '',
  'flag.wf.old': '',
  'flag.ws.old': '',
  'flag.ye.old': '',
  'flag.yt.old': '',
  'flag.za.old': '',
  'flag.zm.old': '',
  'flag.zw.old': '',
  'flag.world.old': '',
};

export default { ...flags, ...old };
