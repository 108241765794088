import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import styles from '../index.module.scss';
import { BarLoaderProperties, ThemedLoaderProperties, LoaderThemeMapperType } from '../types';
import checkValue from '../../../../styles/checkValue';
import Flex from '../../../Layout/Flex';
import Themed from './Themed';

const Wrapper = styled.div<BarLoaderProperties>`
  overflow: hidden !important;
  position: absolute;
  background: ${({ color }: BarLoaderProperties) => checkValue(color, 'colors')};
  ${({ position, thickness }: BarLoaderProperties) => {
    if (['left', 'right'].includes(position || '')) {
      return `
        height: 100%;
        width: ${thickness};
        ${position}: -1px;
        top: 0;
      `;
    }
    return `
      width: 100%;
      height: ${thickness};
      ${position}: -1px;
      left: 0;
    `;
  }}
`;

const Bar = styled.div<BarLoaderProperties>`
  box-sizing: border-box;
  ${({ position, thickness, size, emptyColor }: BarLoaderProperties) => {
    if (['left', 'right'].includes(position || '')) {
      return `
        height: 100%;
        width: ${thickness};
        border-top: ${size} solid ${checkValue(emptyColor)};
        ${position}: -1px;
      `;
    }
    return `
      width: 100%;
      height: ${thickness};
      border-left: ${size} solid ${emptyColor};
      ${position}: -1px;
    `;
  }}
`;

const StyledFlex = styled(Flex)<BarLoaderProperties>`
  position: relative;
  ${(props: BarLoaderProperties) => `min-width: ${props.size}`};
  ${(props: BarLoaderProperties) => {
    if (['top', 'bottom'].includes(props.position || Bar.defaultProps?.position || '')) {
      return `
       min-height: ${props.thickness};
      `;
    }
    return '';
  }}
`;

/**
 * Basic implementation of the Loader.Bar component.
 * @visibleName Loader.Bar
 * @since 0.3.0
 */

const BasicBarLoader: React.FunctionComponent<BarLoaderProperties> & {
  Themed: React.FunctionComponent<ThemedLoaderProperties & { Component: any }> & {
    mapper?: LoaderThemeMapperType;
  };
} = ({
  alignItems,
  thickness,
  centered,
  children,
  className,
  color,
  direction,
  flexBasis,
  justifyContent,
  loaderClassName,
  position,
  size,
  style,
  theme,
  wrap,
  emptyColor,
  invert,
  testId,
  id,
}) => {
  return (
    <StyledFlex
      alignItems={alignItems}
      thickness={thickness}
      className={classnames(className, {
        [styles.centered]: centered,
      })}
      direction={direction}
      flexBasis={flexBasis}
      justifyContent={justifyContent}
      position={position}
      size={size}
      style={style}
      wrap={wrap}
      id={id}
      data-test-id={testId ? testId : undefined}
    >
      {invert && children}
      <Wrapper
        className={classnames(styles.bar__wrapper, loaderClassName)}
        position={position}
        thickness={thickness}
        size={size}
        color={color || theme?.color}
      >
        <Bar
          thickness={thickness}
          position={position}
          size={size}
          emptyColor={emptyColor}
          className={classnames(styles.translate, {
            [styles.vertical]: ['left', 'right'].includes(position || ''),
          })}
        />
      </Wrapper>
      {!invert && children}
    </StyledFlex>
  );
};

BasicBarLoader.displayName = 'Loader.Bar';
BasicBarLoader.Themed = (props) => <Themed {...props} Component={BasicBarLoader} />;
BasicBarLoader.Themed.displayName = 'Loader.Bar.Themed';

BasicBarLoader.defaultProps = {
  size: '10px',
  position: 'top',

  thickness: '4px',
  emptyColor: '#fff',
};
export default BasicBarLoader;
