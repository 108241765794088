import * as React from 'react';
import Button from '../../../General/Button';
import Typography from '../../../General/Typography';
import Icon from '../../../General/Icon';
import Flex from '../../../Layout/Flex';
import { MenuButtonProperties } from '../types';
import styles from '../index.module.scss';

/**
 * Basic implementation of the menu button component - the button responsible for triggering
 * visibility of the menu list. This is used by default and is passed the content of the Menu component's `menuLabelContent` property. For simplest use, pass `label` prop with a string that will be rendered with default dropdown icon. No need to pass icon prop unless you want something other than `triangle-bottom`.
 *
 * If you need to, you can have more control in how properties are passed, and can pass this component
 * as a child to the Menu component. In such case, you do not need the label, but take into account that
 * some properties might be overwritten by the Menu component (such as the onClick handling).
 *
 * @visibleName Menu.Button
 * @since 0.6.0
 */
// TODO allow isSearch, isContext for other 'standard' menu icons
const MenuButton: React.FunctionComponent<MenuButtonProperties> = ({
  children,
  className,
  style,
  onClick,
  onKeyDown,
  ariaExpanded,
  disabled,
  label,
  icon,
  ...other
}) => {
  // TODO add dynamic classname for icon, ie `triangle-${direction}` based on direction of menu
  const IconLabelContent = (label || icon) && (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={styles.iconLabelContentFlexWrapper}
    >
      {icon && typeof icon === 'string' && <Icon icon={icon} fontSize="15px" />}
      {icon && React.isValidElement(icon) && icon}
      {label && <Typography className={styles.label}>{label}</Typography>}
      <Icon icon="$icon--triangle-bottom" fontSize="15px" />
    </Flex>
  );

  return (
    <Button
      {...other}
      role="button"
      onClick={!disabled ? onClick : undefined}
      className={className}
      onKeyDown={!disabled ? onKeyDown : undefined}
      style={style}
      ariaHasPopup="menu"
      ariaExpanded={ariaExpanded}
      disabled={disabled}
    >
      {children ? children : IconLabelContent}
    </Button>
  );
};

MenuButton.displayName = 'Menu.Button';
export default MenuButton;
