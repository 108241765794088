import * as React from 'react';
import { printCoreWarning } from '../../../shared';
import styles from '../index.module.scss';

import Flex from '../../../Layout/Flex';
import Headline from './Headline';
import { DefaultPageHeaderProperties } from '../types';
import { ChildElementType } from '../../../../types';

/**
 * A component that accepts topLeft prop (should be <Breadcrumb/>), topRight prop (should be <Button/> or <Button.Group/>). Title and subtitle can also be passed as props if you dont want to add <Headline> component as a child. Any children passed into header will be rendered in order. 
 * @since 1.3.0
 *
 * @component
 * @visibleName DefaultPage.Header
 */
const DefaultPageHeader = ({ id, testId, children, title, subtitle, topLeft, topRight}: DefaultPageHeaderProperties) => {

  const componentChildren = React.Children.toArray(children);

  const headlineChild = componentChildren.find((child) => (child as ChildElementType).type.displayName === "DefaultPage.Headline");

  if(!title) {
    if (!headlineChild) {
      printCoreWarning("DefaultPageHeader requires a Headline child or title prop to be set");
    }

    const headlineFirst = componentChildren[0] && (componentChildren[0] as ChildElementType).type.displayName === "DefaultPage.Headline";

    if(!headlineFirst) {
      printCoreWarning("DefaultPageHeader recommends if you're not passing a title prop, that your first child be a Headline since all children are rendered in order.");
    }
  }

  if (headlineChild && title) {
    printCoreWarning("DefaultPageHeader requires EITHER a Headline child or title prop to be set. If you set both, Headline Child will take precedence.");
  }

  return(
    <header 
      className={styles.defaultPageHeader}         
      id={id} 
      data-test-id={testId ? testId : undefined}
    >
      <Flex justifyContent={topRight ? 'space-between' : 'flex-start'}>
        <Flex alignItems="center">
          {topLeft}
        </Flex>
        {topRight}
      </Flex>
      {!headlineChild && <Headline subHeadline={subtitle}>{title}</Headline>}
      {children}
    </header>
)};
DefaultPageHeader.displayName = 'DefaultPage.Header';

export default DefaultPageHeader; 