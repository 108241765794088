import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { BasicCheckboxProps, CheckboxThemeOverwriteType } from '../types';
import Icon from '../../../General/Icon';
import styles from '../index.module.scss';
import checkValue from '../../../../styles/checkValue';

const StyledDiv = styled.div`
  ${(props: { theme?: CheckboxThemeOverwriteType }) => {
    let style = ``;
    if (props.theme?.default?.color) {
      style += `& > span:first-child {
          color: ${checkValue(props.theme?.default?.color, 'colors')};
        }`;
    }
    if (props.theme?.hover?.color) {
      style += `& > span:first-child:hover {
          color: ${checkValue(props.theme?.hover?.color, 'colors')};
        }`;
    }
    if (props.theme?.focus?.color) {
      style += `& > span:first-child:focus {
          color: ${checkValue(props.theme?.focus?.color, 'colors')};
        }`;
    }
    if (props.theme?.disabled?.color) {
      style += `
        &.${styles['checkbox--disabled']} > span:first-child {
          color: ${checkValue(props.theme?.disabled?.color, 'colors')};
        }
      `;
    }
    return style;
  }}
`;

/**
 * Basic checkbox implemented as a [controlled component](https://reactjs.org/docs/forms.html#controlled-components).<br/>
 * It's HTML structure is implemented using the input element with `type` attribute set to 'checkbox'. (
 * For more information about the input element check [here](https://www.w3schools.com/jsref/dom_obj_checkbox.asp). <br/>
 */
const BasicCheckbox: React.FunctionComponent<BasicCheckboxProps> = ({
  checked,
  className,
  disabled,
  icon,
  id,
  label,
  testId,
  name,
  onChange,
  theme,
  value,
  readOnly,
  style,
  classNames,
}) => {
  return (
    <StyledDiv
      theme={theme}
      data-test-id={testId ? testId : undefined}
      className={classnames({
        [styles['checkbox']]: true,
        [styles['checkbox--disabled']]: !!disabled,
        [className as string]: !!className,
      })}
      style={style}
    >
      <span
        className={classnames(styles['checkbox__input-wrapper'], classNames?.wrapper, {
          [`${className}__input__wrapper`]: !!className,
        })}
      >
        <input
          type="checkbox"
          name={name}
          readOnly={readOnly || !onChange}
          className={classnames(styles['checkbox__input'], classNames?.input, {
            [`${className}__input`]: !!className,
          })}
          id={id}
          value={value}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
        />
        <Icon
          className={classnames(styles.checkbox__icon, classNames?.icon, {
            [`${className}__input__icon`]: !!className,
          })}
          color={checked ? undefined : '$grey--700'}
          icon={(checked ? icon?.checked : icon?.unchecked) || '$icon--checkbox-empty'}
        />
      </span>
      {label}
    </StyledDiv>
  );
};

BasicCheckbox.defaultProps = {
  icon: {
    unchecked: '$icon--checkbox-empty',
    checked: '$icon--checkbox-checked',
  },
};

BasicCheckbox.displayName = 'BasicCheckbox';
export default BasicCheckbox;
