import * as React from 'react';

import { TabProps } from '../types';
import classnames from 'classnames';
import Button from '../../../General/Button';
import { ButtonThemeVariant, ThemedButtonProperties } from '../../../General/Button/types';
import { withTheme } from '../../../../theme';
import styles from '../index.module.scss';

const TabButton: React.FunctionComponent<TabProps & { themed?: boolean }> = (props) => {
  const { selected, onChangeTab, value, children, className, ...otherProps } = props;

  const ButtonComponent = props.themed ? Button.Themed : Button;
  const cssActive = `${selected ? styles['tab-active'] : styles['tab-inactive']}`;

  const onClick = React.useCallback(() => onChangeTab && onChangeTab(value), [value, onChangeTab]);

  return (
    <ButtonComponent
      sneaky
      variant={ButtonThemeVariant.outlined}
      onClick={onClick}
      className={classnames(styles.tab, cssActive, {
        [className as string]: !!className,
      })}
      role="tab"
      aria-selected={selected}
      {...otherProps as any}
    >
      <span className={styles['tab-text']}>{children}</span>
    </ButtonComponent>
  );
};

// themed

const TabThemed: React.FunctionComponent<TabProps & ThemedButtonProperties> = (props) => (
  <TabButton {...props} themed />
);

const TabThemedExport: typeof TabThemed & {
  _internalType?: string;
} = withTheme(TabThemed);
TabThemedExport.displayName = 'Tabs.Tab.Themed';
TabThemedExport._internalType = 'Tabs.Tab.Themed';

// basic

/** Represents single Tab. Exposes additional API on top of the one exposed in the Button component. */
const Tab: React.FunctionComponent<TabProps> & {
  _internalType: string;
  Themed: typeof TabThemedExport;
} = (props) => <TabButton {...props} />;

Tab.displayName = 'Tabs.Tab';
Tab._internalType = 'Tabs.Tab';
Tab.Themed = TabThemedExport;

export default Tab;
