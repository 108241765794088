import { default as boxShadows, BoxShadowType } from './js/boxShadows';
import { default as colors, ColorsType } from './js/colors';
import { default as flags, FlagsType } from './js/flags';
import { default as icons, IconsType } from './js/icons';
import { default as spacings, SpacingsType } from './js/spacings';
import { default as typography, TypographyType } from './js/typography';
import { default as zIndex, zIndexType } from './js/elevation';

export enum CSSinJSEnum {
  boxShadows = 'boxShadows',
  colors = 'colors',
  flags = 'flags',
  icons = 'icons',
  spacings = 'spacings',
  typography = 'typography',
  zIndex = 'zIndex',
}

export type CSSinJSType = {
  boxShadows: BoxShadowType;
  colors: ColorsType;
  flags: FlagsType;
  icons: IconsType;
  spacings: SpacingsType;
  typography: TypographyType;
  zIndex: zIndexType;
};

const defaultCSSinJS: CSSinJSType = {
  boxShadows,
  colors,
  flags,
  icons,
  spacings,
  typography,
  zIndex,
};

export default defaultCSSinJS;
