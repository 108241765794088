import * as React from 'react';
import { ThemeContextType, ThemeContext } from './context';
import { ThemeType, ElementType } from './types';

type ComponentType<P> = React.ComponentClass<P> | React.FunctionComponent<P>;

export default function withComponentTheme<
  P extends { theme?: ElementType | object; toggleTheme?: Function }
>(Component: ComponentType<P>, themeName: string): React.FC<P> {
  return function (props: P) {
    return (
      <ThemeContext.Consumer>
        {(context: ThemeContextType) => (
          <Component
            {...props}
            theme={(context.theme as ThemeType)[themeName] as ElementType | object}
            toggleTheme={context.toggleTheme}
          />
        )}
      </ThemeContext.Consumer>
    );
  };
}
