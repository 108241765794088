import * as React from 'react';
import { ClientThemeType, ThemeClient, Palette, withTheme } from '../../../../theme/index';
import Basic from './Basic';
import { CheckboxThemeMapper, CheckboxThemeOverwriteType, ThemedCheckboxProps } from '../types';
import { printCoreWarning } from '../../../shared';

const themeMapper = (
  theme: ThemeClient | undefined,
  { palette = Palette.brand, themeOverwrite }: { palette?: Palette; themeOverwrite?: CheckboxThemeOverwriteType }
): CheckboxThemeOverwriteType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Checkbox.Themed component must have a palette. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    return {
      default: {
        color: themeOverwrite?.default?.color || selectedPalette.main,
      },
      hover: {
        color: themeOverwrite?.hover?.color || selectedPalette.light,
      },
      focus: {
        color: themeOverwrite?.focus?.color || selectedPalette.lighter || 'inherit',
      },
      disabled: {
        color: themeOverwrite?.disabled?.color || '$grey--700',
      },
    };
  }
  return undefined;
};

/**
 * Themed checkbox implementation.
 * Special parameters include theme, palete and the themeOverwrite object.
 * @param {ThemedCheckboxProps} properties
 */
const ThemedCheckbox = ({
  theme,
  palette = Palette.brand,
  themeOverwrite,
  ...otherProps
}: ThemedCheckboxProps) => {
  const mappedTheme = themeMapper(theme, { palette, themeOverwrite });
  return <Basic theme={mappedTheme} {...otherProps} />;
};

/**
 *
 * @component
 *
 * @visibleName Checkbox.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedCheckboxProps> & {
  mapper?: CheckboxThemeMapper;
} = withTheme(ThemedCheckbox);

ThemedExport.mapper = themeMapper;

ThemedExport.displayName = 'Checkbox.Themed';
export default ThemedExport;
