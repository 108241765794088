import * as React from 'react';
import {
  ClientThemeType,
  ThemeClient,
  Palette,
  ColorDefinition,
  withTheme,
} from '../../../../theme/index';
import Basic from './Basic';
import {
  ButtonThemeType,
  ButtonThemeVariant,
  ThemedButtonProperties,
  ButtonThemeOverwriteType,
} from '../types';
import { printCoreWarning } from '../../../shared';

const themeMapper = (
  theme: ThemeClient = {},
  {
    palette = Palette.brand,
    variant,
    themeOverwrite
  }: {
    palette?: Palette;
    variant: ButtonThemeVariant | string;
    themeOverwrite?: ButtonThemeOverwriteType;
  }
): ButtonThemeType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `Button.Themed component must pass a theme. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    if (variant === ButtonThemeVariant.filled) {
      return getFilledStyle(selectedPalette, themeOverwrite);
    }
    if (variant === ButtonThemeVariant.outlined) {
      return getOutlineStyle(selectedPalette, themeOverwrite);
    }
    printCoreWarning(
      `Unrecognized variant passed to Button.Themed component. Your theme is ignored.`
    );
    return undefined;
  }

  return undefined;
};

const ThemedButton = ({
  theme,
  palette = Palette.brand,
  variant = ButtonThemeVariant.filled,
  themeOverwrite,
  ...otherProps
}: ThemedButtonProperties): any => {
  const variantEnum: ButtonThemeVariant = ButtonThemeVariant[variant];
  const mappedTheme = themeMapper(theme || {}, {
    palette,
    variant: variantEnum,
    themeOverwrite,
  });
  return <Basic basic={false} theme={mappedTheme} {...otherProps} />;
};

const getFilledStyle = (
  palette: ColorDefinition,
  themeOverwrite?: ButtonThemeOverwriteType
): ButtonThemeType => {
  // TODO ADD MEMOIZATION !
  return {
    default: {
      backgroundColor: themeOverwrite?.default?.backgroundColor || palette.main,
      borderColor: themeOverwrite?.default?.borderColor || palette.main,
      color: themeOverwrite?.default?.color || palette.contrast,
    },
    hover: {
      backgroundColor: themeOverwrite?.hover?.backgroundColor || palette.main,
      borderColor: themeOverwrite?.hover?.borderColor || palette.main,
      color: themeOverwrite?.hover?.color || palette.contrast,
    },
    active: {
      backgroundColor: themeOverwrite?.active?.backgroundColor || palette.light,
      borderColor: themeOverwrite?.active?.borderColor || palette.light,
      color: themeOverwrite?.active?.color || palette.contrast,
    },
    focus: {
      backgroundColor: themeOverwrite?.focus?.backgroundColor || palette.main,
      borderColor: themeOverwrite?.focus?.borderColor || 'colors.grey.1200',
      color: themeOverwrite?.focus?.color || palette.contrast,
      outlineColor: 'transparent',
    },
    disabled: {
      backgroundColor: themeOverwrite?.disabled?.backgroundColor || palette.lighter,
      borderColor: themeOverwrite?.disabled?.borderColor || palette.lighter,
      color: themeOverwrite?.disabled?.color || palette.contrast,
    },
  };
};

const getOutlineStyle = (
  palette: ColorDefinition,
  themeOverwrite?: ButtonThemeOverwriteType
): ButtonThemeType => {
  // TODO ADD MEMOIZATION !
  return {
    default: {
      backgroundColor: themeOverwrite?.default?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.default?.borderColor || palette.main,
      color: themeOverwrite?.default?.color || palette.main,
    },
    hover: {
      backgroundColor: themeOverwrite?.hover?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.hover?.borderColor || palette.main,
      color: themeOverwrite?.hover?.color || palette.main,
    },
    active: {
      backgroundColor: themeOverwrite?.active?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.active?.borderColor || palette.light,
      color: themeOverwrite?.active?.color || palette.light,
    },
    focus: {
      backgroundColor: themeOverwrite?.focus?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.focus?.borderColor || 'colors.grey.1200',
      color: themeOverwrite?.focus?.color || palette.main,
      outlineColor: 'transparent',
    },
    disabled: {
      backgroundColor: themeOverwrite?.disabled?.backgroundColor || 'transparent',
      borderColor: themeOverwrite?.disabled?.borderColor || palette.lighter,
      color: themeOverwrite?.disabled?.color || palette.lighter,
    },
  };
};
/**
 * Themed variant of the Button component
 * @component
 * @visibleName Button.Themed
 */
const ThemedExport: React.FC<ThemedButtonProperties> & {
  mapper?: (
    theme: ThemeClient,
    params: {
      palette: Palette;
      variant: ButtonThemeVariant | string;
      themeOverwrite: ButtonThemeOverwriteType;
    }
  ) => ButtonThemeType | undefined;
} = withTheme(ThemedButton);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'Button.Themed';
export default ThemedExport;
