import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import Group from './components/Group';
import { BasicRadioProps, ThemedRadioProps, RadioThemeMapper } from './types';
import { ReactComponent as Svg } from './radiobutton.svg';

type RadioButtonStaticProps = {
  Themed: React.FunctionComponent<ThemedRadioProps> & {
    mapper?: RadioThemeMapper;
  };
  Group: React.FunctionComponent<any>;
  svg: React.FunctionComponent;
};

/**
 * Basic radio button
 * Prevents input state changes on user interaction if proper state storing and handling is not implemented in
 * a parent component.
 * Best to use with RadioButton.Group and a parent component that controlls the state.
 */
/**
 * Basic radio button implemented as a [controlled component](https://reactjs.org/docs/forms.html#controlled-components).<br/>
 * It's HTML structure is implemented using the input element with `type` attribute set to 'checked'. (
 * For more information about the input element check [here](https://www.w3schools.com/jsref/dom_obj_radio.asp)). <br/>
 * Intended for use with `RadioButton.Group` component which is a basic implementation of the wrapper that would represent an HTML
 * radio group.
 * @component
 */
const RadioButton: React.FunctionComponent<BasicRadioProps> & RadioButtonStaticProps = (props) => (
  <Basic {...props} />
);

RadioButton.Group = Group;
RadioButton.Themed = Themed;

RadioButton.displayName = 'RadioButton';
RadioButton.svg = Svg;
export default RadioButton;
