import moduleStyles from './index.scss';
import styles from './index';

const jsStyles: LooseObject = styles;
const scssStyles: LooseObject = moduleStyles;

/**
 * Extracts value that should be used inside of styled-components style defintion or passed down through styles property.
 * Goes through our scss and CSS-in-JS exports and checks if the passed value is an exported variable or value present in one of the
 * exported variables.
 * If the value is not supported, returns undefined.
 * If the value passed equals to false (null, 0, undefined), returns undefined.
 * @param value - String that represents either value, scss variable or js property path. Must be taken from the DesignToken documentation
 */
const checkValue = (value?: string, limitToKey?: string) => {
  if (!value) return undefined;
  if (value === 'transparent' || value === 'inherit') return value;

  // If it's one of the variables just return the value of that variable.
  if (value.includes('$')) return scssStyles[value.replace('$', '').replace('--', '_')];
  if (value.includes('.'))
    return jsStyles[value.substring(0, value.indexOf('.'))][
      value.substring(value.indexOf('.') + 1)
    ];

  // Not a variable. If this is a safe value, return it, otherwise return undefined.
  let found = false;

  if (limitToKey) {
    found = !!Object.keys(jsStyles[limitToKey]).find((subKey) => {
      return jsStyles[limitToKey][subKey].toLowerCase() === value.toLowerCase();
    });
    return found ? value : undefined;
  }

  Object.keys(jsStyles).forEach((jsKey) => {
    if (!found) {
      found = !!Object.keys(jsStyles[jsKey]).find((subKey) => {
        return (
          jsStyles[jsKey][subKey].toLowerCase() === value.toLowerCase() ||
          jsStyles[jsKey][subKey].toLowerCase() === `\\${value.toLowerCase()}`
        );
      });
    }
  });
  return found ? value : undefined;
};

export default checkValue;
