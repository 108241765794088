import * as React from 'react';
import styled from 'styled-components';
import { printCoreWarning } from '../../../shared';
import checkValue from '../../../../styles/checkValue';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { StyledFlex } from './Styled';
import Themed from './Themed';
import { ProgressLoaderProperties, ThemedLoaderProperties, LoaderThemeMapperType } from '../types';

const Progress = styled.div<{
  progressValue?: number;
  total?: number;
  thickness: string;
  color: string;
  className?: string;
}>`
  height: ${({ thickness }: { thickness: string }) => thickness};
  background-color: ${({ color }: { color: string }) => checkValue(color, 'colors')};
  ${({ progressValue, total }: { progressValue?: number; total?: number }) => {
    if (progressValue) {
      let value = 0;
      try {
        value = Number(progressValue);
      } catch (error) {
        printCoreWarning(`The value passed to the Loader component cannot be converted to number.`);
        return `width: 0;`;
      }
      // If total is passed, we need to calculate the
      // percentage that needs to be displayed
      if (total) {
        let totalValue = 0;
        try {
          totalValue = Number(total);
          return `width: ${Math.floor((value / totalValue) * 100)}%;`;
        } catch (error) {
          printCoreWarning(
            `The total passed to the Loader component cannot be converted to number.`
          );
          return `width: 0;`;
        }
      }
      // If total is not passed, you need to take the progressValue as percentage
      return `width: ${Math.floor(value)}%;`;
    }
    return `width: 0;`;
  }}
`;

/**
 * Basic implementation of the Loader component.
 *
 * @visibleName Loader.Progress
 * @since 0.3.0
 */

const ProgressLoader: React.FunctionComponent<ProgressLoaderProperties> & {
  Themed: React.FunctionComponent<ThemedLoaderProperties & { Component: any }> & {
    mapper?: LoaderThemeMapperType;
  };
} = ({
  alignItems,
  centered,
  children,
  className,
  color,
  direction,
  flexBasis,
  invert,
  justifyContent,
  loaderClassName,
  value,
  size,
  testId,
  style,
  theme,
  thickness,
  total,
  id,
  wrap,
}) => {
  return (
    <StyledFlex
      alignItems={alignItems}
      thickness={thickness}
      className={classnames(className, {
        [styles.centered]: centered,
      })}
      direction={direction}
      flexBasis={flexBasis}
      justifyContent={justifyContent}
      size={size}
      style={style}
      id={id}
      data-test-id={testId ? testId : undefined}
      wrap={wrap}
    >
      {invert && children}
      <Progress
        thickness={thickness || ''}
        className={classnames(styles.progress, loaderClassName)}
        color={color || theme?.color || ''}
        progressValue={value}
        total={total}
      />
      {!invert && children}
    </StyledFlex>
  );
};

ProgressLoader.displayName = 'Loader.Progress';
ProgressLoader.Themed = (props) => <Themed {...props} Component={ProgressLoader} />;
ProgressLoader.Themed.displayName = 'Loader.Progress.Themed';

ProgressLoader.defaultProps = {
  size: '10px',
  color: '#d9dadc',
  thickness: '4px',
};

export default ProgressLoader;
