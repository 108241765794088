import * as React from 'react';
import classnames from 'classnames';
import List from '../../../DataDisplay/List';
import { StyledLi } from './Styled';
import Button from '../../../General/Button';
import Icon from '../../../General/Icon';
import useVisibilityHook from './useVisibilityHook';
import styles from '../index.module.scss';
import { SubmenuProperties } from '../types';
import { handleKeyDown, useOutsideClickHandler } from './eventHandlers';

/**
 * A component indended for usage when you need more levels of submenus inside a single
 * menu component.
 * Does not have an `onClick` handler as it requires an additional handling of the space key
 * and changes accessibility expectations. This is mostly used in menubars, not supported
 * out of the box via this implementation, so it is why such property is skipped.
 *
 * @visibleName Menu.Submenu
 * @since 0.6.0
 */

const Submenu: React.FunctionComponent<SubmenuProperties> = ({
  children,
  label,
  testId,
  style,
  id,
  includeHover,
  className,
  notifyParent,
  isParentVisible,
  elevation,
  position,
  disabled,
}) => {
  const [
    isVisible,
    { childVisibilityUpdate, onChildEnter, onChildLeave, onClickHandler, setShow },
  ] = useVisibilityHook({
    initialVisibility: false,
    notifyParent,
    includeHover,
  });

  const wrapperRef = React.useRef(null);
  useOutsideClickHandler(wrapperRef, () => {
    setShow(false);
  });

  const hasChildren = React.useMemo(() => React.Children.count(children) > 0, [children]);

  return (
    <StyledLi
      forwardedRef={wrapperRef}
      elevation={elevation}
      role="none"
      listItemIcon=""
      className={classnames(styles.menu__item, className, {
        [styles.disabled]: disabled,
      })}
      onMouseEnter={includeHover ? onChildEnter : undefined}
      onMouseLeave={includeHover ? onChildLeave : undefined}
      testId={testId}
      style={style}
      id={id}
    >
      <Button
        role="menuitem"
        ariaHasPopup="menu"
        ariaExpanded={(isVisible || isParentVisible) && !disabled}
        className={classnames(styles.menu__item__button, {
          [`${className}__button`]: !!className,
          [styles.hasIcon]: !!hasChildren,
        })}
        disabled={disabled}
        ariaDisabled={disabled}
        sneaky
        hidden={!isVisible && !isParentVisible}
        onKeyDown={!disabled ? handleKeyDown(onChildEnter, notifyParent) : undefined}
        onClick={!disabled ? onClickHandler : undefined}
      >
        <span className={styles.menu__item__button__label}>{label}</span>
        {hasChildren && (
          <Icon
            className={classnames({
              [`${className}__button__icon`]: !!className,
            })}
            icon={`$icon--triangle-${iconPositionMap[position || 'left']}`}
          />
        )}
      </Button>
      {hasChildren && (
        <List
          role="menu"
          className={classnames(styles.menu__item__submenu, styles[position || 'left'], {
            [`${className}__menu__item__submenu`]: !!className,
            [styles['hovered']]: isVisible && !disabled,
          })}
        >
          {React.Children.map(
            children,
            (child) =>
              child &&
              React.cloneElement(child as React.ReactElement, {
                ...(child as React.ReactElement)?.props,
                notifyParent: childVisibilityUpdate,
                isParentVisible: isVisible,
                includeHover,
              })
          )}
        </List>
      )}
    </StyledLi>
  );
};

const iconPositionMap = {
  left: 'right',
  right: 'left',
  top: 'top',
  bottom: 'bottom',
};

Submenu.displayName = 'Menu.Submenu';
Submenu.defaultProps = {
  position: 'left',
};
export default Submenu;
