import { ThemeType, ClientThemeType } from './types';
import variables from '../styles/index';

/** @deprecated Default theme defined by our app */
export const defaultTheme: ThemeType = {
  global: {
    default: {
      color: 'blue',
      background: 'red',
    },
  },
};

/**
 * Default theme of the new client theme
 */
export const defaultClientTheme: ClientThemeType = {
  palette: {
    brand: {
      light: '',
      main: '',
      lighter: '',
      contrast: '',
    },
    error: {
      light: '',
      main: variables.colors['red.bright.1000'],
      lighter: '',
      contrast: variables.colors['white'],
    },
    warning: {
      light: '',
      main: '',
      lighter: '',
      contrast: '',
    },
    success: {
      light: '',
      main: variables.colors['green.dark.moderate.lime.500'],
      lighter: '',
      contrast: variables.colors['white'],
    },
    info: {
      light: '',
      main: '',
      lighter: '',
      contrast: '',
    },
  },
  typography: {
    headings: {
      // Landingpage headlines
      1: {
        fontSize: variables.typography['font.size.60'],
        weight: variables.typography['font.weight.light'],
        color: variables.colors['grey.1200'],
        // marginTop: variables.typography["font.size.60"],
        // marginBottom:  variables.typography["font.size.25"],
      },
      // Numbers in KPIs only
      2: {
        fontSize: variables.typography['font.size.40'],
        weight: variables.typography['font.weight.light'],
        color: variables.colors['grey.1200'],
      },
      // Headlines, Numbers in KPIs
      3: {
        fontSize: variables.typography['font.size.32'],
        weight: variables.typography['font.weight.light'],
        color: variables.colors['grey.1200'],
      },
      // Headlines of table or chart modules
      4: {
        fontSize: variables.typography['font.size.20'],
        weight: variables.typography['font.weight.medium'],
        color: variables.colors['grey.1200'],
      },
      // Headlines in BC-Widgets
      5: {
        fontSize: variables.typography['font.size.16'],
        weight: variables.typography['font.weight.medium'],
        color: variables.colors['grey.1200'],
      },
      // Zeplin subheadins
      6: {
        fontSize: variables.typography['font.size.13'],
        weight: variables.typography['font.weight.medium'],
        color: variables.colors['grey.1200'],
      },
    },
    paragraph: {
      fontSize: variables.typography['font.size.13'],
      weight: variables.typography['font.weight.regular'],
      color: variables.colors['grey.1200'],
      lineHeight: '1.38',
      letterSpacing: 'normal',
      alignment: 'left',
    },
  },
};
