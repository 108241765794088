import * as React from 'react';
import { ClientThemeType, ThemeClient, Palette, withTheme } from '../../../../theme/index';
import Basic from './Basic';
import { RadioThemeMapper, RadioThemeOverwriteType, ThemedRadioProps } from '../types';
import { printCoreWarning } from '../../../shared';

const themeMapper = (
  theme: ThemeClient | undefined,
  { palette, themeOverwrite }: { palette: Palette; themeOverwrite: RadioThemeOverwriteType }
): RadioThemeOverwriteType | undefined => {
  const clientTheme = theme as ClientThemeType;

  if (clientTheme.palette) {
    const selectedPalette = clientTheme.palette[palette];
    if (!selectedPalette) {
      printCoreWarning(
        `RadioButton.Themed component must have a palette. Your currently selected palette does not exist in the theme passed. Are you sure this was what you were going to do?`
      );
      return undefined;
    }
    return {
      default: {
        color: themeOverwrite?.default?.color || selectedPalette.main,
      },
      hover: {
        color: themeOverwrite?.hover?.color || selectedPalette.light,
      },
      focus: {
        color: themeOverwrite?.focus?.color || selectedPalette.lighter || 'inherit',
      },
      disabled: {
        color: themeOverwrite?.disabled?.color || '$grey--700',
      },
    };
  }

  return undefined;
};

/**
 * Themed radio buton implementation.
 * Special parameters include theme, palete and the themeOverwrite object.
 * @param {ThemedRadioProps} properties
 */
const ThemedRadio = ({
  theme,
  palette = Palette.brand,
  themeOverwrite,
  ...otherProps
}: ThemedRadioProps) => {
  const mappedTheme = themeMapper(theme, { palette, themeOverwrite });
  return <Basic theme={mappedTheme} {...otherProps} />;
};

/**
 * @component
 *
 * @visibleName RadioButton.Themed
 */
const ThemedExport: React.FunctionComponent<ThemedRadioProps> & {
  mapper?: RadioThemeMapper;
} = withTheme(ThemedRadio);

ThemedExport.mapper = themeMapper;

ThemedExport.displayName = 'RadioButton.Themed';
export default ThemedExport;
