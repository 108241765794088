import styled from 'styled-components';
import { LoaderProperties } from '../types';
import Flex from '../../../Layout/Flex';

const StyledFlex = styled(Flex)<LoaderProperties>`
  position: relative;
  ${(props: LoaderProperties) => `min-width: ${props.size}`};
`;

export { StyledFlex };
