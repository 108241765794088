import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { TooltipChildType } from '../types';

/**
 * Our Tooltip component can have simple and complex content.
 * The Tooltip.Content component that serves as a wrapper.
 * In case of simple content (just text for example),
 * the Tooltip wraps the element in Content so you do not need to.
 * @component
 * @visibleName Tooltip.Content
 */
const TooltipContent: React.FunctionComponent<TooltipChildType> = ({
  className,
  style,
  hasHeader = false,
  hasFooter = false,
  theme,
  ...props
}) => (
  <div
    style={style}
    className={classnames(styles['tooltip__inner__content'], className, {
      [styles['tooltip__inner__content--basic']]: !theme && !(hasHeader || hasFooter),
      [styles['tooltip__inner__content--light']]: hasHeader || hasFooter,
      [styles['tooltip__inner__content--border-bottom']]: hasHeader && !hasFooter,
      [styles['tooltip__inner__content--border-top']]: !hasHeader && hasFooter,
    })}
  >
    {props.children}
  </div>
);

TooltipContent.displayName = 'Tooltip.Content';

export default TooltipContent;
