import * as React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import Item from './components/Item';
import Styled from './components/Basic';
import { GridProperties, GridItemProperties } from './types';
import { ReactComponent as Svg } from './grid.svg';

const rowChildren = (children, count: number) => {
  let row = 1;
  let column = 1;

  return React.Children.map(children, (child) => {
    if (child?.props?.column?.span + column > count + 1) {
      row += 1;
      column = 1;
    }
    const newChild = React.cloneElement(child, {
      ...child.props,
      column: {
        span: child.props?.column?.span || 1,
        start: column,
      },
      row: {
        span: child.props?.row?.span || 1,
        start: row,
      },
    });

    // Add this child's column for next
    column += child.props?.column?.span || 1;
    return newChild;
  });
};

/** Static properties of the Grid element. */
type GridStaticProperties = {
  Item: React.FunctionComponent<GridItemProperties>;
  svg: React.FunctionComponent;
};

/**
 * Basic implementation of the Grid component.
 * Uses the CSS grid to implement its functionality and should be used for creating complex
 * layouts and grids (for more info on the CSS grid check [here](https://css-tricks.com/snippets/css/complete-guide-grid))
 *
 * For easier component creation, we expose the [Grid.Item](#/Components/Layout/Grid?id=item) component. It includes some
 * basic properties which map to corresponding CSS. It's a div wrapper without any behavior which means you can easily
 * use any component as a direct child of the Grid component, however **we would recommend using Grid.Item where possible**.
 * @since 0.3.0
 */

const Grid: React.FunctionComponent<
  GridProperties & {
    /**
     * Sets starting positions of the child elements.
     */
    fillRows?: boolean;
  }
> &
  GridStaticProperties = ({
  alignItems,
  fillRows,
  children,
  className,
  columns,
  gap,
  id,
  justifyItems,
  rows,
  style,
  testId,
}) => {
  return (
    <Styled
      alignItems={alignItems}
      className={classnames(styles.grid__container, className)}
      columns={columns}
      data-test-id={testId ? testId : undefined}
      gap={gap}
      id={id}
      justifyItems={justifyItems}
      rows={rows}
      style={style}
    >
      {fillRows &&
        rowChildren(
          children,
          columns && /^-{0,1}\d+$/.test(columns.toString())
            ? Number(columns)
            : React.Children.count(children)
        )}
      {!fillRows && children}
    </Styled>
  );
};

Grid.displayName = 'Grid';
Grid.Item = Item;
Grid.defaultProps = {
  fillRows: true,
};
Grid.svg = Svg;

export default Grid;
