import * as React from 'react';
import BasicTag from './components/Basic';
import ThemedTag from './components/Themed';
import { TagProps, TagThemedExportType } from './types';
import { ReactComponent as Svg } from './tags.svg';
import Arrow from './components/Arrow';
import { ArrowTagExportType } from './components/ArrowTag/types';

type TagStaticProps = {
  Themed: TagThemedExportType;
  Arrow: ArrowTagExportType;
  svg: React.FunctionComponent;
};

/**
 * Basic and themed Tag component
 */
const Tag: React.FC<TagProps> & TagStaticProps = (props) => <BasicTag {...props} />;

Tag.Themed = ThemedTag;
Tag.Arrow = Arrow;
Tag.displayName = 'Tag';
Tag.svg = Svg;

export default Tag;
