import * as React from 'react';
import styled from 'styled-components';
import { BasicToggleProps } from '../types';
import checkValue from '../../../../styles/checkValue';

const printWarning = (propName: string) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      '%c CORE-UI-KIT WARNING',
      'color: red',
      `\n\rYou are passing both the theme and ${propName} property. The property ${propName} will override the passed ${propName} from the theme.\n\rIs this what you wanted to achieve?`
    );
  }
};

export const StyledToggle: React.FunctionComponent<BasicToggleProps> = styled.button<
  BasicToggleProps
>`
  background-color: ${(props: BasicToggleProps) => {
    if (props.color && props.theme?.default?.color) {
      printWarning('color');
    }
    return props.active && (checkValue(props.color, 'colors') || props.theme?.default?.color);
  }};
`;
