import * as React from 'react';

export type CSSType = React.CSSProperties;

/** Definition of pseudo-class properties + one default */
export type ElementType = {
  ['first-child']?: CSSType;
  ['first-of-type']?: CSSType;
  ['in-range']?: CSSType;
  ['last-child']?: CSSType;
  ['last-of-type']?: CSSType;
  ['only-child']?: CSSType;
  ['out-of-range']?: CSSType;
  ['read-only']?: CSSType;
  ['read-write']?: CSSType;
  ['required']?: CSSType;
  ['visited']?: CSSType;
  active?: CSSType;
  checked?: CSSType;
  default: CSSType /* Default values applied to element */;
  disabled?: CSSType;
  empty?: CSSType;
  enabled?: CSSType;
  focus?: CSSType;
  hover?: CSSType;
  link?: CSSType;
  optional?: CSSType;
};

/**
 * Global theme object that defines styles for elements
 * @deprecated will be removed when all older components start using the new one
 */
export type ThemeType = {
  global: ElementType;
  [anotherKey: string]: ElementType | object;
};

/** Generic interface for current and all future theme type definitions */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ThemeClient {}

/**
 * Type for expressing color palette definitions
 */
export type ColorDefinition = {
  /**
   * A lighter variant than the light color definition
   */
  lighter?: string;
  /**
   * A lighter variant than the main color definition
   */
  light: string;
  /**
   * Base for the color defition
   */
  main: string;
  /**
   * A darker variant than the main color definition
   */
  dark?: string;
  /**
   * A darker variant than the dark color definition
   */
  darker?: string;
  /**
   * Contrasting text color for main color
   */
  contrast: string;
};

/**
 * Type for expressing typographic definitions
 */
export type TypographyDefinition = {
  fontSize?: string;
  weight?: string;
  color?: string;
  lineHeight?: string;
  letterSpacing?: string;
  alignment?: string;
  marginTop?: string;
  marginBottom?: string;
};

export enum Palette {
  brand = 'brand',
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

/**
 * Definition of what palettes this system recognizes
 */
export type PaletteType = {
  /**
   * Default palette.
   */
  brand: ColorDefinition;
  /**
   * Palette that conveys a negative outcome of an user interaction.
   */
  error: ColorDefinition;
  /**
   * Palette indicating an warning state inside of the application.
   */
  warning: ColorDefinition;
  /**
   * Palette that needs to be used for general information passing to the user.
   */
  info: ColorDefinition;
  /**
   * Palette that conveys a positive outcome of an user interaction.
   */
  success: ColorDefinition;
};

/**
 * Client provided theme. Object with this type should
 * be passed to our ThemeContext to be propagated
 * to various components.
 * Is an interface so it's expandable.
 */
export interface ClientThemeType extends ThemeClient {
  /**
   * An object containing all available color palettes
   * present in the single theme
   */
  palette: PaletteType;
  /**
   * Typographic definitions inside the application.
   */
  typography: {
    /**
     * All headings inside of the application
     */
    headings: {
      1?: TypographyDefinition; // h1
      2?: TypographyDefinition; // h2
      3?: TypographyDefinition; // h3
      4?: TypographyDefinition; // h4
      5?: TypographyDefinition; // h5
      6?: TypographyDefinition; // h6
    };
    /**
     * Specifies default options for the component.
     */
    paragraph: TypographyDefinition;
  };
}
