import * as React from 'react';
import classnames from 'classnames';
import Flex from '../../../Layout/Flex';
import styles from '../index.module.scss';
import { GroupInputProperties } from '../types';

/**
 * This element is useful when you wish to create a single widget that contains
 * a input inside (for instance, input with a Select or a Button).
 * This element will display passed children and apply some styling to them
 * to give them a unique look.
 * @component
 * @visibleName Input.Group
 */
const Group: React.FunctionComponent<GroupInputProperties> = ({
  className,
  style,
  children,
  id,
  testId,
  hidden,
  forwardedRef,
}) => {
  return (
    <Flex
      testId={testId}
      hidden={hidden}
      forwardedRef={forwardedRef}
      id={id}
      style={style}
      className={classnames(styles.input__group, className)}
    >
      {children}
    </Flex>
  );
};

Group.displayName = 'Input.Group';
export default Group;
