import * as React from 'react';
import classnames from 'classnames';
import Option from './components/Option';
import Typography from '../../General/Typography';
import Loader from '../../Feedback/Loader';
import { Component } from '../../General/Typography/types';
import { SelectProperties, SelectOptionProperties } from './types';
import SelectButton from './components/Button';
import SelectListBox from './components/Listbox';
import styles from './index.module.scss';
import { handleKeyPress, useOutsideClickHandler } from './eventHandlers';
import { ReactComponent as Svg } from './select.svg';

/** Static properties of the Select element. */
type SelectStaticProperties = {
  Option: React.FunctionComponent<SelectOptionProperties>;
  svg: React.FunctionComponent;
};

/**
 * Basic implementation of the listbox pattern with a button that reveals available options.
 * This component is intended for using whenever a list of options need to be presented to the user and to allow
 * the user to select one or more of them. Is not implemented using the native HTML select element because
 * of native element's limitations but the component handles keyboard navigation and mouse handler events just as the native element would.
 * It is also a controlled component meaning you'd need to add handling of state to make sure it functions properly.
 * <br/><br/>
 * **Note**
 * If you need to have actions happen on each option selection, a *Menu* component might be more suitable for using.
 * If you need to have an classic input, with filtering/search/autocomplete options, a *Combobox* component might be more suitable for using.
 * If you need to have elements with more complex interaction (more interactive options), implement a combobox with popup with "grid" role.
 *
 * @since 0.4.0
 */

const Select: React.FunctionComponent<SelectProperties> & SelectStaticProperties = ({
  children,
  className,
  classNames,
  disabled,
  elevation,
  error,
  expandedOnSelect,
  id,
  labelId,
  listFooter,
  listHeader,
  loader,
  loading,
  onChange,
  placeholder,
  renderSelect,
  selected,
  style,
  testId,
  warning,
  autoMaxWidth,
  round,
  padding,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [hovered, setHovered] = React.useState<number>(-1);
  useOutsideClickHandler(id, expanded, setExpanded);

  const options = React.useMemo(() => {
    return React.Children.map(children, (child, index) => {
      const childElement = child as React.ReactElement;

      return {
        index,
        id: childElement.props?.id,
        disabled: childElement.props?.heading || childElement.props?.disabled || false,
      };
    });
  }, [children]);

  const selectedChildren = React.useMemo(() => {
    const allChildren = React.Children.toArray(children);
    let selectedIdArray = selected instanceof Array ? selected : [selected];
    if (selected === '') selectedIdArray = [];
    const selectedChildren = selectedIdArray.map((id) =>
      allChildren.find((child) => id === (child as React.ReactElement).props?.id)
    );
    if (renderSelect && typeof renderSelect !== 'boolean') {
      return renderSelect(selectedChildren);
    }
    return selectedChildren;
  }, [children, selected, renderSelect]);

  const selectAndClose = React.useCallback(
    (optionId: string) => {
      if (onChange) {
        onChange(optionId);
      }
      if (!expandedOnSelect) {
        setExpanded(false);
        document.getElementById(id)?.focus();
      }
      const selectedOption = options?.find((option) => option.id === optionId);
      if (selectedOption) {
        setHovered(selectedOption.index);
      }
    },
    [onChange, expandedOnSelect, options, id]
  );

  const keyDownHandler = React.useMemo(
    () =>
      handleKeyPress({
        hoveredIndex: hovered,
        isExpandedOnSelect: expandedOnSelect,
        options,
        onChange,
        setExpanded,
        expanded,
        setHovered,
      }),
    [hovered, expandedOnSelect, options, onChange, expanded]
  );

  return (
    <div
      id={id ? `${id}_wrapper` : undefined}
      data-test-id={testId ? `${testId}_wrapper` : undefined}
      className={classnames(
        styles.select,
        { [styles['select--disabled']]: disabled },
        { [styles[`select--rounded-${round}`]]: true },
        className
      )}
      style={{ ...style, ...(autoMaxWidth && { maxWidth: autoMaxWidth }) }}
    >
      <SelectButton
        ariaLabelledBy={labelId}
        onKeyDown={keyDownHandler}
        expanded={expanded}
        id={id}
        testId={testId}
        disabled={disabled}
        setExpanded={setExpanded}
        autoMaxWidth={autoMaxWidth}
        round={round}
        padding={padding}
        className={classnames(classNames?.button, {
          [`${className}__button`]: !!className,
          [styles.error]: error,
          [styles.warning]: warning,
        })}
      >
        {selected && (selected as Array<string>).length !== 0 && renderSelect && selectedChildren}
        {(!selected || (selected as Array<string>).length === 0 || renderSelect === false) && (
          <Typography color={'$grey--700'} component={Component.span}>
            {placeholder}
          </Typography>
        )}
      </SelectButton>
      <SelectListBox
        ariaActiveDesendant={selected instanceof Array ? undefined : selected}
        ariaMultiSelectable={selected instanceof Array}
        header={listHeader}
        footer={listFooter}
        id={id}
        testId={testId}
        elevation={elevation}
        expanded={expanded}
        autoMaxWidth={autoMaxWidth}
        optionsClassname={classNames?.optionsWrapper}
        className={classnames(classNames?.listbox, {
          [`${className}__listbox`]: !!className,
        })}
      >
        {!loading &&
          React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              key: (child as React.ReactElement).props?.id,
              className: classnames({
                [(child as React.ReactElement).props?.className]: !!(child as React.ReactElement)
                  .props?.className,
                [styles['select__listbox__option--hovered']]: index === hovered,
              }),
              selected:
                (child as React.ReactElement).props?.id === selected ||
                (selected?.includes && selected?.includes((child as React.ReactElement).props?.id)),
              onSelect: selectAndClose,
              outsideButton: true,
              onKeyDown: keyDownHandler,
              icon: (child as React.ReactElement).props?.icon,
              label: (child as React.ReactElement).props?.label,
              autoMaxWidth: autoMaxWidth,
            })
          )}
        {loading && loader}
      </SelectListBox>
    </div>
  );
};

Select.displayName = 'Select';
Select.Option = Option;
Select.defaultProps = {
  disabled: false,
  renderSelect: true,
  expandedOnSelect: false,
  error: false,
  warning: false,
  loading: false,
  elevation: 100,
  loader: <Loader direction="column" alignItems="center" />,
  round: 'none',
};
Select.svg = Svg;
export default Select;
