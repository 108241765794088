import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { BasicListProperties } from '../types';

/**
 * Basic list implementation
 */
const BasicList: React.FunctionComponent<BasicListProperties> = ({
  type,
  listItemIcon,
  children,
  className,
  style,
  testId,
  id,
  theme,
  ariaDisabled,
  ariaExpanded,
  ariaHasPopup,
  ariaLabelledBy,
  hidden,
  role,
  tabIndex,
  onMouseEnter,
  onMouseLeave,
}) => {
  const rendered = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement, {
      theme,
      listItemIcon: (child as any)?.props?.listItemIcon || listItemIcon || undefined,
    });
  });
  return React.createElement(type || 'ul', {
    children: rendered,
    style,
    className: classnames(styles.list, className),
    role,
    id,
    onMouseEnter,
    onMouseLeave,
    tabIndex,
    hidden,
    'aria-disabled': ariaDisabled,
    'aria-expanded': ariaExpanded,
    'aria-haspopup': ariaHasPopup,
    'aria-labelledby': ariaLabelledBy,
    'data-test-id': testId ? testId : undefined,
  });
};

BasicList.displayName = 'BasicList';
export default BasicList;
