import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { BasicRadioProps, RadioThemeOverwriteType } from '../types';
import Icon from '../../../General/Icon';
import styles from '../index.module.scss';
import checkValue from '../../../../styles/checkValue';

const StyledDiv = styled.div`
  ${(props: { theme?: RadioThemeOverwriteType }) => {
    let style = ``;
    if (props.theme?.default?.color) {
      style += `& > span:first-child {
          color: ${checkValue(props.theme?.default?.color, 'colors')};
        }`;
    }
    if (props.theme?.hover?.color) {
      style += `& > span:first-child:hover {
          color: ${checkValue(props.theme?.hover?.color, 'colors')};
        }`;
    }
    if (props.theme?.focus?.color) {
      style += `& > span:first-child:focus {
          color: ${checkValue(props.theme?.focus?.color, 'colors')};
        }`;
    }
    if (props.theme?.disabled?.color) {
      style += `
        &.${styles['radio-button--disabled']} > span:first-child {
          color: ${checkValue(props.theme?.disabled?.color, 'colors')};
        }
      `;
    }
    return style;
  }}
`;

/**
 * Basic radio button implemented as a [controlled component](https://reactjs.org/docs/forms.html#controlled-components).
 * Prevents input state changes on user interaction if proper state storing and handling is not implemented in
 * a parent component.
 * Best to use with RadioButton.Group and a parent component that controlls the state.
 */
const BasicRadio: React.FunctionComponent<BasicRadioProps> = ({
  checked,
  className,
  classNames,
  disabled,
  icon,
  id,
  label,
  name,
  onChange,
  style,
  testId,
  theme,
  value,
}) => {
  return (
    <StyledDiv
      theme={theme}
      data-test-id={testId ? testId : undefined}
      className={classnames({
        [styles['radio-button']]: true,
        [styles['radio-button--disabled']]: !!disabled,
        [className as string]: !!className,
      })}
      style={style}
    >
      <span
        className={classnames(styles['radio-button__input-wrapper'], classNames?.wrapper, {
          [`${className}__input__wrapper`]: !!className,
        })}
      >
        <input
          type="radio"
          name={name}
          id={id}
          className={classnames(styles['radio-button__input'], classNames?.input, {
            [`${className}__input`]: !!className,
          })}
          value={value}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
        />
        <Icon
          color={checked ? undefined : '$grey--700'}
          className={classnames(styles['radio-button__icon'], classNames?.icon, {
            [`${className}__input__icon`]: !!className,
          })}
          icon={(checked ? icon?.checked : icon?.unchecked) || '$icon--ring'}
        />
      </span>
      {label}
    </StyledDiv>
  );
};

BasicRadio.defaultProps = {
  icon: {
    unchecked: '$icon--ring',
    checked: '$icon--ring-filled',
  },
};

BasicRadio.displayName = 'BasicRadioButton';
export default BasicRadio;
