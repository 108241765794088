import * as React from 'react';
import Basic from './components/Basic';
import { default as TitleComponent } from './components/Title';
import { default as ContentComponent } from './components/Content';
import { ReactComponent as Svg } from './accordion.svg';

import { BasicAccordionProperties, AccordionElementProperties } from './types';

type AccordionStatic = {
  Title: React.FunctionComponent<AccordionElementProperties>;
  Content: React.FunctionComponent<AccordionElementProperties>;
  svg: React.FunctionComponent;
};

/** Basic implementation of the Accordion component.
 * @component
 * @since 0.3.0
 * */
const Accordion: React.FunctionComponent<BasicAccordionProperties> & AccordionStatic = (
  props: BasicAccordionProperties
) => <Basic {...props} />;

Accordion.displayName = 'Accordion';

Accordion.Title = TitleComponent;
Accordion.Title.displayName = 'Accordion.Title';

Accordion.Content = ContentComponent;
Accordion.Content.displayName = 'Accordion.Content';

Accordion.svg = Svg;

export default Accordion;
