import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { GridItemProperties } from '../types';

/**
 * Basic implementation of the Grid.Item component.
 * Direct child of the Grid that exposes some properties to allow easier CSS property setup.
 * Does not need to be used as direct child of Grid, but we'd recommend it.
 *
 * @visibleName Grid.Item
 * @since 0.3.0
 */

const Item: React.FunctionComponent<GridItemProperties> = ({
  className,
  style,
  children,
  row,
  column,
  justifySelf,
  alignSelf,
}) => (
  <div
    style={style}
    className={classnames(
      styles.grid__item,
      className,
      {
        [styles[`justify--${justifySelf}`]]: !!justifySelf,
        [styles[`align--${alignSelf}`]]: !!alignSelf,
      },
      {
        [styles[`grid__column__start--${column?.start}`]]: !!column?.start,
        [styles[`grid__column__end--${column?.span}`]]: !!column?.span,
      },
      {
        [styles[`grid__row__start--${row?.start}`]]: !!row?.start,
        [styles[`grid__row__end--${row?.span}`]]: !!row?.span,
      }
    )}
  >
    {children}
  </div>
);

Item.displayName = 'Grid.Item';
Item.defaultProps = {
  column: {
    start: 'auto',
    span: 1,
  },
};

export default Item;
