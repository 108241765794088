import React from 'react';
import classnames from 'classnames';
import Typography from '../../../General/Typography';
import { AccordionElementProperties } from '../types';

/**
 *
 * @visibleName Accordion.Content
 * @component
 */
const Content: React.FunctionComponent<AccordionElementProperties> = ({
  className,
  children,
  ...other
}: AccordionElementProperties) => (
  <Typography
    className={classnames({
      // eslint-disable-next-line @typescript-eslint/camelcase
      Accordion__content: true,
      className,
    })}
    {...other}
  >
    {children}
  </Typography>
);

export default Content;
