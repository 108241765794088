import styled from 'styled-components';
import styles from '../index.module.scss';
import { GridProperties } from '../types';
/**
 * A styled div that's going to be creating our Separator component.
 * Displays all applicable styling.
 * @component
 */
const BasicGrid = styled.div<GridProperties & { children: any }>`
  grid-template-columns: ${(props: GridProperties & { children: any }) => {
    const columns = props.columns;
    // Number only
    if (columns && /^-{0,1}\d+$/.test(columns.toString())) {
      let gap = props.gap || Number(styles.defaultGridRowGap.replace('px', ''));
      if (gap.hasOwnProperty('column')) {
        gap = gap['column'];
      }
      return generateEqualTemplate(Number(columns), gap as number);
    }
    // Not a number, apply what was passed
    return columns;
  }};
  grid-template-rows: ${(props: GridProperties & { children: any }) => {
    const rows = props.rows;
    // Number only
    if (rows && /^-{0,1}\d+$/.test(rows.toString())) {
      let gap = props.gap || Number(styles.defaultGridRowGap.replace('px', ''));
      if (gap.hasOwnProperty('row')) {
        gap = gap['row'];
      }
      return generateEqualTemplate(Number(rows), gap as number);
    }
    // Single px value - generate based on number of children and make them fixed width
    if (rows && /^(-{0,1}\d+)px$/.test(rows.toString())) {
      return generateFixedTemplate(
        props.children?.length,
        Number(rows.toString().replace('px', ''))
      );
    }
    // Not a number or single value, apply what was passed
    return rows;
  }};
  ${(props: GridProperties) =>
    props.gap
      ? `column-gap: ${props.gap.hasOwnProperty('column') ? props.gap['column'] : props.gap}px;`
      : ''}
  ${(props: GridProperties) =>
    props.gap
      ? `row-gap: ${props.gap.hasOwnProperty('row') ? props.gap['row'] : props.gap}px;`
      : ''}
  ${(props: GridProperties) => (props.justifyItems ? `justify-items: ${props.justifyItems};` : '')}
  ${(props: GridProperties) => (props.alignItems ? `align-items: ${props.alignItems};` : '')}
`;

/**
 * Generates a template string that generates a dimension template
 * based on the available size from the gap
 * @param numberOfElements - number of items in a grid
 * @param gap - a gap between two items in a grid (single)
 */
export function generateEqualTemplate(numberOfElements: number, gap: number): string {
  let template = '';
  const singleValue = `calc(calc(100% - ${gap * (numberOfElements - 1)}px) / ${numberOfElements}) `;
  for (let i = 1; i <= numberOfElements; i += 1) {
    template += singleValue;
  }
  return template;
}

export function generateFixedTemplate(numberOfElements: number, fixedValue: number): string {
  let template = '';
  const singleValue = `${fixedValue}px `;
  for (let i = 1; i <= numberOfElements; i += 1) {
    template += singleValue;
  }
  return template;
}

BasicGrid.displayName = 'BasicGrid';

BasicGrid.defaultProps = {
  // By default the grid has 12 columnds
  columns: generateEqualTemplate(12, 16),
  rows: 'auto', //generateFixedTemplate(4, 30),
};

export default BasicGrid;
