import React from 'react';
import styled from 'styled-components';
import Item from './components/Item';
import { FlexProperties, FlexStaticProperties } from './types';
import { ReactComponent as Svg } from './flex.svg';

const StyledFlex = styled.div<FlexProperties>`
  align-items: ${(props: FlexProperties) => props.alignItems};
  display: flex;
  flex-basis: ${(props: FlexProperties) => props.flexBasis};
  flex-direction: ${(props: FlexProperties) => props.direction};
  flex-wrap: ${(props: FlexProperties) => (props.wrap ? 'wrap' : 'nowrap')};
  justify-content: ${(props: FlexProperties) => props.justifyContent};
`;

/**
 * This component repsresents a basic flexbox wrapper that's available to you
 * for easier handling of flexbox css.
 * It's most useful for single-dimension layouts or layouts without complex
 * items (for instance, single elements spaning for more rows). For other cases, we recommend the `Grid` component.
 * If you want to know how the CSS flex behaves, take a look at this [guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/).
 *
 * For easier component creation, we expose the [Flex.Item](#/Components/Layout/Flex?id=item) component. It includes some
 * basic properties which map to corresponding CSS. It's a div wrapper without any behavior which means you can easily
 * use any component as a direct child of the Flex component.
 * @example ./examples.md
 * @since 0.3.0
 */

const Flex: React.FunctionComponent<FlexProperties> & FlexStaticProperties = ({
  id,
  testId,
  forwardedRef,
  ...props
}) => (
  <StyledFlex id={id} data-test-id={testId ? testId : undefined} {...props} ref={forwardedRef} />
);
Flex.displayName = 'Flex';
Flex.Item = Item;

Flex.defaultProps = {
  direction: 'row',
  alignItems: 'stretch',
};
Flex.svg = Svg;
export default Flex;
