import styled from 'styled-components';
import List from '../../../DataDisplay/List';

const StyledLi = styled(List.Item)`
  margin-bottom: 0;
  ${({ elevation }: { elevation?: string | number }) => (elevation ? `z-index: ${elevation}` : '')}
`;

const StyledSublist = styled(List)`
  ${({ elevation }: { elevation?: string | number }) => (elevation ? `z-index: ${elevation}` : '')}
`;

export { StyledSublist, StyledLi };
