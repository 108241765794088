import base from '../index.scss';

export type TypographyType = {
  [key: string]: string;
};

const typography: TypographyType = {
  'font.size.05': base.font_size_05,
  'font.size.10': base.font_size_10,
  'font.size.11': base.font_size_11,
  'font.size.13': base.font_size_13,
  'font.size.15': base.font_size_15,
  'font.size.16': base.font_size_16,
  'font.size.18': base.font_size_18,
  'font.size.20': base.font_size_20,
  'font.size.24': base.font_size_24,
  'font.size.25': base.font_size_25,
  'font.size.30': base.font_size_30,
  'font.size.32': base.font_size_32,
  'font.size.40': base.font_size_40,
  'font.size.48': base.font_size_48,
  'font.size.60': base.font_size_60,
  'font.size.72': base.font_size_72,
  'font.size.xxs': base.font_size_xxs,
  'font.size.xs': base.font_size_xs,
  'font.size.s': base.font_size_s,
  'font.size.m': base.font_size_m,
  'font.size.l': base.font_size_l,
  'font.size.xl': base.font_size_xl,
  'font.size.xxl': base.font_size_xxl,
  'font.weight.light': base.font_weight_light,
  'font.weight.medium': base.font_weight_medium,
  'font.weight.regular': base.font_weight_regular,
  'font.weight.bold': base.font_weight_bold,
};

// Is not autogenerated, do not remove
const old = {
  'font.size.05.old': 'sizeTiny',
  'font.size.10.old': 'sizeSmaller',
  'font.size.11.old': 'sizeSmall',
  'font.size.13.old': 'sizeDefault',
  'font.size.15.old': 'sizeMedium',
  'font.size.16.old': 'sizeTitle',
  'font.size.18.old': 'sizeGlyphicon',
  'font.size.20.old': 'sizeBig',
  'font.size.25.old': 'sizeBigger',
  'font.size.30.old': '',
  'font.size.32.old': 'sizeVeryBig',
  'font.size.40.old': 'sizeHuge',
  'font.size.60.old': '',
  'font.size.72.old': '',
  'font.size.xxs.old': 'sizeSmaller',
  'font.size.xs.old': 'sizeSmall',
  'font.size.s.old': 'sizeDefault',
  'font.size.m.old': 'sizeTitle',
  'font.size.l.old': 'sizeGlyphicon',
  'font.size.xl.old': 'sizeBig',
  'font.size.xxl.old': 'sizeVeryBig',
  'font.weight.light.old': 'familyLight',
  'font.weight.medium.old': 'familyMedium',
  'font.weight.regular.old': 'familyRegular',
  'font.weight.bold.old': 'familyBold',
};

export default { ...typography, ...old };
