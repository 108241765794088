import * as React from 'react';
import { ClientThemeType, Palette, withTheme } from '../../../../../theme';
import { ThemedArrowTagProps, MapperType } from './types';
import Basic from './Basic';
import { printCoreWarning } from '../../../../shared';

const themeMapper: MapperType = (theme = {}, { palette = Palette.brand, themeOverwrite }) => {
  const clientTheme = theme as ClientThemeType;

  if (!clientTheme.palette) return undefined;

  const selectedPalette = clientTheme.palette[palette];
  if (!selectedPalette) {
    printCoreWarning(`ArrowTag.Themed component must pass a theme for selected palette`);
    return undefined;
  }

  return {
    default: {
      backgroundColor: themeOverwrite?.default?.backgroundColor || selectedPalette.main,
      color: themeOverwrite?.default?.color || selectedPalette.contrast,
      borderColor: themeOverwrite?.default?.borderColor || selectedPalette.main,
    },
  };
};

const ThemedArrowTag = ({ theme, palette, themeOverwrite, ...other }: ThemedArrowTagProps) => {
  const mappedTheme = themeMapper(theme || {}, { palette, themeOverwrite });
  return <Basic theme={mappedTheme} {...other} />;
};

const ThemedExport: React.FC<ThemedArrowTagProps> & {
  mapper?: MapperType;
} = withTheme(ThemedArrowTag);

ThemedExport.mapper = themeMapper;
ThemedExport.displayName = 'ArrowTag.Themed';
export default ThemedExport;

export { MapperType };
