import * as React from 'react';
import Submenu from './components/Submenu';
import Separator from './components/Separator';
import BasicMenu from './components/Menu';
import Button from './components/Button';
import Item from './components/Item';
import { ReactComponent as Svg } from './menu.svg';
import {
  MenuProperties,
  MenuButtonProperties,
  MenuItemProperties,
  SubmenuProperties,
  MenuSeparatorProperties,
} from './types';

/** Static properties of the Menu component. */
type MenuStaticProperties = {
  Item: React.FunctionComponent<MenuItemProperties>;
  Separator: React.FunctionComponent<MenuSeparatorProperties>;
  Submenu: React.FunctionComponent<SubmenuProperties>;
  Button: React.FunctionComponent<MenuButtonProperties>;
  svg: React.FunctionComponent;
};

/**
 * A menu is a widget that offers a list of predefined choices to the user called menu items.
 * Each menu item tipically presents a common action or function of the system that the user
 * can invoke by selecting the item from the menu.
 * This component is a basic implementation of such widget and is represented by (at least) two
 * parts: menu button and the list with role attribute equal to menu.
 * The menu button is a button that handles visibility of the accompanying menu list.
 *
 * @since 0.6.0
 */

const Menu: React.FunctionComponent<MenuProperties> & MenuStaticProperties = (props) => (
  <BasicMenu {...props} />
);

Menu.displayName = 'Menu';
Menu.Item = Item;
Menu.Button = Button;
Menu.Submenu = Submenu;
Menu.Separator = Separator;
Menu.svg = Svg;

Menu.defaultProps = {
  elevation: '100',
  position: 'bottom',
  includeHover: false,
};
export default Menu;
