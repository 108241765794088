/**
 * Allows printing the core-ui-kit warnings with custom content.
 * Does not print issues in production.
 * Can get added functionality.
 * */
export function printCoreWarning(content: string) {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('%c CORE-UI-KIT WARNING', 'color: red', `\n\r${content}`);
  }
}
