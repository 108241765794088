import * as React from 'react';
import { PanelProps } from '../types';

const hiddenStyle = { display: 'none' };

/**
 * Represents tab content. Only one tab content can be visible at a time.
 * Connected to `Tabs.Tab` with `value` property.
 */
const Panel: React.FunctionComponent<PanelProps> & {
  _internalType: string;
} = ({ selected, children, style, ...otherProps }) => {
  return (
    <div role="tabpanel" {...otherProps} style={selected ? style : hiddenStyle}>
      {children}
    </div>
  );
};

Panel.displayName = 'Tabs.Panel';
Panel._internalType = 'Tabs.Panel';
export default Panel;
