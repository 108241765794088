import * as React from 'react';
import styles from '../index.module.scss';
import Flex from '../../../Layout/Flex';

import { DefaultPageFilterProperties } from '../types';

/**
 * A component that accepts children and renders them in a flex row container vertically centered (alignItems = center). It also handles the spacing between the children and inside the container. The horizontal layout of the children can be changed as the justifyContent is exposed to the developer.
 * @since 1.3.0
 *
 * @component
 * @visibleName DefaultPage.Filter
 */
const DefaultPageFilter = ({ id, testId, children, justifyContent}: DefaultPageFilterProperties) => {

  return(
    <Flex 
      className={styles.defaultPageFilter}         
      id={id} 
      data-test-id={testId ? testId : undefined}
      justifyContent={justifyContent}
      alignItems="center"
      wrap
    >
      {children}
    </Flex>
)};
DefaultPageFilter.displayName = 'DefaultPage.Filter';

export default DefaultPageFilter; 
