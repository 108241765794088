import React from 'react';

/**
 * At the moment, small implementation for handling clicks outside
 */
function useOutsideClickHandler(id, clickOutsideCallback, triggerAction) {
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const { target } = event;
      const wrapper = document.getElementById(`${id}_wrapper`);
      if (wrapper && !(wrapper.contains(target as Node) || wrapper === target)) {
        clickOutsideCallback(event);
      }
    };
    if (triggerAction === 'click') {
      window.addEventListener('click', handleClickOutside);
      return () => window.removeEventListener('click', handleClickOutside);
    }
    // Do not add listeners on hover
    return;
  }, [id, clickOutsideCallback, triggerAction]);
}

export default useOutsideClickHandler;
