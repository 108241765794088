import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import Button from '../../Button';
import componentStyles from '../index.module.scss';
import checkValue from '../../../../styles/checkValue';
import { BasicIconProperties } from '../types';
import { printCoreWarning } from '../../../shared';

type StyledSpanProp = {
  /**
   * Boolean indicating if this is a flag icon or not.
   * Set by our component library and should not be used outside.
   * @ignore
   */
  flag: boolean;
  /**
   * Either exact value to be set, scss variable or js property path. <br />
   * If not set, the element has font-size set to inherit.
   */
  fontSize?: string;
  /**
   * If set to true, element is disabled and has poiner events set to none as well as cursor to 'not-allowed'.
   */
  disabled?: boolean;
  /**
   * Icon you wish to show. Defaults to icon.globe <br />
   * For a full list check our Design Token component
   */
  icon?: string;
  /**
   * Either exact value to be set, scss variable or js property path.<br/>
   * If not set, the element has color set to inherit.
   */
  color?: string;
  /**
   * Either exact value to be set, scss variable or js property path.<br/>
   * If not set, the element has will inherit hover color.
   * To remove visual feedback on hover,set this to the same value as color.
   */
  hoverColor?: string;
  /**
   * Internal usage only.
   * Is true if the onClick property in parent component is set. If set to true,
   * this base component defines some styling for a clickable element.
   * @ignore
   */
  clickable?: boolean;
};

/**
 * A basic variation of the icon component. A single span with selected styling.
 * Font size and color are inherited if not specified. This can be seen in the .icon class
 * specified in the index.module.scss file.
 *
 */
const BaseIcon = styled.span<StyledSpanProp>`
  color: ${(props: StyledSpanProp) => {
    return checkValue(props.color, 'colors');
  }};
  cursor: ${(props: StyledSpanProp) =>
    props.clickable === true ? 'pointer' : props.disabled === true ? 'not-allowed' : 'inherit'};
  font-size: ${(props: StyledSpanProp) => (!props.flag ? checkValue(props.fontSize) : undefined)};
  user-select: ${(props: StyledSpanProp) => (props.disabled ? 'none' : undefined)};
  width: ${(props: StyledSpanProp) =>
    props.flag ? checkValue(props.fontSize) || '1em' : undefined};

  &:hover {
    color: ${(props: StyledSpanProp) =>
      props.disabled ? checkValue(props.color, 'colors') : checkValue(props.hoverColor, 'colors')};
  }
`;

export type BaseIconType = Omit<React.HTMLAttributes<HTMLElement>, 'color' | 'fontSize'> &
  StyledSpanProp &
  BasicIconProperties;

/**
 * @displayName Icon.Base
 * @component
 */
export default ({
  className,
  onClick,
  disabled,
  clickable,
  id,
  testId,
  ...props
}: BaseIconType) => {
  if (clickable && disabled) {
    printCoreWarning(
      `\n\rYou're passing both clickable and disabled to either the Icon or Icon.Flag component. The disabled property lets the component ignore the onClick property.\n\r Did you mean to do this?`
    );
  }

  if (onClick) {
    return (
      <Button sneaky onClick={onClick} disabled={disabled}>
        <BaseIcon
          id={id}
          data-test-id={testId ? testId : undefined}
          {...props}
          disabled={disabled}
          clickable={(!!onClick || clickable) && !disabled}
          className={classnames(className, {
            [componentStyles.icon]: true,
          })}
        />
      </Button>
    );
  }
  return (
    <BaseIcon
      {...props}
      id={id}
      data-test-id={testId ? testId : undefined}
      disabled={disabled}
      clickable={(!!onClick || clickable) && !disabled}
      className={classnames({
        [componentStyles.icon]: true,
        [className as string]: !!className,
      })}
    />
  );
};
