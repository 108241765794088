import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { TextareaProperties } from '../types';

/**
 * Basic component that specifies an multi-line input field where the user can enter data. We suggest using this as a controlled
 * component - meaning  * you controll it's value and handle the onChange updates to the value.
 * This makes it easier to handle cleanup or programmatic setup of textual value.
 * Generates a native textarea HTML tag.
 *
 * You can use the rows and columns properties supported by the textarea tag to change the height/width of
 * the textarea.
 * @since 0.7.0
 *
 * @visibleName Input.Textarea
 */

const BasicTextarea: React.FunctionComponent<TextareaProperties> = ({
  className,
  classNames,
  error,
  forwardedRef,
  hidden,
  id,
  includeMessageHeight,
  message,
  placeholder,
  style,
  testId,
  warning,
  ...other
}) => {
  return (
    <div
      className={classnames(styles.input__wrapper, className, {
        [styles.warning]: warning,
        [styles.error]: error,
        [styles.extra__height]: !message && includeMessageHeight,
      })}
      style={style}
    >
      <textarea
        ref={forwardedRef}
        placeholder={placeholder}
        hidden={hidden}
        id={id}
        data-test-id={testId}
        className={classnames(styles.input, styles.textarea, classNames?.textarea, {
          [styles.warning]: warning,
          [styles.error]: error,
          [`${className}__input`]: !!className,
        })}
        {...other}
      />
      {message && (
        <span
          title={message}
          className={classnames(styles.input__message, classNames?.message, {
            [`${className}__message`]: !!className,
          })}
        >
          {message}
        </span>
      )}
    </div>
  );
};

BasicTextarea.displayName = 'Input.Textarea';
BasicTextarea.defaultProps = {
  error: false,
  warning: false,
  hidden: false,
};
export default BasicTextarea;
