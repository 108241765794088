import base from '../index.scss';

export type SpacingsType = {
  [key: string]: string;
};

const spacings: SpacingsType = {
  'space.04': base.space_04,
  'space.06': base.space_06,
  'space.08': base.space_08,
  'space.10': base.space_10,
  'space.11': base.space_11,
  'space.16': base.space_16,
  'space.24': base.space_24,
  'space.32': base.space_32,
  'space.50': base.space_50,
  'space.xxs': base.space_xxs,
  'space.xs': base.space_xs,
  'space.s': base.space_s,
  'space.m': base.space_m,
  'space.l': base.space_l,
  'space.xl': base.space_xl,
  'space.xxl': base.space_xxl,
};

// Is not autogenerated, do not remove
const old = {
  'space.04.old': 'smaller',
  'space.06.old': 'extraSmall',
  'space.08.old': 'verySmall',
  'space.10.old': 'small',
  'space.11.old': 'smallMedium',
  'space.16.old': 'medium',
  'space.32.old': 'large',
  'space.50.old': 'huge',
  'space.xxs.old': 'smaller',
  'space.xs.old': 'extraSmall',
  'space.s.old': 'small',
  'space.m.old': 'smallMedium',
  'space.l.old': 'medium',
  'space.xl.old': 'large',
  'space.xxl.old': 'huge',
};

export default { ...spacings, ...old };
