import * as React from 'react';
import { ThemeContext } from './context';
import { ThemeType, ClientThemeType } from './types';

export default function withTheme<
  P extends { theme?: ThemeType | ClientThemeType; toggleTheme?: Function }
>(Component: React.ComponentClass<P> | React.FunctionComponent<P>): React.FC<P> {
  return function (props: P) {
    return (
      <ThemeContext.Consumer>
        {(context) => <Component {...props} {...context} />}
      </ThemeContext.Consumer>
    );
  };
}
