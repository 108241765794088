import * as React from 'react';
import classnames from 'classnames';

import styles from '../index.module.scss';
import { BasicTabsProperties } from '../types';

const getFirstTabValue = (children) => {
  const tabList: any = React.Children.toArray(children).find(
    (child) => (child as any).type._internalType === 'Tabs.TabList'
  );
  if (!tabList) throw new Error('Tabs component must have Tabs.List as a child');

  // return value of first tab that have 'value' property set
  return tabList.props.children.find((child: any) => !!child.props.value)?.props.value;
};

const emptyCallback = () => {
  /* do nothing */
};

const BasicTabs: React.FC<BasicTabsProperties> = (props) => {
  const {
    children,
    style,
    className,
    onSelectTab = emptyCallback,
    selected: selectedProp,
    controlled,
    testId,
    id,
  } = props;

  const [selectedState, setSelectedState] = React.useState(() =>
    // initial state:
    // - undefined if controlled
    // - selected property or first tab if not controlled
    controlled ? undefined : selectedProp || getFirstTabValue(children)
  );

  React.useEffect(() => {
    !controlled && onSelectTab(selectedState);
  }, [controlled, selectedState, onSelectTab]);

  const handleSelect = React.useCallback(
    (value: string) => {
      if (controlled) {
        onSelectTab(value);
      } else {
        setSelectedState(value);
      }
    },
    [onSelectTab, controlled, setSelectedState]
  );

  // use selected property when controlled, otherwise use state
  const selectedTab = controlled ? selectedProp : selectedState;

  const rendered = React.useMemo(
    () =>
      React.Children.map(children, (child) => {
        const _internalType = (child as any).type._internalType;
        const isInternal: boolean = _internalType && _internalType.startsWith('Tabs.');

        if (!isInternal) return child;

        const childProps = (child as any)?.props;

        return React.cloneElement(child as React.ReactElement, {
          ...childProps,
          selected: selectedTab,
          onChangeTab: handleSelect,
        });
      }),
    [children, handleSelect, selectedTab]
  );

  return (
    <div
      id={id}
      data-test-id={testId ? testId : undefined}
      className={classnames(styles.tabs, {
        [className as string]: !!className,
      })}
      style={style}
    >
      {rendered}
    </div>
  );
};

BasicTabs.displayName = 'Tabs.Basic';

export default BasicTabs;
