import * as React from 'react';
import Basic from './components/Basic';
import Themed from './components/Themed';
import { BasicTypographyProperties, ThemedTypographyProperties } from './types';
import Label from './components/Label';
import { ReactComponent as Svg } from './typography.svg';

type StaticValues = {
  svg: React.FunctionComponent;
  Themed: React.FunctionComponent<ThemedTypographyProperties>;
  /*
   * @deprecated Will be removed in the future.
   */
  Label: React.FunctionComponent<BasicTypographyProperties & { isBig: boolean; isBold: boolean }>;
};

/**
 * Component for displaying typographical elements in the application.
 * Has a basic variant and Themed variant which can be accessed via static 'Themed' property.
 * The Themed variant looks for the general properties.
 *
 */
const Typography: React.FunctionComponent<BasicTypographyProperties> & StaticValues = (props) => (
  <Basic {...props} />
);

Typography.Themed = Themed;
Typography.Label = Label;

Typography.displayName = 'Typography';
Typography.svg = Svg;
export default Typography;
