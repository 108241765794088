import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { BasicLabelProperties } from '../types';
import checkValue from '../../../../styles/checkValue';
import Tooltip from '../../Tooltip';
import Icon from '../../../General/Icon';
import { printCoreWarning } from '../../../shared';

const StyledLabel = styled.label`
  ${({ color }: { color?: string }) => (color ? `color: ${checkValue(color, 'colors')};` : ``)}
  ${({ block }: { block?: boolean; color?: string }) =>
    block ? `display: block; margin-bottom: 6px;` : ``}
  ${({ fontSize }: { block?: boolean; color?: string; fontSize?: string }) =>
    fontSize ? `font-size: ${checkValue(fontSize, 'typography')};` : ``}
  ${({ fontWeight }: { block?: boolean; color?: string; fontSize?: string; fontWeight?: string }) =>
    fontWeight ? `font-weight: ${checkValue(fontWeight, 'typography')};` : ``}
`;

/**
 * A Label component repsresents a caption for an item in an user interace.
 *
 * @since 0.7.0
 */

const BasicLabel: React.FunctionComponent<BasicLabelProperties> = ({
  block,
  children,
  className,
  color,
  ellipsis,
  fontSize,
  fontWeight,
  forwardedRef,
  hidden,
  htmlFor,
  id,
  style,
  testId,
  title,
  tooltip,
  tooltipPosition,
}) => {
  if (tooltip && !id) {
    printCoreWarning(
      'Label: You are missing the id property needed for our default tooltip implementation. It will not work properly unless you do.'
    );
  }
  return (
    <StyledLabel
      title={title}
      block={block}
      className={classnames(styles.label, className, {
        [styles.ellipsis]: ellipsis,
      })}
      color={color}
      data-test-id={testId}
      fontSize={fontSize}
      fontWeight={fontWeight}
      hidden={hidden}
      htmlFor={htmlFor}
      ref={forwardedRef}
      style={style}
    >
      {children}
      {tooltip && (
        <Tooltip
          arrow
          className={classnames(styles.label__tooltip)}
          id={id ? `${id}-tooltip` : ''}
          position={tooltipPosition}
          trigger={
            <Icon
              color={color}
              fontSize={fontSize}
              icon="icons.icon.help"
              className={styles.label__tooltip__icon}
            />
          }
        >
          {tooltip}
        </Tooltip>
      )}
    </StyledLabel>
  );
};

BasicLabel.displayName = 'Label.Basic';
export default BasicLabel;
