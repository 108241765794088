import * as React from 'react';

import Typography from '../../../General/Typography';
import { DefaultPageSectionHeadlineProperties } from '../types';
import Flex from '../../../Layout/Flex';

/**
 * Section headline on the page. Optional page element.
 *
 * @since 1.1.1
 *
 * @component
 * @visibleName DefaultPage.SectionHeadline
 */

const SectionHeadline: React.FunctionComponent<DefaultPageSectionHeadlineProperties> = (
  props: React.PropsWithChildren<DefaultPageSectionHeadlineProperties>
) => {
  const { children } = props;
  return (
    <Flex direction="column" alignItems="center">
      <Typography
        color={'$grey--700'}
        fontSize={'$font_size--30'}
        fontWeight={'$font_weight--medium'}
      >
        {children}
      </Typography>
    </Flex>
  );
};

SectionHeadline.displayName = 'DefaultPage.SectionHeadline';
export default SectionHeadline;
