import * as React from 'react';
import { BasicModalProperties } from '../types';
import Basic from './Basic';
import ReactDOM from 'react-dom';

class LayerSetter extends React.Component<
  BasicModalProperties,
  { isRendered: boolean; root: any }
> {
  constructor(props) {
    super(props);
    this.state = { isRendered: false, root: null }; // we only want to render children when the parent rendered
  }

  componentDidMount() {
    const { modalRoot } = this.props;
    let modalLayer = document.getElementById(modalRoot || '');
    // If this element does not exist, add it
    if (!modalLayer) {
      modalLayer = document.createElement('div');
      modalLayer.setAttribute('id', modalRoot || 'modal-layer-root');
      document.getElementsByTagName('body')[0].append(modalLayer);
    }
    this.setState({ isRendered: true, root: modalLayer });
  }

  render() {
    const { isRendered, root } = this.state;

    if (isRendered) {
      return ReactDOM.createPortal(<Basic {...this.props} />, root);
    }

    return null;
  }
}

export default LayerSetter;
