import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { SkeletonLoaderProperties } from '../types';
import { printCoreWarning } from '../../../shared';
import { StyledFlex } from './Styled';

import { ReactComponent as BarChart } from '../svgs/skeleton_bar-chart.svg';
import { ReactComponent as BubbleChart } from '../svgs/skeleton_bubble-chart.svg';
import { ReactComponent as ColumnChart } from '../svgs/skeleton_column-chart.svg';
import { ReactComponent as DonutChart } from '../svgs/skeleton_donut-chart.svg';
import { ReactComponent as Kpi } from '../svgs/skeleton_kpi.svg';
import { ReactComponent as LineChart } from '../svgs/skeleton_line-chart.svg';
import { ReactComponent as Meta } from '../svgs/skeleton_meta.svg';
import { ReactComponent as PageFilter } from '../svgs/skeleton_page-filter.svg';
import { ReactComponent as SpiderChart } from '../svgs/skeleton_spider-chart.svg';
import { ReactComponent as Sunburst } from '../svgs/skeleton_sunburst.svg';
import { ReactComponent as Table } from '../svgs/skeleton_table.svg';

const typeSvgMap = {
  'bar-chart': BarChart,
  'bubble-chart': BubbleChart,
  'column-chart': ColumnChart,
  'donut-chart': DonutChart,
  kpi: Kpi,
  'line-chart': LineChart,
  meta: Meta,
  'page-filter': PageFilter,
  'spider-chart': SpiderChart,
  'sunburst-chart': Sunburst,
  table: Table,
};

/**
 * Basic implementation of the Loader.Skeleton component - can be used as
 * skeleton/ghost loader of various visual elements in the system.
 * @visibleName Loader.Skeleton
 * @since 0.5.0
 */

const Skeleton: React.FunctionComponent<SkeletonLoaderProperties> = ({
  type,
  className,
  style,
  invert,
  children,
  alignItems,
  centered,
  flexBasis,
  direction,
  justifyContent,
  wrap,
  id,
  testId,
}) => {
  const Component = typeSvgMap[type || ''];

  if (!Component) {
    printCoreWarning('Loader.Skeleton - wrong type passed as property. Will display nothing.');
    return null;
  }

  return (
    <StyledFlex
      alignItems={alignItems}
      className={classnames(className, {
        [styles.centered]: centered,
      })}
      size={'unset'}
      direction={direction}
      flexBasis={flexBasis}
      justifyContent={justifyContent}
      style={style}
      id={id}
      data-test-id={testId ? testId : undefined}
      wrap={wrap}
    >
      {invert && children}
      <Component width={'100%'} height="100%" className={classnames(styles.skeleton)} />
      {!invert && children}
    </StyledFlex>
  );
};

Skeleton.displayName = 'Loader.Skeleton';
Skeleton.defaultProps = {
  type: 'bar-chart',
};
export default Skeleton;
