import * as React from 'react';
import classnames from 'classnames';
import styles from '../index.module.scss';
import { TooltipTriggerType } from '../types';

/**
 * Container for the trigger of the tooltip
 * @component
 * @visibleName Tooltip.Trigger
 */
const TooltipTrigger: React.FunctionComponent<TooltipTriggerType> = ({
  className,
  style,
  triggerRef,
  id,
  hidden,
  disabled,
  ...props
}) => (
  <span
    style={style}
    className={classnames(styles['tooltip__trigger'], className, {
      active: !hidden,
      [styles['tooltip__trigger--disabled']]: disabled,
    })}
    aria-describedby={id}
    tabIndex={0}
    role="button"
    ref={triggerRef}
  >
    {props.children}
  </span>
);

TooltipTrigger.displayName = 'Tooltip.Trigger';
export default TooltipTrigger;
