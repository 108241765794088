import * as React from "react";
import { printCoreWarning } from '../../../shared';
import { BasicDefaultPageProperties } from '../types';  
import { ChildElementType } from '../../../../types';
import styles from '../index.module.scss';

/**
 * Basic implementation of the DefaultPage component.
 * 
 * @since 1.3.0
 */

const DefaultPage: React.FunctionComponent<BasicDefaultPageProperties> = ({
  children,
  id,
  testId,
}) => 
{
  const componentChildren = React.Children.toArray(children);

  const headerChild = componentChildren.find((child) => (child as ChildElementType).type.displayName === "DefaultPage.Header");

  if (!headerChild) {
    printCoreWarning("Page requires DefaultPage.Header child.");
  }

  return (
      <section 
        id={id} 
        data-test-id={testId ? testId : undefined}
        className={styles.defaultPage}
      >
        {headerChild} 
      </section>
  );
};

DefaultPage.displayName = 'DefaultPage';

export default DefaultPage;
